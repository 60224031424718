import MobileNewMessage from "../../app/chat-system/mobile-version/mobile-new-message/mobile-new-message";

const MobileNewMessagePage = () => {
  return (
    <>
      <MobileNewMessage />
    </>
  );
};

export default MobileNewMessagePage;
