import styled from "styled-components";
import { breakpoint, color } from "../../themes/themes";

export const Wrapper = styled.section`
  padding: 5rem 0;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;

  ${breakpoint.mobile} {
    gap: 3rem;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;

  h1 {
    font-family: "Poppins", sans-serif;
    font-size: 2.6rem;
    margin: 0 0 2rem;

    ${breakpoint.tablet} {
      font-size: 2rem;
    }

    ${breakpoint.mobile} {
      font-size: 1.8rem;
    }
  }

  span {
    color: ${color.primary};
  }

  p {
    margin: 0;
    font-size: 1.1rem;
  }

  .boldText {
    font-weight: 700;
  }
`;

export const RightSide = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
`;

export const Item = styled.li`
  list-style-type: none;
  margin-bottom: 1rem;
`;

export const Question = styled.div`
  cursor: pointer;
  padding: 1rem;
  border-radius: 0.4rem;
  border: 1px solid #ddd;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Answer = styled.div`
  padding: 1rem;
  margin-top: 0.5rem;
  display: none;

  &.active {
    display: block;
  }
`;
