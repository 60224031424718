import React from "react";
import ModalLayout from "../modal-layout/modal-layout";
import { BsThreeDots } from "react-icons/bs";
import { FaThumbsUp, FaComment, FaShare, FaUser } from "react-icons/fa";
import ProfileImg from "../../../assets/Story1.png";
import {
  Container,
  Header,
  ListItem,
  Item,
  Avatar,
  IconOverlay,
  Content,
} from "./notification.styled";

const Notification = () => {
  const notifications = [
    {
      type: "like",
      message: "Your last story got 181 views before it expired.",
      time: "17h",
    },
    { type: "comment", message: "Someone commented on your post.", time: "2d" },
    { type: "share", message: "Your post was shared 3 times.", time: "5d" },
    { type: "visit", message: "Someone visited your profile.", time: "3h" },
    {
      type: "like",
      message: "Your last story got 181 views before it expired.",
      time: "17h",
    },
    { type: "share", message: "Your post was shared 3 times.", time: "5d" },
    { type: "visit", message: "Someone visited your profile.", time: "3h" },
    { type: "comment", message: "Someone commented on your post.", time: "2d" },
    { type: "share", message: "Your post was shared 3 times.", time: "5d" },
    { type: "visit", message: "Someone visited your profile.", time: "3h" },
  ];

  const notificationColors = {
    like: "linear-gradient(135deg, #4CAF50, #81C784)",
    comment: "linear-gradient(135deg, #FFC107, #FFEB3B)",
    share: "linear-gradient(135deg, #03A9F4, #4FC3F7)",
    visit: "linear-gradient(135deg, #9C27B0, #E1BEE7)",
  };

  const renderIcon = (type) => {
    switch (type) {
      case "like":
        return <FaThumbsUp />;
      case "comment":
        return <FaComment />;
      case "share":
        return <FaShare />;
      case "visit":
        return <FaUser />;
      default:
        return null;
    }
  };

  return (
    <ModalLayout>
      <Container>
        <Header>
          <h1>Notification</h1>
          <BsThreeDots />
        </Header>
        <ListItem>
          {notifications.map((notification, index) => (
            <Item key={index}>
              <Avatar>
                <img src={ProfileImg} alt="profile" />
                <IconOverlay $gradient={notificationColors[notification.type]}>
                  {renderIcon(notification.type)}
                </IconOverlay>
              </Avatar>
              <Content>
                <p>{notification.message}</p>
                <p className="time">{notification.time}</p>
              </Content>
            </Item>
          ))}
        </ListItem>
      </Container>
    </ModalLayout>
  );
};

export default Notification;
