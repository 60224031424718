import styled from "styled-components";
import { breakpoint, color } from "../../../themes/themes";

export const Wrapper = styled.section`
  background-image: linear-gradient(
    to top,
    #f3e7e9 0%,
    #e3eeff 99%,
    #e3eeff 100%
  );
  padding: 5rem 0;
`;

export const ListItem = styled.ul`
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  list-style-type: none;
  margin-top: 3rem;

  ${breakpoint.tablet} {
    gap: 1.4rem;
    grid-template-columns: repeat(2, 1fr);
  }

  ${breakpoint.mobile} {
    gap: 1.4rem;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  border-radius: 1rem;
  background-color: ${color.white};
  box-shadow: 0 15px 15px -5px hsla(0, 0%, 0%, 0.05);

  svg {
    margin-right: 1.2rem;
    font-size: 2.4rem;
  }

  h3 {
    font-weight: 600;
  }
`;
