import styled from "styled-components";
import { breakpoint, color } from "../../../../themes/themes";

export const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  overflow: hidden;
  min-height: calc(100vh - 1rem);
  background-color: ${color.white};

  ${breakpoint.mobile} {
    border-radius: 0;
  }
`;

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
  gap: 1rem;
`;

export const ChatTitle = styled.h3`
  margin: 0.8rem 0;
`;

export const ChatContent = styled.div`
  flex-grow: 1;
  border-radius: 0.5rem;
  background-color: #f1f1f1;
  overflow-y: scroll;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 50vh;
  gap: 0.8rem;
`;

export const ChatMessage = styled.div`
  padding: 0.8rem;
  background-color: ${color.white};
  border-radius: 0.5rem 0 0.5rem 0.5rem;
  font-size: 0.9rem;
  align-self: flex-end;
  max-width: 60%;
  word-wrap: break-word;
  overflow-wrap: break-word;

  ${breakpoint.mobile} {
    max-width: 70%;
  }
`;

export const ChatHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const UserSection = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  h4 {
    margin: 0;
  }
`;

export const UserAvatar = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const InfoIcon = styled.div`
  cursor: pointer;

  svg {
    display: flex;
    font-size: 1.5rem;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  bottom: 0;
  background-color: #fff;
`;

export const InputField = styled.input`
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
  width: 100%;
  font-size: 0.8rem;
  border: 1px solid #ccc;

  &:focus {
    border-color: ${color.primary};
    outline: none;
  }
`;

export const SendIconButton = styled.button`
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  color: ${color.primary};
  font-size: 1.5rem;

  &:hover {
    color: ${color.secondary};
  }

  svg {
    display: flex;
  }
`;
