import styled from "styled-components";
import { FiSearch } from "react-icons/fi";
import { breakpoint, color } from "../../themes/themes";

export const Navbar = styled.nav`
  background-color: ${color.white};
  padding: 0.8rem 0;
  box-shadow: 0 10px 15px -5px hsla(0, 0%, 0%, 0.05);
  width: 100%;
  position: fixed;
  z-index: 9999;

  ${breakpoint.navbar} {
    padding: 1.2rem 0;
  }
`;

export const NavContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Logo = styled.h1`
  font-size: 1.6rem;
  color: ${color.primary};
  margin: 0;
  cursor: pointer;

  &:hover {
    color: ${color.link};
  }
`;

export const MenuToggle = styled.div`
  display: none;
  cursor: pointer;
  position: relative;

  ${breakpoint.navbar} {
    display: block;
  }
`;

export const MenuLine = styled.span`
  display: block;
  width: 25px;
  height: 2px;
  background-color: ${color.black};
  margin: 6px auto;
  transition: all 0.2s ease;

  &:nth-child(1) {
    transform: ${({ $isOpen }) =>
      $isOpen ? "rotate(45deg) translate(3px, 3px)" : "none"};
  }

  &:nth-child(2) {
    transform: ${({ $isOpen }) =>
      $isOpen ? "rotate(-45deg) translate(3px, -3px)" : "none"};
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  ${breakpoint.navbar} {
    display: ${({ $isOpen }) => ($isOpen ? "flex" : "none")};
    flex-direction: column;
    gap: 0;
    width: 100%;
  }
`;

export const NavList = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;

  li {
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: color 0.3s ease;
    padding: 0 0.8rem;

    &:hover {
      color: ${color.link};
    }
  }

  ${breakpoint.navbar} {
    flex-direction: column;
    padding: 1rem 0;
    width: 100%;

    li {
      padding: 0.8rem 0;
    }
  }
`;

export const SearchBox = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #ccc;
  padding: 0.4rem 0.8rem;
  border-radius: 6px;
  width: 11rem;

  ${breakpoint.navbar} {
    margin-bottom: 1rem;
    width: 100%;
  }
`;

export const SearchIcon = styled(FiSearch)`
  font-size: 1.5rem;
  color: ${color.darkBg};
`;

export const SearchInput = styled.input`
  border: none;
  outline: none;
  width: 100%;

  ${breakpoint.navbar} {
    font-size: 1rem;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 0.8rem;

  ${breakpoint.navbar} {
    padding: 1rem 0;
    width: 100%;
    flex-direction: column;
    display: ${({ $isOpen }) => ($isOpen ? "flex" : "none")};
  }

  a:first-child > Button {
    background-color: ${color.transparent};
    color: inherit;
  }

  a:first-child > Button:hover {
    background-color: ${color.primary};
    color: ${color.white};
  }
`;

export const Button = styled.button`
  padding: 0.7rem 1.4rem;
  font-size: 0.9rem;
  color: ${color.white};
  background-color: ${color.primary};
  border: 1px solid ${color.primary};
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${color.link};
  }

  ${breakpoint.navbar} {
    width: 100%;
    padding: 0.8rem 1.4rem;
  }
`;
