import styled from "styled-components";
import { breakpoint, color } from "../../../themes/themes";

export const BackgroundContainer = styled.section`
  background-color: #f2f4f7;
`;

export const StyledContainer = styled.div`
  width: 100%;
  max-width: 960px;
  margin: auto;
  padding: 5.5rem 1rem 5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const AccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: ${color.white};
`;

export const CoverSection = styled.div`
  background: ${({ $coverImage }) =>
    $coverImage
      ? `url(${$coverImage}) no-repeat center center / cover`
      : "linear-gradient(to right, #5c4b8a, #7f00ff)"};
  height: 35vh;
  position: relative;
  display: flex;
  align-items: flex-end;

  ${breakpoint.mobile} {
    height: 25vh;
  }
`;

export const SocialList = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  list-style-type: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

export const SocialIcon = styled.li`
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.6);
  }

  svg {
    display: flex;
    font-size: 1.4rem;
    color: ${color.white};
  }
`;

export const UserDetails = styled.div`
  display: flex;
  padding: 2rem;
  position: relative;
  z-index: 1;
`;

export const ProfileImage = styled.div`
  border-radius: 50%;
  width: 12rem;
  height: 12rem;
  border: 0.3rem solid ${color.white};
  overflow: hidden;
  position: absolute;
  top: -6rem;
  left: 3rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${breakpoint.mobile} {
    left: 2rem;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  flex-grow: 1;
  margin: 0 0 0 15rem;

  ${breakpoint.mobile} {
    margin: 5rem 0 0 0;
  }
`;

export const UserName = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;

  * {
    margin: 0;
  }

  h1 {
    font-size: 1.8rem;
  }

  p {
    font-size: 1rem;
  }
`;

export const Bio = styled.p`
  margin: 0;
`;

export const FollowerStats = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  p {
    margin: 0;
    font-size: 0.9rem;
    font-weight: 700;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.7rem;
`;

export const ActionButton = styled.button`
  padding: 0.6rem 1rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid ${color.primary};
  background: ${color.transparent};

  &:last-child {
    background: ${color.primary};
    color: ${color.white};
  }
`;

export const NavLinks = styled.ul`
  margin: 0;
  list-style-type: none;
  display: flex;
  gap: 1.5rem;
  padding: 0.4rem 1.4rem;
  border-radius: 0.5rem;

  li {
    cursor: pointer;
    padding: 0.5rem;

    &.active {
      border-bottom: 1px solid ${color.primary};
    }

    &:hover {
      color: ${color.primary};
    }
  }

  ${breakpoint.mobile} {
    padding: 0.4rem 0;

    li {
      padding: 0.5rem 0;
    }
  }
`;

export const ListItem = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.2rem;

  ${breakpoint.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${breakpoint.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Item = styled.li`
  border-radius: 0.5rem;
  background-color: white;
  overflow: hidden;
  box-shadow: 0 15px 15px -5px hsla(0, 0%, 0%, 0.07);
  cursor: pointer;

  h1 {
    margin: 0;
    font-size: 1rem;
    padding: 1.4rem;
  }
`;

export const ImageThumbnail = styled.div`
  width: 100%;
  height: 30vh;

  img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
