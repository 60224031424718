import styled from "styled-components";
import { breakpoint } from "../../themes/themes";

export const Content = styled.div`
  border-radius: 1rem;
  overflow: hidden;
  height: 90vh;
  margin-top: 4rem;

  iframe {
    display: flex;
    width: 100%;
    height: 100%;
  }

  ${breakpoint.tablet} {
    height: 50vh;
  }

  ${breakpoint.mobile} {
    height: 50vh;
  }
`;
