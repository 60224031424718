import React from "react";
import { FaChevronCircleRight, FaLock } from "react-icons/fa";
import ConfettiGIF from "../../../../assets/confetti.gif";
import ProfileImg from "../../../../assets/Story1.png";
import html2canvas from "html2canvas";
import {
  StyledModal,
  ModalContainer,
  Header,
  ModalBody,
  ProfileCard,
  ProfileImage,
  UserInfo,
  ActionButton,
  Confetti,
  Slots,
  Footer,
} from "./skip-modal.styled";

const SkipModal = ({ onClose }) => {
  const saveScreenshot = () => {
    const modalBody = document.getElementById("modal-body");

    html2canvas(modalBody).then((canvas) => {
      const image = canvas.toDataURL("image/jpeg");

      const link = document.createElement("a");
      link.href = image;
      link.download = "modal-screenshot.jpg";
      link.click();
    });
  };

  return (
    <StyledModal>
      <ModalContainer>
        <Header>
          <p>You have 10 slots everyday.</p>
        </Header>

        <ModalBody id="modal-body">
          <ProfileCard>
            <ProfileImage $imgSrc={ProfileImg} />

            <UserInfo>
              <p>Congratulations! It was</p>
              <h1>Lyza Mae Sajot</h1>
              <p>whom you spoke with.</p>
            </UserInfo>

            <Confetti className="left">
              <img src={ConfettiGIF} alt="confetti" />
            </Confetti>
            <Confetti className="right">
              <img src={ConfettiGIF} alt="confetti" />
            </Confetti>
          </ProfileCard>

          <ActionButton onClick={onClose}>
            NEXT <FaChevronCircleRight />
          </ActionButton>

          <Slots>
            <FaLock />
            <p>1/10</p>
          </Slots>
        </ModalBody>

        <Footer>
          <button onClick={saveScreenshot}>Save Screenshot</button>
        </Footer>
      </ModalContainer>
    </StyledModal>
  );
};

export default SkipModal;
