import React from "react";
import styled from "styled-components";
import { breakpoint, color } from "../../../themes/themes";

const Wrapper = styled.div`
  background-color: ${color.white};
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  position: fixed;
  padding: 1rem;
  right: 1rem;
  top: 4rem;
  width: 23rem;
  height: calc(100vh - 4rem);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1111;
  overflow: hidden;

  ${breakpoint.mobile} {
    display: none;
  }
`;

const ModalLayout = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default ModalLayout;
