import styled from "styled-components";
import { color } from "../../../../themes/themes";

export const Wrapper = styled.div`
  background-color: ${color.white};
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  position: fixed;
  padding: 1rem;
  right: 8rem;
  bottom: 0;
  width: 20rem;
  height: 28rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1111;
  overflow: hidden;
  color: black;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: flex-start;
  align-items: center;
  width: 100%;

  h4 {
    margin: 0;
  }

  svg {
    display: flex;
    cursor: pointer;
  }
`;

export const Input = styled.input`
  margin-top: 1rem;
  padding: 0.6rem;
  border-radius: 0.25rem;
  width: 100%;
  font-size: 1rem;
  border: 1px solid #e0e0e0;

  &:focus {
    border-color: ${color.primary};
    outline: none;
  }
`;
