import styled from "styled-components";
import { breakpoint, color } from "../../../themes/themes";

export const Wrapper = styled.section`
  padding: 5rem 0;
  border-top: 1px solid #ddd;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 2rem;
  margin-top: 3rem;

  ${breakpoint.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Thumbnail = styled.div`
  img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-size: 2.6rem;

  ${breakpoint.mobile} {
    font-size: 1.8rem;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    font-size: 1.1rem;
    display: flex;
    align-items: center;

    .check-icon {
      margin-right: 1rem;
      color: ${color.lightgreen};
    }
  }
`;
