import React, { useCallback, useState } from "react";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { GrClose } from "react-icons/gr";
import {
  ModalWrapper,
  ModalImage,
  CloseButton,
  NavButton,
  Dot,
  DotContainer,
} from "./image-modal.styled";

const ImageModal = ({ images, currentImageIndex, onClose, onNavigate }) => {
  const [touch, setTouch] = useState({ start: 0, end: 0 });

  const handleTouchEnd = useCallback(() => {
    const { start, end } = touch;
    if (start - end > 75) {
      onNavigate(1);
    } else if (end - start > 75) {
      onNavigate(-1);
    }
  }, [touch, onNavigate]);

  const handlePrevious = () => onNavigate(-1);
  const handleNext = () => onNavigate(1);

  const genPostImageUrl = (name) =>
    `${process.env.REACT_APP_BASE_IMAGE_URL}/${name}`;

  return (
    <ModalWrapper
      onTouchStart={(e) =>
        setTouch({ ...touch, start: e.targetTouches[0].clientX })
      }
      onTouchMove={(e) =>
        setTouch({ ...touch, end: e.targetTouches[0].clientX })
      }
      onTouchEnd={handleTouchEnd}
    >
      <CloseButton onClick={onClose} aria-label="Close modal">
        <GrClose />
      </CloseButton>
      <ModalImage
        src={genPostImageUrl(images[currentImageIndex])}
        alt="Enlarged"
      />
      <NavButton
        className="left"
        onClick={handlePrevious}
        aria-label="Previous image"
      >
        <IoChevronBack />
      </NavButton>
      <NavButton className="right" onClick={handleNext} aria-label="Next image">
        <IoChevronForward />
      </NavButton>
      <DotContainer>
        {images.map((_, index) => (
          <Dot key={index} $active={index === currentImageIndex} />
        ))}
      </DotContainer>
    </ModalWrapper>
  );
};

export default ImageModal;
