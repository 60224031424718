import React from "react";
import AppLayout from "../../app/app-components/app-layout/app-layout";
import AppHeader from "../../app/app-components/app-navbar/app-header";
import MobileNav from "../../app/app-components/app-navbar/mobile-navbar";
import DigitalProducts from "../../app/digital-products/digital-products";
import Dashboard from "../../app/app-components/dashboard/dashboard";

const DigitalProductsPage = () => {
  return (
    <>
      <AppHeader />
      <AppLayout
        leftContent={<Dashboard />}
        centerContent={<DigitalProducts />}
      />
      <MobileNav />
    </>
  );
};

export default DigitalProductsPage;
