import React, { useState } from "react";
import { FaSquareXTwitter, FaPlus } from "react-icons/fa6";
import { FaFacebook, FaYoutube } from "react-icons/fa";
import { ProfilePic } from "../../newsfeed/newsfeed-post/post-data";
import { IoChatbubble } from "react-icons/io5";
import NoImage from "../../../assets/blank.png";
import {
  BackgroundContainer,
  StyledContainer,
  AccountWrapper,
  CoverSection,
  SocialList,
  SocialIcon,
  UserDetails,
  ProfileImage,
  UserInfo,
  UserName,
  Bio,
  FollowerStats,
  ActionButtons,
  ActionButton,
  NavLinks,
  ListItem,
  Item,
  ImageThumbnail,
} from "./user-account.styled";

const UserAccount = () => {
  const [activeCategory, setActiveCategory] = useState("Digital Products");

  const socialMediaLinks = [
    { icon: <FaSquareXTwitter />, alt: "Twitter" },
    { icon: <FaFacebook />, alt: "Facebook" },
    { icon: <FaYoutube />, alt: "YouTube" },
  ];

  const followerStats = [
    { label: "Followers", count: "12.4K" },
    { label: "Following", count: "200" },
  ];

  const userInfo = {
    name: "Joe De Guzman",
    profession: "Graphic Designer",
    bio: "I'm Joe with 7 years of experience in digital marketing.",
  };

  const actionButtons = [
    { icon: <FaPlus />, label: "Follow" },
    { icon: <IoChatbubble />, label: "Message" },
  ];

  const coverImages = [
    "https://cdn.pixabay.com/photo/2012/08/27/14/19/mountains-55067_1280.png",
  ];

  const items = [
    {
      image: NoImage,
      title: "I will edit videos for you",
      category: "Services",
    },
    {
      image: NoImage,
      title: "I sell template books for you",
      category: "Digital Products",
    },
    {
      image: NoImage,
      title: "I sell template books for you",
      category: "Digital Products",
    },
    {
      image: NoImage,
      title: "I sell template books for you",
      category: "Digital Products",
    },
    {
      image: NoImage,
      title: "Logo design for your brand",
      category: "Services",
    },
    {
      image: NoImage,
      title: "Digital marketing consultation",
      category: "Services",
    },
    {
      image: NoImage,
      title: "E-books on design",
      category: "Digital Products",
    },
    { image: NoImage, title: "My Discord Account", category: "Links" },
    { image: NoImage, title: "My Portfolio", category: "Links" },
    { image: NoImage, title: "My LinkedIn Profile", category: "Links" },
  ];

  const navLinks = [
    { label: "Services", id: "services" },
    { label: "Digital Products", id: "digital-products" },
    { label: "Posts", id: "posts" },
    { label: "Links", id: "links" },
  ];

  const coverImage = coverImages.length > 0 ? coverImages[0] : "";

  const filteredItems =
    activeCategory === "All"
      ? items
      : items.filter((item) => item.category === activeCategory);

  return (
    <BackgroundContainer>
      <StyledContainer>
        <AccountWrapper>
          <CoverSection $coverImage={coverImage}>
            <SocialList>
              {socialMediaLinks.map((link, index) => (
                <SocialIcon key={index} title={link.alt}>
                  {link.icon}
                </SocialIcon>
              ))}
            </SocialList>
          </CoverSection>
          <UserDetails>
            <ProfileImage>
              <img src={ProfilePic} alt="profile" />
            </ProfileImage>
            <UserInfo>
              <UserName>
                <h1>{userInfo.name}</h1>
                <p>{userInfo.profession}</p>
              </UserName>
              <Bio>{userInfo.bio}</Bio>
              <FollowerStats>
                {followerStats.map((stat, index) => (
                  <p key={index}>{`${stat.count} ${stat.label}`}</p>
                ))}
              </FollowerStats>
              <ActionButtons>
                {actionButtons.map((button, index) => (
                  <ActionButton key={index}>
                    {button.icon}
                    {button.label}
                  </ActionButton>
                ))}
              </ActionButtons>
            </UserInfo>
          </UserDetails>
        </AccountWrapper>

        <NavLinks>
          {navLinks.map((link, index) => (
            <li
              key={index}
              onClick={() => setActiveCategory(link.label)}
              className={activeCategory === link.label ? "active" : ""}
            >
              {link.label}
            </li>
          ))}
        </NavLinks>

        <ListItem>
          {filteredItems.map((item, index) => (
            <Item key={index}>
              <ImageThumbnail>
                <img src={item.image} alt="thumbnail" />
              </ImageThumbnail>
              <h1>{item.title}</h1>
            </Item>
          ))}
        </ListItem>
      </StyledContainer>
    </BackgroundContainer>
  );
};

export default UserAccount;
