import React from "react";
import { ModalOverlay, ModalContent, Option } from "./ellipsis-modal.styled";

const EllipsisModal = ({ onClose }) => (
  <ModalOverlay onClick={onClose}>
    <ModalContent onClick={(e) => e.stopPropagation()}>
      <Option>Report</Option>
      <Option>Go to post</Option>
      <Option>Share to..</Option>
      <Option>Copy link</Option>
      <Option>Embed</Option>
      <Option>About this account</Option>
    </ModalContent>
  </ModalOverlay>
);

export default EllipsisModal;
