import NavbarComponent from "../components/navbar/navbar";
import Hero from "../landingpage/hero/hero";
import Users from "../landingpage/users/users";
import FeaturesPage from "./features-page";
import Presentation from "../landingpage/presentation/presentation";
import Reviews from "../landingpage/reviews/reviews";
import FAQs from "../landingpage/FAQs/FAQs";
import About from "../landingpage/about/about";
import Footer from "../components/footer/footer";

const LandingPage = () => {
  const navItems = [
    { label: "Features", path: "#features" },
    { label: "Testimonials", path: "#testimonials" },
    { label: "Pricing", path: "/pricing" },
    { label: "About", path: "#about" },
  ];

  const buttons = [
    { label: "Get Started", path: "/get-started" },
    { label: "Sign Up", path: "/signup" },
  ];

  return (
    <>
      <NavbarComponent
        showLogo={true}
        logoPath="/landing-page"
        logoText="Autosell.io"
        navItems={navItems}
        showSearch={true}
        buttons={buttons}
      />
      <Hero />
      <Users />
      <section id="features">
        <FeaturesPage />
      </section>
      <Presentation />
      <section id="testimonials">
        <Reviews />
      </section>
      <FAQs />
      <section id="about">
        <About />
      </section>
      <Footer />
    </>
  );
};

export default LandingPage;
