import React from "react";
import {
  Wrapper,
  CategoryInput,
  Tags,
  TagButton,
  CostInput,
  Datestamp,
  DurationButton,
  InputContainer,
  TextInput,
  AttachmentIcon,
  UploadButton,
} from "./hiring-settings.styled";

const HiringSettings = () => {
  return (
    <Wrapper>
      <CategoryInput
        id="category-input"
        name="category"
        placeholder="Type Category"
      />
      <Tags>
        <TagButton>Full-Time</TagButton>
        <TagButton>Part-Time</TagButton>
        <TagButton>One-Time</TagButton>
      </Tags>
      <CostInput
        id="cost-input"
        name="cost"
        type="number"
        placeholder="Type How much"
      />
      <Datestamp>
        <DurationButton>Monthly</DurationButton>
        <DurationButton>One-Time</DurationButton>
      </Datestamp>
      <InputContainer>
        <TextInput
          id="text-input"
          name="looking-for"
          placeholder="Looking for..."
        />
        <AttachmentIcon />
      </InputContainer>
      <UploadButton type="button">Upload</UploadButton>
    </Wrapper>
  );
};

export default HiringSettings;
