import React from "react";
import DefaultHeader from "../../../app-components/community-header/community-header";

const MyCommunityHeader = () => {
  const communityItems = [
    { name: "Announcements", path: "/my-community/announcements" },
    { name: "Group Chat", path: "/under-construction" },
    { name: "Calendars", path: "/my-community/calendar" },
    { name: "Classroom", path: "/under-construction" },
  ];

  return (
    <div>
      <DefaultHeader items={communityItems} />
    </div>
  );
};

export default MyCommunityHeader;
