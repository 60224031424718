import React, { useState } from "react";
import { FaStar } from "react-icons/fa";
import { digitalProductsData } from "./digital-products-data";
import {
  ServicesWrapper,
  ListItem,
  Item,
  ImageThumbnail,
  Title,
  Name,
  Rating,
} from "../services/services.styled";
import DigitalProductsSorting from "./digital-products-sorting";

const DigitalProducts = () => {
  const [category, setCategory] = useState("");
  const [price, setPrice] = useState(500);
  const [search, setSearch] = useState("");
  const [filteredPrice, setFilteredPrice] = useState(600);

  const filteredServices = digitalProductsData.filter((service) => {
    const matchesCategory = category ? service.category === category : true;
    const matchesPrice = service.price <= filteredPrice;
    const matchesSearch = service.name
      .toLowerCase()
      .includes(search.toLowerCase());

    return matchesCategory && matchesPrice && matchesSearch;
  });

  const handlePriceFilter = () => {
    setFilteredPrice(price);
  };

  return (
    <ServicesWrapper>
      <ListItem>
        {filteredServices.length > 0 ? (
          filteredServices.map((service, index) => (
            <Item key={index}>
              <ImageThumbnail>
                <img src={service.image} alt={service.name} />
              </ImageThumbnail>
              <Title>
                <Name>{service.name}</Name>
                <Rating>
                  <FaStar />
                  <p>{service.rating}</p>
                </Rating>
              </Title>
            </Item>
          ))
        ) : (
          <p>No items available</p>
        )}
      </ListItem>

      <DigitalProductsSorting
        search={search}
        setSearch={setSearch}
        category={category}
        setCategory={setCategory}
        price={price}
        setPrice={setPrice}
        handlePriceFilter={handlePriceFilter}
      />
    </ServicesWrapper>
  );
};

export default DigitalProducts;
