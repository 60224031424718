import ProfilePic from "../../../assets/Story1.png";
import NoImage from "../../../assets/Story2.png";

export { ProfilePic };

// Initial posts data
export const postsData = [
  {
    username: "Joe De Guzman",
    datePosted: "1 day ago",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
    profile: ProfilePic,
    images: [],
    heartCount: 10,
    isLiked: false,
    comments: [],
  },
  {
    username: "Alice Smith",
    datePosted: "3 days ago",
    text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.",
    profile: ProfilePic,
    images: NoImage,
    heartCount: 20,
    isLiked: false,
    comments: [],
  },
  {
    username: "Jane Doe",
    datePosted: "2 days ago",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    profile: ProfilePic,
    images: [ProfilePic, NoImage],
    heartCount: 5,
    isLiked: false,
    comments: [],
  },
];
