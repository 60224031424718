const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const homePageApi = {
  getOffers: async () => {
    try {
      const response = await fetch(`${BASE_URL}/items/home/offers`, {
        method: "GET",
      });

      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        console.error("Failed to fetch news feed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  },
};

export default homePageApi;
