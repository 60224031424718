import styled from "styled-components";
import { breakpoint, color } from "../../../themes/themes";

export const PostList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const PostCard = styled.li`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  background-color: ${color.white};
  border-radius: 0.5rem;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
  font-size: 0.9rem;

  ${breakpoint.mobile} {
    border-radius: 0;
  }
`;

export const PostHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .right {
    display: flex;
    gap: 1rem;

    ${breakpoint.mobile} {
      gap: 0.5rem;
    }
  }

  svg {
    font-size: 1.2rem;
    cursor: pointer;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const UserAvatar = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    display: flex;
    object-fit: cover;
  }
`;

export const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserName = styled.h3`
  margin: 0;
  font-weight: 600;
  font-size: 1rem;
`;

export const PostTimestamp = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  color: ${color.gray};

  p {
    margin: 0;
    font-size: 0.8rem;
  }

  svg {
    display: flex;
    font-size: 0.8rem;
  }
`;

export const PostDescription = styled.p`
  line-height: 1.2rem;
  padding: 0.8rem 0;
`;

export const PostInteraction = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InteractionCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-size: 0.8rem;
  color: ${color.gray};
`;

export const ReactCount = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;

  svg {
    display: flex;
    font-size: 1.1rem;
    color: #ff0000;
  }

  p {
    margin: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  gap: 1rem;
`;

export const CommentCount = styled.p`
  margin: 0;
`;

export const ShareCount = styled.p`
  margin: 0;
`;

export const ActionButtons = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  li {
    display: flex;
    gap: 0.5rem;
    cursor: pointer;
    align-items: center;

    svg {
      display: flex;
      font-size: 1rem;
    }

    ${breakpoint.mobile} {
      gap: 0.3rem;
    }
  }
`;

export const CommentSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CommentInput = styled.input`
  flex: 1;
  padding: 0.5rem;
  margin-right: 0.5rem;
  border: 1px solid ${color.primary};
  border-radius: 0.5rem;
`;

export const CommentButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${color.primary};
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: ${color.lightblue};
  }
`;

export const CommentsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  overflow-y: auto;
  padding: 1rem 0;
  margin: 1rem 0 0;
  border-top: 1px solid #ddd;
`;

export const CommentInfo = styled.div`
  display: flex;
  gap: 0.5rem;
  max-width: 20rem;

  .commentAvatar {
    width: 2rem;
    height: 2rem;
  }
`;

export const CommentItem = styled.p`
  align-self: start;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  padding: 1rem;
  border-radius: 0.8rem;
  margin: 0;
  background-color: ${color.lightviolet};
  font-size: 0.9rem;
`;

export const Notification = styled.div`
  position: fixed;
  bottom: 6rem;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.4);
  color: ${color.white};
  font-size: 0.9rem;
  padding: 0.5rem 1.2rem;
  border-radius: 0.3rem;
  z-index: 9999;
  animation: fadeIn 0.5s, fadeOut 0.5s 1.5s forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;
