import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Container from "../container/container";
import {
  Navbar,
  NavContent,
  Logo,
  MenuToggle,
  MenuLine,
  MenuWrapper,
  NavList,
  SearchBox,
  SearchInput,
  SearchIcon,
  ButtonGroup,
  Button,
} from "./navbar.styled";

const NavbarComponent = ({
  navItems,
  buttons,
  showSearch = true,
  showLogo = true,
  logoText = "Autosell.io",
  logoPath = "/",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }

    setLastScrollY(currentScrollY);
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const goToHome = () => {
    navigate(logoPath);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleNavigation = () => {
    setIsOpen(false);
  };

  return (
    <Navbar
      style={{
        transform: isVisible ? "translateY(0)" : "translateY(-100%)",
        transition: "transform 0.3s ease-in-out",
      }}
    >
      <Container>
        <NavContent>
          {showLogo && <Logo onClick={goToHome}>{logoText}</Logo>}

          <MenuToggle onClick={toggleMenu}>
            <MenuLine $isOpen={isOpen} />
            <MenuLine $isOpen={isOpen} />
          </MenuToggle>

          <MenuWrapper $isOpen={isOpen}>
            <NavList>
              {navItems.map((item, index) => (
                <li key={index} onClick={handleNavigation}>
                  <HashLink
                    smooth
                    to={item.path}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {item.label}
                  </HashLink>
                </li>
              ))}
            </NavList>
            {showSearch && (
              <SearchBox>
                <SearchInput
                  type="text"
                  placeholder="Search..."
                  id="search-input"
                  name="search"
                />
                <SearchIcon />
              </SearchBox>
            )}
          </MenuWrapper>

          <ButtonGroup $isOpen={isOpen}>
            {buttons.map((button, index) => (
              <HashLink to={button.path} key={index}>
                <Button onClick={button.onClick}>{button.label}</Button>
              </HashLink>
            ))}
          </ButtonGroup>
        </NavContent>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
