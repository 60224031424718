import styled from "styled-components";
import { breakpoint, color } from "../../themes/themes";

export const Wrapper = styled.section`
  height: 100vh;
  background-color: ${color.lightviolet};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 0.6rem;
  background-color: #e0e0e0;
`;

export const ProgressBarFill = styled.div`
  height: 100%;
  width: 0;
  background-color: ${color.primary};
  transition: width 0.3s ease;
`;

export const QuizContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  padding: 4rem;
  background-color: ${color.white};
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 1.4rem;
  gap: 1.4rem;

  ${breakpoint.mobile} {
    padding: 3rem 2rem;
  }
`;

export const Question = styled.h2`
  margin: 0;
  font-size: 2rem;
  color: ${color.primary};

  ${breakpoint.mobile} {
    font-size: 1.8rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Button = styled.button`
  padding: 0.6rem 1.3rem;
  background-color: ${(props) =>
    props.$primary ? color.primary : color.darkBg};
  color: ${color.white};
  border: none;
  border-radius: 50px;
  cursor: pointer;

  &:disabled {
    background-color: #efefef;
    cursor: not-allowed;
    color: ${color.black};
  }
`;

export const AnswerInput = styled.input`
  padding: 0.8rem;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 0.4rem;
`;

export const SuggestionList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
  gap: 0.6rem;
`;

export const SuggestionItem = styled.li`
  display: flex;
  align-items: center;
  padding: 0.6rem 1rem;
  background-color: ${color.lightviolet};
  border-radius: 50px;
  cursor: pointer;
  font-size: 0.8rem;

  &:hover {
    background-color: ${color.primary};
    color: ${color.white};
  }
`;
