import React from "react";
import styled from "styled-components";
import { breakpoint } from "../../../themes/themes";

const Wrapper = styled.section`
  background-color: #f2f4f7;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem 1rem 0;

  ${breakpoint.mobile} {
    padding: 0;
  }
`;

const LeftSide = styled.div`
  position: fixed;
  width: 20%;
  left: 0;

  ${breakpoint.tablet} {
    width: 25%;
  }

  ${breakpoint.mobile} {
    display: none;
  }
`;

const RightSide = styled.div`
  width: 80%;
  margin-left: auto;

  ${breakpoint.tablet} {
    width: 75%;
  }

  ${breakpoint.mobile} {
    width: 100%;
  }
`;

const CommunityLayout = ({ leftContent, rightContent }) => {
  return (
    <Wrapper>
      <LeftSide>{leftContent}</LeftSide>
      <RightSide>{rightContent}</RightSide>
    </Wrapper>
  );
};

export default CommunityLayout;
