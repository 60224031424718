import React from "react";
import CommunityLayout from "../../app/app-components/app-layout/community-layout";
import CommunityCalendar from "../../app/community/my-community/calendar/calendar";
import Dashboard from "../../app/app-components/dashboard/dashboard";

const CalendarPage = () => {
  return (
    <>
      <CommunityLayout
        leftContent={<Dashboard />}
        rightContent={<CommunityCalendar />}
      />
    </>
  );
};

export default CalendarPage;
