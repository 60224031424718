import React, { useState, useCallback } from "react";
import { MdOutlinePublic } from "react-icons/md";
import {
  GoHeart,
  GoHeartFill,
  GoComment,
  GoShare,
  GoBookmark,
  GoBookmarkFill,
} from "react-icons/go";
import { BsThreeDots } from "react-icons/bs";
import { IoHeartCircle } from "react-icons/io5";
import ShareModal from "../../newsfeed/newsfeed-post/share-modal/share-modal";
import EllipsisModal from "../../newsfeed/newsfeed-post/ellipsis-modal/ellipsis-modal";
import {
  PostList,
  PostCard,
  PostHeader,
  UserInfo,
  UserAvatar,
  UserDetails,
  UserName,
  PostTimestamp,
  PostDescription,
  PostInteraction,
  InteractionCount,
  ReactCount,
  Container,
  CommentCount,
  ShareCount,
  ActionButtons,
  CommentSection,
  CommentInput,
  CommentsList,
  CommentInfo,
  CommentItem,
  CommentButton,
  Notification,
} from "./testimonials-post.styled";
import testimonialsData from "../testimonials-data/testimonials-data";

const TestimonialsPost = () => {
  // State initialization
  const [posts, setPosts] = useState(
    testimonialsData.map((post) => ({
      ...post,
      liked: false,
      saved: false,
      comments: [],
      commentInput: "",
      showCommentInput: false,
    }))
  );
  const [isModalOpen, setIsModalOpen] = useState({
    share: false,
    ellipsis: false,
  });
  const [currentPost, setCurrentPost] = useState(null);
  const [notification, setNotification] = useState("");

  // Update post function
  const updatePost = useCallback((index, updatedPost) => {
    setPosts((prevPosts) =>
      prevPosts.map((post, i) => (i === index ? updatedPost : post))
    );
  }, []);

  // Notification function
  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => {
      setNotification("");
    }, 2000);
  };

  // Handle like action
  const handleLike = useCallback(
    (index) => {
      const post = posts[index];
      const newLikedState = !post.liked;
      updatePost(index, {
        ...post,
        liked: newLikedState,
        interactions: {
          ...post.interactions,
          likes: newLikedState
            ? post.interactions.likes + 1
            : post.interactions.likes - 1,
        },
      });
    },
    [posts, updatePost]
  );

  // Handle save action
  const handleSave = useCallback(
    (index) => {
      const post = posts[index];
      const newSavedState = !post.saved;
      updatePost(index, {
        ...post,
        saved: newSavedState,
      });
      showNotification(newSavedState ? "Post saved!" : "Post unsaved!");
    },
    [posts, updatePost]
  );

  // Handle comment input
  const handleCommentInput = useCallback(
    (index, value) => {
      updatePost(index, {
        ...posts[index],
        commentInput: value,
      });
    },
    [posts, updatePost]
  );

  // Handle adding a comment
  const handleAddComment = useCallback(
    (index) => {
      const post = posts[index];
      if (post.commentInput.trim()) {
        const newComment = {
          userName: "Current User",
          text: post.commentInput,
        };
        updatePost(index, {
          ...post,
          comments: [...post.comments, newComment],
          commentInput: "",
          interactions: {
            ...post.interactions,
            comments: post.interactions.comments + 1,
          },
        });
      }
    },
    [posts, updatePost]
  );

  // Toggle comment input visibility
  const toggleCommentInput = useCallback(
    (index) => {
      updatePost(index, {
        ...posts[index],
        showCommentInput: !posts[index].showCommentInput,
      });
    },
    [posts, updatePost]
  );

  // Handle share button click
  const handleShareClick = (post) => {
    setCurrentPost(post);
    setIsModalOpen({ ...isModalOpen, share: true });
  };

  // Handle three dots click (ellipsis modal)
  const handleEllipsisClick = (post) => {
    setCurrentPost(post);
    setIsModalOpen({ ...isModalOpen, ellipsis: true });
  };

  // Close modals
  const closeModals = () => {
    setIsModalOpen({ share: false, ellipsis: false });
    setCurrentPost(null);
  };

  return (
    <>
      {isModalOpen.share && currentPost && (
        <ShareModal post={currentPost} onClose={closeModals} />
      )}
      {isModalOpen.ellipsis && currentPost && (
        <EllipsisModal post={currentPost} onClose={closeModals} />
      )}
      {notification && <Notification>{notification}</Notification>}

      <PostList>
        {posts.map(
          (
            {
              profileImg,
              name,
              timestamp,
              content,
              interactions,
              liked,
              saved,
              showCommentInput,
              comments,
              commentInput,
            },
            index
          ) => (
            <PostCard key={index}>
              {/* Post Header */}
              <PostHeader>
                <UserInfo>
                  <UserAvatar>
                    <img src={profileImg} alt="profile" />
                  </UserAvatar>
                  <UserDetails>
                    <UserName>{name}</UserName>
                    <PostTimestamp>
                      <p>{timestamp}</p>
                      <MdOutlinePublic />
                    </PostTimestamp>
                  </UserDetails>
                </UserInfo>
                <BsThreeDots
                  onClick={() => handleEllipsisClick(posts[index])}
                />
              </PostHeader>

              {/* Post Description */}
              <PostDescription>{content}</PostDescription>

              {/* Post Interaction */}
              <PostInteraction>
                {(interactions.likes > 0 ||
                  interactions.comments > 0 ||
                  interactions.shares > 0) && (
                  <InteractionCount>
                    <ReactCount>
                      <IoHeartCircle />
                      <p>{interactions.likes}</p>
                    </ReactCount>
                    <Container>
                      <CommentCount>
                        {interactions.comments} comments
                      </CommentCount>
                      <ShareCount>{interactions.shares} shares</ShareCount>
                    </Container>
                  </InteractionCount>
                )}

                {/* Action Buttons */}
                <ActionButtons>
                  <li onClick={() => handleLike(index)}>
                    {liked ? (
                      <GoHeartFill style={{ color: "#FF0000" }} />
                    ) : (
                      <GoHeart />
                    )}
                    Like
                  </li>
                  <li onClick={() => toggleCommentInput(index)}>
                    <GoComment /> Comment
                  </li>
                  <li onClick={() => handleShareClick(posts[index])}>
                    <GoShare /> Share
                  </li>
                  <li onClick={() => handleSave(index)}>
                    {saved ? (
                      <GoBookmarkFill style={{ color: "#7f00ff" }} />
                    ) : (
                      <GoBookmark />
                    )}
                    Save
                  </li>
                </ActionButtons>

                {/* Comment Section */}
                {showCommentInput && (
                  <>
                    <CommentsList>
                      {comments.map((comment, commentIndex) => (
                        <CommentInfo key={commentIndex}>
                          <UserAvatar className="commentAvatar">
                            <img src={profileImg} alt="profile" />
                          </UserAvatar>
                          <CommentItem>
                            <strong>{comment.userName}</strong> {comment.text}
                          </CommentItem>
                        </CommentInfo>
                      ))}
                    </CommentsList>

                    <CommentSection>
                      <CommentInput
                        type="text"
                        value={commentInput}
                        onChange={(e) =>
                          handleCommentInput(index, e.target.value)
                        }
                        placeholder="Write a comment..."
                      />
                      <CommentButton onClick={() => handleAddComment(index)}>
                        Post
                      </CommentButton>
                    </CommentSection>
                  </>
                )}
              </PostInteraction>
            </PostCard>
          )
        )}
      </PostList>
    </>
  );
};

export default TestimonialsPost;
