import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlinePublic } from "react-icons/md";
import { BsThreeDots } from "react-icons/bs";
import {
  GoHeart,
  GoHeartFill,
  GoComment,
  GoShare,
  GoBookmark,
  GoBookmarkFill,
} from "react-icons/go";
import { IoSend } from "react-icons/io5";
import EllipsisModal from "./ellipsis-modal/ellipsis-modal";
import ImageModal from "./image-modal/image-modal";
import ShareModal from "./share-modal/share-modal";
import { postsData } from "./post-data";
import {
  PostWrapper,
  PostHeader,
  UserSection,
  ProfilePicture,
  UserInfo,
  PostMeta,
  PostOptions,
  PostContentWrapper,
  PostText,
  PostImage,
  PostIcons,
  CommentBoxWrapper,
  CommentTextarea,
  SendButton,
  CommentsList,
  CommentItem,
  CommentUserInfo,
  Notification,
} from "./newsfeed-post.styled";
import newsFeedApi from "../../../api/newsFeedApi";
import { useAuth } from "../../../context/AuthContext";

const NewsFeedPost = ({ posts, updateAllPost }) => {
  // const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [allImages, setAllImages] = useState([]);
  const [commentBoxVisible, setCommentBoxVisible] = useState(
    Array(postsData.length).fill(false)
  );

  const { user } = useAuth();

  const genPostImageUrl = (name) =>
    `${process.env.REACT_APP_BASE_IMAGE_URL}/${name}`;

  const [newComments, setNewComments] = useState(
    Array(postsData.length).fill("")
  );

  const [isBookmarked, setIsBookmarked] = useState(
    Array(postsData.length).fill(false) // Track bookmark status for each post
  );

  const [notification, setNotification] = useState(""); // Track notification message
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const navigate = useNavigate();

  // Toggle Functions
  const toggleModal = () => setModalOpen(!isModalOpen);
  const toggleImageModal = (image, images) => {
    setImageModalOpen(true);
    setCurrentImageIndex(images.indexOf(image));
    setAllImages(images);
  };
  const toggleShareModal = () => setShareModalOpen(!isShareModalOpen);
  const toggleCommentBox = (index) => {
    setCommentBoxVisible((prev) =>
      prev.map((isVisible, i) => (i === index ? !isVisible : isVisible))
    );
  };

  // Heart Click Handler
  const handleHeartClick = (index, currentPost) => {
    if (loading) return;
    setLoading(true);
    if (currentPost.isLiked) {
      newsFeedApi
        .unlikePost(currentPost.postId)
        .then((res) => {
          const updatedPosts = posts.map((post, i) => {
            if (i === index) {
              const newLikes = post.likes.filter((item) => item.likeId !== res);

              const newHeartCount = newLikes.length;
              return {
                ...post,
                likes: newLikes,
                heartCount: newHeartCount,
                isLiked: !post.isLiked,
              };
            }
            return post;
          });
          updateAllPost(updatedPosts);
        })
        .catch((e) => console.log(e))
        .finally(setLoading(false));
    } else {
      newsFeedApi
        .likePost(currentPost.postId)
        .then((res) => {
          const updatedPosts = posts.map((post, i) => {
            if (i === index) {
              const newLikes = [...post.likes, res];
              const newHeartCount = newLikes.length;
              return {
                ...post,
                likes: newLikes,
                heartCount: newHeartCount,
                isLiked: !post.isLiked,
              };
            }
            return post;
          });
          updateAllPost(updatedPosts);
        })
        .catch((e) => console.log(e))
        .finally(setLoading(false));
    }
  };

  // Bookmark Click Handler
  const handleBookmarkClick = (index) => {
    setIsBookmarked((prev) =>
      prev.map((bookmarked, i) => {
        if (i === index) {
          const newBookmarkStatus = !bookmarked;
          setNotification(
            newBookmarkStatus ? "Save successful" : "Unsave successful"
          );

          setTimeout(() => setNotification(""), 2000);
          return newBookmarkStatus;
        }
        return bookmarked;
      })
    );
  };

  // Comment Handlers
  const handleCommentChange = (index, value) => {
    setNewComments((prev) =>
      prev.map((comment, i) => (i === index ? value : comment))
    );
  };

  const handleAddComment = (index, currentPost) => {
    if (newComments[index].trim() === "") return;
    if (loading) return;
    setLoading(true);

    newsFeedApi
      .commentPost(currentPost.postId, newComments[0])
      .then((res) => {
        const updatedPosts = posts.map((post, i) => {
          if (i === index) {
            return {
              ...post,
              comments: [...post.comments, res],
            };
          }
          return post;
        });
        updateAllPost(updatedPosts);
        setNewComments((prev) =>
          prev.map((comment, i) => (i === index ? "" : comment))
        );
      })
      .catch((e) => console.log(e))
      .finally(setLoading(false));
  };

  // Image Navigation Function
  const handleNavigate = (direction) => {
    const newIndex =
      (currentImageIndex + direction + allImages.length) % allImages.length;
    setCurrentImageIndex(newIndex);
  };

  return (
    <>
      {notification && <Notification>{notification}</Notification>}
      {posts.map((post, index) => {
        const postImages = Array.isArray(post.images)
          ? post.images
          : [post.images];

        return (
          <PostWrapper key={index}>
            <PostHeader>
              <UserSection>
                <ProfilePicture>
                  <img
                    src={post?.user?.profilePic || "/default-profile.png"}
                    alt={`${post.user.username} Profile`}
                  />
                </ProfilePicture>
                <UserInfo>
                  <h3 onClick={() => navigate("/username@autosell.io")}>
                    {post.user.username}
                  </h3>
                  <PostMeta>
                    <p>{post.datePosted}</p>
                    <MdOutlinePublic />
                  </PostMeta>
                </UserInfo>
              </UserSection>
              <PostOptions>
                <BsThreeDots onClick={toggleModal} />
              </PostOptions>
            </PostHeader>

            <PostContentWrapper>
              <PostText>{post.text}</PostText>
              {postImages.length > 0 && (
                <PostImage>
                  {postImages.map((image, imgIndex) => (
                    <img
                      key={imgIndex}
                      src={genPostImageUrl(image)}
                      alt={`Post Content ${imgIndex + 1}`}
                      onClick={() => toggleImageModal(image, postImages)}
                    />
                  ))}
                </PostImage>
              )}
            </PostContentWrapper>

            <PostIcons>
              <div className="left-icons">
                <div className="react">
                  <button onClick={() => handleHeartClick(index, post)}>
                    {post.isLiked ? (
                      <GoHeartFill style={{ color: "#FF0000" }} />
                    ) : (
                      <GoHeart />
                    )}
                  </button>
                  <span>{post.heartCount}</span>
                </div>
                <GoComment onClick={() => toggleCommentBox(index)} />
                <GoShare onClick={toggleShareModal} />
              </div>
              <button onClick={() => handleBookmarkClick(index)}>
                {isBookmarked[index] ? (
                  <GoBookmarkFill style={{ color: "#7f00ff" }} />
                ) : (
                  <GoBookmark />
                )}
              </button>
            </PostIcons>

            {commentBoxVisible[index] && (
              <>
                <CommentsList>
                  {post.comments.map((comment, commentIndex) => (
                    <CommentItem key={commentIndex}>
                      <ProfilePicture>
                        <img
                          src={
                            comment?.commentBy?.profilePic ||
                            "/default-profile.png"
                          }
                          alt={`${comment.commentBy.username} Profile`}
                        />
                      </ProfilePicture>
                      <CommentUserInfo>
                        <h4>{comment.commentBy.username}</h4>
                        <p>{comment.text}</p>
                      </CommentUserInfo>
                    </CommentItem>
                  ))}
                </CommentsList>
                <CommentBoxWrapper>
                  <ProfilePicture>
                    <img
                      src={
                        user?.profile?.profilePictureUrl ||
                        "/default-profile.png"
                      }
                      alt="Current User Profile"
                    />
                  </ProfilePicture>
                  <CommentTextarea
                    placeholder="Write a comment..."
                    value={newComments[index]}
                    onChange={(e) => handleCommentChange(index, e.target.value)}
                  />
                  <SendButton onClick={() => handleAddComment(index, post)}>
                    <IoSend />
                  </SendButton>
                </CommentBoxWrapper>
              </>
            )}
          </PostWrapper>
        );
      })}
      {isModalOpen && <EllipsisModal onClose={toggleModal} />}
      {isShareModalOpen && <ShareModal onClose={toggleShareModal} />}
      {isImageModalOpen && (
        <ImageModal
          images={allImages}
          currentImageIndex={currentImageIndex}
          onClose={() => setImageModalOpen(false)}
          onNavigate={handleNavigate}
        />
      )}
    </>
  );
};

export default NewsFeedPost;
