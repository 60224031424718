import styled, { keyframes } from "styled-components";
import { breakpoint, color } from "../../themes/themes";

const bounceAndRotate = keyframes`
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
  30% {
    transform: translateY(-30px) rotate(-10deg);
    opacity: 0.8;
  }
  50% {
    transform: translateY(-20px) rotate(10deg);
    opacity: 1;
  }
  70% {
    transform: translateY(0) rotate(0deg);
    opacity: 0.8;
  }
  100% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
`;

export const LoadingScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 6rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom right, #e0b3ff, #7f00ff);
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;

  svg {
    color: ${color.white};
    font-size: 10rem;
    animation: ${bounceAndRotate} 2s infinite;

    ${breakpoint.mobile} {
      font-size: 8rem;
    }
  }
`;

export const LoadingText = styled.h1`
  font-size: 2.5rem;
  color: ${color.white};
  margin: 0;

  ${breakpoint.mobile} {
    font-size: 2rem;
  }
`;

export const Quotes = styled.div`
  text-align: center;
  max-width: 30%;
  margin-top: auto;

  p {
    color: ${color.white};
    font-style: italic;
    font-size: 0.9rem;
  }

  ${breakpoint.tablet} {
    max-width: 50%;
  }

  ${breakpoint.mobile} {
    max-width: 80%;
  }
`;
