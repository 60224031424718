import React, { useState, useRef, useEffect } from "react";
import { RiRobot2Fill } from "react-icons/ri";
import { IoMenuOutline } from "react-icons/io5";
import { useNavigate, useLocation } from "react-router-dom";
import {
  HeaderWrapper,
  Item,
  Logo,
  MenuButton,
  Sidebar,
  SidebarItem,
  Overlay,
} from "./community-header.styled";

const CommunityHeader = ({ items = [] }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);
  const menuButtonRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !menuButtonRef.current.contains(event.target)
      ) {
        setSidebarOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleItemClick = (path) => {
    navigate(path);
    setSidebarOpen(false);
  };

  const isActive = (path) => location.pathname === path;

  return (
    <HeaderWrapper>
      <Logo>
        <RiRobot2Fill />
      </Logo>
      {items.map((item, index) => (
        <Item
          key={index}
          onClick={() => handleItemClick(item.path)}
          $active={isActive(item.path)}
        >
          {item.name}
        </Item>
      ))}
      <MenuButton ref={menuButtonRef} onClick={toggleSidebar}>
        <IoMenuOutline />
      </MenuButton>

      {isSidebarOpen && <Overlay />}

      <Sidebar ref={sidebarRef} $isOpen={isSidebarOpen}>
        {items.map((item, index) => (
          <SidebarItem
            key={index}
            onClick={() => handleItemClick(item.path)}
            $active={isActive(item.path)}
          >
            {item.name}
          </SidebarItem>
        ))}
      </Sidebar>
    </HeaderWrapper>
  );
};

export default CommunityHeader;
