import React from "react";
import TestimonialLayout from "../../app/app-components/app-layout/testimonials-layout";
import AppHeader from "../../app/app-components/app-navbar/app-header";
import MobileNav from "../../app/app-components/app-navbar/mobile-navbar";
import Testimonials from "../../app/testimonials/testimonials/testimonials";
import Dashboard from "../../app/app-components/dashboard/dashboard";

const TestimonialsPage = () => {
  return (
    <>
      <AppHeader />
      <TestimonialLayout
        leftContent={<Dashboard />}
        rightContent={<Testimonials />}
      />
      <MobileNav />
    </>
  );
};

export default TestimonialsPage;
