const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const authApi = {
  login: async (username, password) => {
    const response = await fetch(`${BASE_URL}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });

    if (!response.ok) {
      throw new Error("Login failed");
    }

    return await response.json();
  },

  check: async (token) => {
    const response = await fetch(`${BASE_URL}/auth/check`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        throw new Error("unauthenticated");
      } else {
        const errorText = await response.text();
        throw new Error(errorText || "Something went wrong");
      }
    }

    return await response.json();
  },

  register: async (username, email, password, confPassword) => {
    const response = await fetch(`${BASE_URL}/auth/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, email, password, confPassword }),
    });

    if (!response.ok) {
      throw new Error("Register failed:" + (await response.text()));
    }

    return await response.json();
  },

  logout: async () => {
    const response = await fetch(`${BASE_URL}/auth/logout`, {
      method: "POST",
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Logout failed");
    }

    return response.json();
  },
};

export default authApi;
