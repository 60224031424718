import React from "react";
import styled from "styled-components";
import { color } from "../../themes/themes";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: ${color.primary};
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: ${color.lightblue};
  }
`;

const UnderConstruction = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Wrapper>
      <h1>Under Construction</h1>
      <Button onClick={handleBack}>Back to Previous</Button>
    </Wrapper>
  );
};

export default UnderConstruction;
