import styled from "styled-components";
import { breakpoint } from "../../../../../themes/themes";

export const ListItem = styled.ul`
  padding: 0 1rem 1rem;
  margin: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.4rem;
  width: 100%;

  ${breakpoint.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${breakpoint.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Item = styled.li`
  display: flex;
  flex-direction: column;
  border-radius: 0.6rem;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 4px 0 8px rgba(0, 0, 0, 0.09);
  justify-content: space-between;

  &:hover img {
    transform: scale(1.1);
  }
`;

export const Title = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  h1,
  p {
    margin: 0;
  }

  p {
    font-size: 0.9rem;
  }
`;

export const Thumbnail = styled.div`
  width: 100%;
  height: 100%;
  max-height: 30vh;
  overflow: hidden;

  img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.2s ease-in-out;
  }
`;
