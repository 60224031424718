import React, { useState, useEffect, useRef } from "react";
import { IoInformationCircle, IoSend } from "react-icons/io5";
import ChatAHeader from "../header/header";
import ChatAFooter from "../footer/footer";
import DefaultProfile from "../../../../assets/default-profile.png";
import SkipModal from "../skip-modal/skip-modal";
import {
  ChatWrapper,
  ChatContainer,
  ChatTitle,
  ChatContent,
  ChatMessage,
  ChatHeader,
  UserSection,
  UserAvatar,
  InfoIcon,
  InputWrapper,
  InputField,
  SendIconButton,
} from "./chat-anonymously.styled";

const ChatAnonymously = () => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const chatContentRef = useRef(null);

  const handleSendMessage = () => {
    if (inputValue.trim() !== "") {
      setMessages([...messages, inputValue]);
      setInputValue("");
    }
  };

  useEffect(() => {
    if (chatContentRef.current) {
      chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <ChatWrapper>
      <ChatAHeader />
      <ChatContainer>
        <ChatTitle>
          Chat, connect, network, and have fun with random people.
        </ChatTitle>

        <ChatContent ref={chatContentRef}>
          <ChatHeader>
            <UserSection>
              <UserAvatar>
                <img src={DefaultProfile} alt="profile" />
              </UserAvatar>
              <h4>Anonymous</h4>
            </UserSection>
            <InfoIcon>
              <IoInformationCircle />
            </InfoIcon>
          </ChatHeader>
          {messages.map((message, index) => (
            <ChatMessage key={index}>{message}</ChatMessage>
          ))}
        </ChatContent>

        <InputWrapper>
          <InputField
            placeholder="Type a message..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
          />
          <SendIconButton onClick={handleSendMessage}>
            <IoSend />
          </SendIconButton>
        </InputWrapper>
      </ChatContainer>
      <ChatAFooter setModalVisible={setModalVisible} />

      {modalVisible && <SkipModal onClose={() => setModalVisible(false)} />}
    </ChatWrapper>
  );
};

export default ChatAnonymously;
