import styled from "styled-components";
import { color, breakpoint } from "../../themes/themes";

export const MainTitle = styled.h1`
  color: ${(props) => (props.theme === "dark" ? color.white : color.black)};
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 2.6rem;
  margin: 0;

  ${breakpoint.mobile} {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
`;

export const LastWord = styled.span`
  color: ${color.primary};
`;
