import styled from "styled-components";
import { breakpoint, color } from "../../../themes/themes";

export const Wrapper = styled.form`
  width: 100%;
  padding: 1.2rem 1rem;
  background-color: ${color.white};
  border-radius: 0.5rem;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  ${breakpoint.mobile} {
    border-radius: 0;
  }
`;

export const UserSection = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

export const ProfileImageWrapper = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const TextInput = styled.input`
  flex-grow: 1;
  padding: 0.8rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.4rem;
  font-size: 0.9rem;

  &:focus {
    border-color: ${color.primary};
    outline: none;
  }
`;

export const SettingsSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ActionList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  gap: 0.4rem;
  align-items: center;
`;

export const ActionItem = styled.li`
  padding: 0.5rem 0.8rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.6rem;
  border-radius: 0.2rem;

  &:hover {
    background-color: ${color.lightviolet};
  }

  p {
    margin: 0;
  }

  ${breakpoint.mobile} {
    padding: 0.5rem;
  }
`;

export const StyledIcon = styled.div`
  color: ${(props) => props.color};

  svg {
    font-size: 1.1rem;
    display: flex;
  }
`;

export const SubmitButton = styled.button`
  padding: 0.5rem 1.1rem;
  background-color: ${color.primary};
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${color.lightblue};
  }
`;
