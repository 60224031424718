import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1111;
`;

export const ModalContent = styled.div`
  background: white;
  padding: 1.4rem;
  border-radius: 0.8rem;
  width: 18rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
`;

export const Option = styled.div`
  padding: 0.8rem;
  text-align: center;
  cursor: pointer;
  border-radius: 0.5rem;

  &:hover {
    background-color: #f5f5f5;
  }
`;
