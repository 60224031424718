import React from "react";
import { MdOutlinePermMedia } from "react-icons/md";
import TestimonialsPost from "../testimonials-post/testimonials-post";
import {
  Container,
  Header,
  Title,
  Form,
  InputContainer,
  Input,
  Asset,
  SubmitButton,
} from "./testimonials.styled";

const Testimonials = () => {
  return (
    <Container>
      <Header>
        <Title>
          Leaving testimonials will help in recommending your account.
        </Title>
        <Form>
          <InputContainer>
            <Input placeholder="Upload yours.." />
            <Asset>
              <MdOutlinePermMedia />
            </Asset>
          </InputContainer>
          <SubmitButton>Upload</SubmitButton>
        </Form>
      </Header>
      <TestimonialsPost />
    </Container>
  );
};

export default Testimonials;
