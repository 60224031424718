import React, { useState, useEffect, useRef } from "react";
import { IoSend } from "react-icons/io5";
import { RiRobot2Fill } from "react-icons/ri";
import BotLogo from "../../../assets/bot-logo.png";
import botResponses from "./chatbot-responses";
import {
  Wrapper,
  Header,
  ChatBody,
  Message,
  BotMessage,
  UserMessage,
  TypeMessage,
  Input,
  SendButton,
  QuickQuestions,
  QuestionButton,
} from "./chat-automation.styled";

const ChatAutomation = () => {
  const [messages, setMessages] = useState([
    { id: 1, text: "Hello! How can I assist you today?", $sender: "bot" },
  ]);
  const [newMessage, setNewMessage] = useState("");

  const chatBodyRef = useRef(null);

  // Function to get bot's response based on user message
  const getBotResponse = (userMessage) => {
    if (botResponses[userMessage]) {
      return botResponses[userMessage];
    }

    const keywords = Object.keys(botResponses);
    for (let keyword of keywords) {
      if (userMessage.toLowerCase().includes(keyword.toLowerCase())) {
        return botResponses[keyword];
      }
    }

    return botResponses["default"];
  };

  // Function to handle sending a new message
  const handleSend = () => {
    if (newMessage.trim()) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { id: Date.now(), text: newMessage, $sender: "user" },
      ]);
      setNewMessage("");

      const botReply = getBotResponse(newMessage);
      setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { id: Date.now() + 1, text: botReply, $sender: "bot" },
        ]);
      }, 3000);
    }
  };

  // Function to handle quick question responses
  const handleQuickQuestion = (question, response) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { id: Date.now(), text: question, $sender: "user" },
    ]);
    setTimeout(() => {
      setMessages((prevMessages) => [
        ...prevMessages,
        { id: Date.now() + 1, text: response, $sender: "bot" },
      ]);
    }, 3000);
  };

  // Scroll to the bottom when new messages are added
  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <Wrapper>
      <Header>
        <RiRobot2Fill />
        <h1>Autosell Assist</h1>
      </Header>

      <ChatBody ref={chatBodyRef}>
        <QuickQuestions>
          <QuestionButton
            onClick={() =>
              handleQuickQuestion(
                "What is Autosell?",
                "Autosell is an automated platform that helps manage and sell your products effectively."
              )
            }
          >
            What is Autosell?
          </QuestionButton>
          <QuestionButton
            onClick={() =>
              handleQuickQuestion(
                "Is there a fee?",
                "Yes, Autosell charges a small service fee depending on your sales volume."
              )
            }
          >
            Is there a fee?
          </QuestionButton>
          <QuestionButton
            onClick={() =>
              handleQuickQuestion(
                "How does it work?",
                "Autosell works by integrating with your product listings and automating the sales process."
              )
            }
          >
            How does it work?
          </QuestionButton>
        </QuickQuestions>

        {messages.map((message) => (
          <Message key={message.id} $sender={message.$sender}>
            {message.$sender === "bot" ? (
              <>
                <img
                  src={BotLogo}
                  alt="Bot Logo"
                  style={{
                    width: "2rem",
                    height: "2rem",
                    borderRadius: "50%",
                  }}
                />
                <BotMessage>{message.text}</BotMessage>
              </>
            ) : (
              <UserMessage>{message.text}</UserMessage>
            )}
          </Message>
        ))}
      </ChatBody>

      <TypeMessage>
        <Input
          type="text"
          placeholder="Type your message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && handleSend()}
        />
        <SendButton onClick={handleSend}>
          <IoSend />
        </SendButton>
      </TypeMessage>
    </Wrapper>
  );
};

export default ChatAutomation;
