import React from "react";
import ServiceSection from "../../../components/service-section/service-section";

const services = [
  "Post videos",
  "Post carousels",
  "Post text and images",
  "Upload stories",
  "Add, connect, and message others",
  "Your new version of LinkedIn and Facebook",
];

const Section6 = () => {
  const imageSrc = "";

  return (
    <ServiceSection
      title="Newsfeed Upload"
      services={services}
      imageSrc={imageSrc}
    />
  );
};

export default Section6;
