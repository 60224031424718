import MobileInbox from "../../app/chat-system/mobile-version/mobile-inbox/mobile-inbox";
import MobileNav from "../../app/app-components/app-navbar/mobile-navbar";

const MobileInboxPage = () => {
  return (
    <>
      <MobileInbox />
      <MobileNav />
    </>
  );
};

export default MobileInboxPage;
