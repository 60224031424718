import styled, { keyframes } from "styled-components";
import { breakpoint, color } from "../../../themes/themes";

export const bounceAndRotate = keyframes`
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
  30% {
    transform: translateY(-10px) rotate(-10deg);
    opacity: 0.8;
  }
  50% {
    transform: translateY(-5px) rotate(10deg);
    opacity: 1;
  }
  70% {
    transform: translateY(0) rotate(0deg);
    opacity: 0.8;
  }
  100% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 1rem 0 0;
  border-radius: 0.5rem;
  overflow: hidden;
  background-color: #fff;
  min-height: calc(100vh - 6rem);

  ${breakpoint.mobile} {
    margin: 0;
    min-height: calc(100vh - 5rem);
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 1rem;
  background-color: #fff;
  border-bottom: 1px solid #ddd;

  h1 {
    margin: 0;
  }

  svg {
    font-size: 2rem;
    animation: ${bounceAndRotate} 2s;
    color: ${color.primary};
  }
`;

export const ChatBody = styled.div`
  flex-grow: 1;
  padding: 0 1rem 1rem;
  overflow-y: auto;
  background-color: #fff;
  max-height: 68vh;

  ${breakpoint.mobile} {
    max-height: 75vh;
  }
`;

export const Message = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-start;
  gap: 0.4rem;
  justify-content: ${(props) =>
    props.$sender === "user" ? "flex-end" : "flex-start"};
  font-size: 0.9rem;
`;

export const BotMessage = styled.div`
  background-color: #e0f7fa;
  border-radius: 1rem;
  padding: 1rem;
  max-width: 70%;
  align-self: flex-start;
`;

export const UserMessage = styled.div`
  background-color: ${color.lightviolet};
  border-radius: 1rem;
  padding: 1rem;
  max-width: 70%;
  align-self: flex-end;
`;

export const TypeMessage = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem;
  border-top: 1px solid #ddd;
  background-color: #fff;
  margin-top: auto;
`;

export const Input = styled.input`
  flex-grow: 1;
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 20px;
  outline: none;
  margin-right: 0.5rem;
`;

export const SendButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;

  &:hover {
    color: #0056b3;
  }
`;

export const QuickQuestions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.8rem;
  margin: 1.5rem 0;
`;

export const QuestionButton = styled.button`
  background-color: ${color.transparent};
  border: 1px solid ${color.primary};
  border-radius: 2rem;
  padding: 0.7rem 1rem;
  cursor: pointer;

  &:hover {
    background-color: ${color.primary};
    color: ${color.white};
  }
`;
