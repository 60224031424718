import styled from "styled-components";
import { breakpoint } from "../../../../themes/themes";

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

export const ModalImage = styled.img`
  width: auto;
  height: 100%;
  ${breakpoint.mobile} {
    width: 100%;
    height: 60vh;
    object-fit: cover;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 2rem;
  right: 2rem;
  color: #fff;
  background: transparent;
  border: none;
  cursor: pointer;
  svg {
    font-size: 1.3rem;
  }
  ${breakpoint.mobile} {
    right: 1rem;
  }
`;

export const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.3);
  border: none;
  cursor: pointer;
  z-index: 1111;
  padding: 0.6rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s;
  &:hover {
    background: rgba(255, 255, 255, 0.6);
  }
  &.left {
    left: 2rem;
  }
  &.right {
    right: 2rem;
  }
  svg {
    display: flex;
    font-size: 1.6rem;
    color: white;
  }
  ${breakpoint.mobile} {
    display: none;
  }
`;

export const Dot = styled.span`
  width: 0.6rem;
  height: 0.6rem;
  background: ${(props) =>
    props.$active ? "#fff" : "rgba(255, 255, 255, 0.5)"};
  border-radius: 50%;
  transition: background 0.3s;
`;

export const DotContainer = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1.5rem;
  ${breakpoint.mobile} {
    display: flex;
  }
`;
