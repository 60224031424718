import React from "react";
import Container from "../../../components/container/container";
import SectionTitle from "../../../components/section-title/section-title";
import { PiShareNetworkFill } from "react-icons/pi";
import {
  MdQuestionAnswer,
  MdPeopleAlt,
  MdDeviceUnknown,
  MdVideoCall,
} from "react-icons/md";
import { HiPuzzle } from "react-icons/hi";
import { Wrapper, ListItem, Item } from "./section2.styled";

const programs = [
  {
    icon: <PiShareNetworkFill />,
    text: "Build connections within the group chat.",
  },
  {
    icon: <MdQuestionAnswer />,
    text: "Ask questions to experts in your field.",
  },
  { icon: <HiPuzzle />, text: "Enjoy weekly challenges and prizes." },
  { icon: <MdPeopleAlt />, text: "Get paired with random people." },
  {
    icon: <MdDeviceUnknown />,
    text: "Chat anonymously and promote your social media.",
  },
  { icon: <MdVideoCall />, text: "Webinars and Video Calls" },
];

const Section2 = () => {
  return (
    <Wrapper>
      <Container>
        <SectionTitle title="Community Programs" />
        <ListItem>
          {programs.map((program, index) => (
            <Item key={index}>
              {program.icon}
              <h3>{program.text}</h3>
            </Item>
          ))}
        </ListItem>
      </Container>
    </Wrapper>
  );
};

export default Section2;
