import styled from "styled-components";
import { color } from "../../../themes/themes";

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div`
  background-color: white;
  padding: 1.2rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 32rem;
  position: relative;
  margin: 0 1rem;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
`;

export const CommentList = styled.div`
  margin-bottom: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  max-height: 40vh;
  overflow-y: auto;
  background-color: ${color.lightviolet};
  border-radius: 0.5rem;
  padding: 1rem;
`;

export const CommentItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.6rem;
`;

export const ProfileImage = styled.img`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  object-fit: cover;
`;

export const CommentItem = styled.div`
  align-self: flex-start;
  padding: 1rem;
  background-color: ${color.white};
  border-radius: 0.5rem;
  font-size: 0.9rem;
`;

export const Username = styled.span`
  font-weight: bold;
  display: block;
  margin-bottom: 0.25rem;
`;

export const InputContainer = styled.div`
  display: flex;
  margin-top: 0.6rem;
`;

export const Input = styled.input`
  flex: 1;
  padding: 0.5rem;
  border: 0.0625rem solid #ddd;
  border-radius: 0.5rem;
  margin-right: 0.6rem;
`;

export const Button = styled.button`
  padding: 0.5rem 0.8rem;
  background-color: ${color.primary};
  color: ${color.white};
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  &:hover {
    background-color: ${color.lightblue};
  }
`;
