import React from "react";
import DefaultHeader from "../../../app-components/community-header/community-header";

// Autosell Community Header
const ACommunityHeader = () => {
  const communityItems = [
    { name: "Autosell Community", path: "/autosell-community" },
    { name: "Link 2", path: "/under-construction" },
    { name: "Link 3", path: "/under-construction" },
    { name: "Link 4", path: "/under-construction" },
  ];

  return (
    <div>
      <DefaultHeader items={communityItems} />
    </div>
  );
};

export default ACommunityHeader;
