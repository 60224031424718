import React, { useState } from "react";
import Container from "../container/container";
import {
  Wrapper,
  QuizContainer,
  Question,
  SuggestionList,
  SuggestionItem,
  AnswerInput,
  ButtonContainer,
  Button,
  ProgressBarContainer,
  ProgressBarFill,
} from "./quiz-form.styled";

const QuizComponent = ({ questions }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // Track which question
  const [answers, setAnswers] = useState({}); // Store all answers here

  // When user types in an answer
  const handleAnswerChange = (e) => {
    setAnswers({
      ...answers,
      [currentQuestionIndex]: e.target.value, // Save the answer under the current question index
    });
  };

  // When user clicks a suggestion
  const handleSuggestionClick = (suggestion) => {
    setAnswers({
      ...answers,
      [currentQuestionIndex]: suggestion, // Update answer with the clicked suggestion
    });
  };

  // Move to the next question (if there is one more)
  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  // Move back to the previous question (if possible)
  const handlePrev = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  // Handle form submission
  const handleSubmit = () => {
    console.log("Submitted Answers: ", answers); // For debugging
    alert("Quiz Submitted!"); // Simple feedback for the user
  };

  const currentQuestion = questions[currentQuestionIndex]; // Get the current question

  // Calculate how far along we are in the quiz
  const progress = ((currentQuestionIndex + 1) / questions.length) * 100;

  return (
    <Wrapper>
      <ProgressBarContainer>
        <ProgressBarFill style={{ width: `${progress}%` }} />
      </ProgressBarContainer>

      <Container>
        <QuizContainer>
          <Question>{currentQuestion.question}</Question>

          {/* If there are suggestions, render them */}
          {currentQuestion.suggestions && (
            <SuggestionList>
              {currentQuestion.suggestions.map((suggestion, index) => (
                <SuggestionItem
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)} // Click to auto-fill answer
                >
                  {suggestion}
                </SuggestionItem>
              ))}
            </SuggestionList>
          )}

          <AnswerInput
            type="text"
            name="answer"
            value={answers[currentQuestionIndex] || ""} // Show the current answer if available
            onChange={handleAnswerChange}
            placeholder="Enter your answer"
          />
          <ButtonContainer>
            <Button onClick={handlePrev} disabled={currentQuestionIndex === 0}>
              Previous
            </Button>

            {/* Show submit button on the last question, otherwise show next */}
            {currentQuestionIndex === questions.length - 1 ? (
              <Button $primary onClick={handleSubmit}>
                Submit
              </Button>
            ) : (
              <Button
                $primary
                onClick={handleNext}
                disabled={!answers[currentQuestionIndex]} // Disable next if no answer
              >
                Next
              </Button>
            )}
          </ButtonContainer>
        </QuizContainer>
      </Container>
    </Wrapper>
  );
};

const QuizForm = () => {
  const questions = [
    {
      question:
        "Which of the following industries are you in or interested in?",
      suggestions: [
        "Digital Product Seller",
        "Freelancer",
        "Business Owner",
        "Affiliate Marketer",
      ],
    },
    {
      question: "How long have you been an entrepreneur?",
      suggestions: [
        "Less than a year",
        "1-3 years",
        "3-5 years",
        "More than 5 years",
      ],
    },
    {
      question: "What type of business do you run?",
      suggestions: ["E-commerce", "Freelancing", "Consulting", "Restaurant"],
    },
    {
      question: "What challenges do you face as an entrepreneur?",
      suggestions: [
        "Finding clients",
        "Managing time",
        "Financial management",
        "Scaling the business",
      ],
    },
  ];

  return <QuizComponent questions={questions} />;
};

export default QuizForm;
