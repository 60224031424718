import React from "react";
import ServiceSection from "../../../components/service-section/service-section";

const services = [
  "Offer your services",
  "Hire someone to do the work for you",
  "Protection against scammers",
  "Unlimited uploads",
  "Built-in landing page for you",
];

const Section3 = () => {
  const imageSrc = "";

  return (
    <ServiceSection
      title="Offer and Hire Services"
      services={services}
      imageSrc={imageSrc}
    />
  );
};

export default Section3;
