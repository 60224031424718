import React from "react";
import { Link } from "react-router-dom";
import { navLinksArray } from "./navbar-links";
import { DesktopWrapper, DesktopLinkItem } from "./navbar.styled";

const DesktopNav = () => {
  return (
    <DesktopWrapper>
      {navLinksArray.slice(0, 5).map((link, index) => (
        <Link to={link.path} key={index}>
          <DesktopLinkItem>{link.name}</DesktopLinkItem>
        </Link>
      ))}
    </DesktopWrapper>
  );
};

export default DesktopNav;
