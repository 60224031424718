import styled from "styled-components";
import { breakpoint, color } from "../../../themes/themes";

export const Wrapper = styled.div`
  padding: 0.8rem;
  margin-left: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  height: calc(100vh - 6rem);
  display: flex;
  flex-direction: column;

  ${breakpoint.mobile} {
    border-radius: 0;
    padding: 6rem 0.8rem 0;
    margin-left: 0;
  }
`;

export const ListItem = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 0;
  gap: 0.5rem;
  overflow-y: hidden;
  flex-grow: 1;

  &:hover {
    overflow-y: auto;
  }

  ${breakpoint.tablet} {
    overflow-y: auto;
  }

  ${breakpoint.mobile} {
    overflow-y: auto;
  }

  &::-webkit-scrollbar {
    width: 0.6rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #eee;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }
`;

export const Item = styled.li`
  padding: 0.8rem 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  border-radius: 0.5rem;

  &:hover {
    background-color: ${color.primary};

    svg {
      color: ${color.white};
    }

    p {
      color: ${color.white};
    }
  }
`;

export const Icon = styled.div`
  svg {
    display: flex;
    font-size: 1.4rem;
  }
`;

export const Title = styled.div`
  p {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
  }
`;
