import ProfileImg from "../../../assets/Story1.png";

const hiringData = [
  {
    name: "Annie Ramirez",
    timestamp: "3 days ago",
    profileImg: ProfileImg,
    labels: ["Hiring", "Part-time"],
    cost: "Php 20,000/month",
    content:
      'Hi, I\'m a newly business owner ng isang coffee shop. I wanted to hire (part timer) to do our social media promotion like video content and other "pakulo" things to boost our sales but the thing is bago pa lang ang shop at madami pa kami expenses para maka pag hire ng professiona which is medyo pricey. Taking chances na baka meron dito na gusto mag offer ng good deals?',
    interactions: {
      likes: 14,
      comments: 29,
      shares: 10,
    },
  },
  {
    name: "John Doe",
    timestamp: "1 week ago",
    profileImg: ProfileImg,
    labels: ["Hiring", "Full-time"],
    cost: "Php 40,000/month",
    content:
      "Looking for a full-time barista for my expanding coffee shop. Must have experience in latte art and customer service.",
    interactions: {
      likes: 27,
      comments: 18,
      shares: 10,
    },
  },
  {
    name: "Annie Ramirez",
    timestamp: "3 days ago",
    profileImg: ProfileImg,
    labels: ["Hiring", "One-time"],
    cost: "Php 200,000/month",
    content:
      'Hi, I\'m a newly business owner ng isang coffee shop. I wanted to hire (part timer) to do our social media promotion like video content and other "pakulo" things to boost our sales but the thing is bago pa lang ang shop at madami pa kami expenses para maka pag hire ng professiona which is medyo pricey. Taking chances na baka meron dito na gusto mag offer ng good deals?',
    interactions: {
      likes: 190,
      comments: 5,
      shares: 10,
    },
  },
  {
    name: "Annie Ramirez",
    timestamp: "3 days ago",
    profileImg: ProfileImg,
    labels: ["Hiring", "One-time"],
    cost: "Php 30,000/month",
    content:
      'Hi, I\'m a newly business owner ng isang coffee shop. I wanted to hire (part timer) to do our social media promotion like video content and other "pakulo" things to boost our sales but the thing is bago pa lang ang shop at madami pa kami expenses para maka pag hire ng professiona which is medyo pricey. Taking chances na baka meron dito na gusto mag offer ng good deals?',
    interactions: {
      likes: 2,
      comments: 8,
      shares: 11,
    },
  },
];

export default hiringData;
