import styled from "styled-components";
import { breakpoint } from "../../../../../themes/themes";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
`;

export const Track = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  cursor: grabbing;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Card = styled.div`
  scroll-snap-align: center;
  flex-shrink: 0;
  width: 100%;
  max-height: 50vh;

  img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }

  ${breakpoint.mobile} {
    max-height: 70vh;
  }
`;

export const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
`;

export const Dot = styled.div`
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background-color: ${({ $active }) => ($active ? "#000" : "#ccc")};
  transition: background-color 0.3s ease-in-out;
`;
