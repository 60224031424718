import styled from "styled-components";
import { breakpoint, color } from "../../themes/themes";

export const Wrapper = styled.section`
  padding: 5rem 0;
  border-bottom: 1px solid #ddd;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  margin-top: 3rem;
  padding: 3rem 4rem;
  gap: 3rem;
  background-image: linear-gradient(to top, #ace0f9 0%, #fff1eb 100%);
  border-radius: 2rem;

  ${breakpoint.mobile} {
    padding: 3rem 2rem;
    grid-template-columns: repeat(1, 1fr);
    border-radius: 1rem;
    gap: 2rem;

    & > *:nth-child(1) {
      order: 2;
    }
    & > *:nth-child(2) {
      order: 1;
    }
  }
`;

export const ListItem = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    font-size: 1.8rem;
    font-weight: 600;
    display: flex;
    align-items: center;

    .check-icon {
      margin-right: 1rem;
      color: ${color.primary};
    }

    ${breakpoint.tablet} {
      font-size: 1.1rem;
    }

    ${breakpoint.mobile} {
      font-size: 1.1rem;
    }
  }
`;

export const Thumbnail = styled.div`
  border-radius: 1rem;
  overflow: hidden;
  img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
