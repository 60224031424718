import React from "react";
import AppLayout from "../../app/app-components/app-layout/app-layout";
import AppHeader from "../../app/app-components/app-navbar/app-header";
import MobileNav from "../../app/app-components/app-navbar/mobile-navbar";
import Hiring from "../../app/hiring/hiring/hiring";
import Dashboard from "../../app/app-components/dashboard/dashboard";
import HiringSettings from "../../app/hiring/hiring-settings/hiring-settings";

const HiringPage = () => {
  return (
    <>
      <AppHeader />
      <AppLayout
        leftContent={<Dashboard />}
        centerContent={<Hiring />}
        rightContent={<HiringSettings />}
      />
      <MobileNav />
    </>
  );
};

export default HiringPage;
