import styled from "styled-components";
import { breakpoint, color } from "../../../themes/themes";

export const Wrapper = styled.div`
  padding: 7rem 0 5rem;
  background-color: ${color.lightviolet};
`;

export const PaymentFormWrapper = styled.div`
  max-width: 35rem;
  margin: 0 auto;
  padding: 3rem 2rem 2rem;
  background-color: ${color.white};
  border-radius: 1rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  ${breakpoint.mobile} {
    margin: 0 1.5rem;
  }
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

export const InlineContainer = styled.div`
  display: flex;
  gap: 0.8rem;
`;

export const Title = styled.h2`
  font-size: 1.5rem;
  margin: 0;
  color: ${color.primary};
`;

export const Label = styled.label`
  font-size: 1rem;
  font-weight: 600;
  display: block;
`;

export const InputBase = styled.input`
  width: 100%;
  padding: 1rem;
  margin-top: 5px;
  font-size: 0.9rem;
  border: 1px solid ${color.transparent};
  background-color: #f8f8f8;
  border-radius: 0.5rem;
  transition: border-color 0.2s;

  &:focus {
    border-color: ${color.primary};
    outline: none;
  }
`;

export const Input = styled(InputBase).attrs({ type: "text" })``;
export const Select = styled(InputBase).attrs({ as: "select" })``;

export const PaymentOptions = styled.div`
  display: flex;
  gap: 1rem;

  ${breakpoint.mobile} {
    flex-direction: column;
  }
`;

export const PaymentOptionButton = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1.4rem;
  font-size: 0.9rem;
  border: 1px solid ${color.primary};
  border-radius: 0.5rem;
  cursor: pointer;
  background-color: ${color.white};
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;

  &:hover {
    border: 1px solid ${color.link};
  }

  img {
    width: 5rem;
  }

  ${breakpoint.mobile} {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem;

    img {
      width: 6rem;
    }
  }
`;

export const SubmitButton = styled.button`
  padding: 1rem;
  font-size: 1rem;
  color: white;
  background-color: ${color.primary};
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${color.lightblue};
  }
`;

export const Disclaimer = styled.p`
  font-size: 0.8rem;
  color: #ccc;
  margin-top: 1rem;
`;
