import React from "react";
import Container from "../../components/container/container";
import { Wrapper, HeroContent, Title, Button } from "./hero.styled";

const Hero = () => {
  return (
    <Wrapper>
      <Container>
        <HeroContent>
          <Title>
            #1 <span>Online Earning</span> Platform for Entrepreneurs
          </Title>
          <Button aria-label="Shop Now">Get Started</Button>
        </HeroContent>
      </Container>
    </Wrapper>
  );
};

export default Hero;
