import React, { useState, useRef } from "react";
import { GoFileMedia, GoCalendar } from "react-icons/go";
import { color } from "../../../themes/themes";
import {
  Wrapper,
  UserSection,
  ProfileImageWrapper,
  TextInput,
  SettingsSection,
  ActionList,
  ActionItem,
  StyledIcon,
  SubmitButton,
} from "./create-post.styled";
import newsFeedApi from "../../../api/newsFeedApi";
import { useAuth } from "../../../context/AuthContext";

const CreatePost = ({ addNewPost }) => {
  const privacy = ["PUBLIC", "FRIENDS", "ONLY_ME"];
  const [files, setFiles] = useState([]);
  const [content, setContent] = useState("");
  const [privacyLevel, setPrivacyLevel] = useState(privacy[0]);

  const { user } = useAuth();

  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    setFiles([...e.target.files]);
  };

  const handleMediaClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("user")).access_token;
    try {
      if (!token) throw Error("missing token.");
      if (content.length < 1) throw Error("Content is required.");
      const post = await newsFeedApi.post(token, content, privacyLevel, files);
      if (post) {
        addNewPost(post);
        setContent("");
        setFiles([]);
        setPrivacyLevel(privacy[0]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const actionItems = [
    {
      id: 1,
      icon: <GoFileMedia />,
      label: "Media",
      color: color.primary,
      onClick: handleMediaClick,
    },
    {
      id: 2,
      icon: <GoCalendar />,
      label: "Event",
      color: "#FF0000",
      onClick: () => {},
    },
  ];

  return (
    <Wrapper onSubmit={handleSubmit}>
      <UserSection>
        <ProfileImageWrapper>
          <img
            src={user?.profile?.profilePictureUrl || "/default-profile.png"}
            alt="profile"
          />
        </ProfileImageWrapper>
        <TextInput
          id="post-input"
          name="post"
          placeholder="What's on your mind..."
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
      </UserSection>
      {files.map((file) => (
        <span key={file.name}>{file.name},</span>
      ))}
      <SettingsSection>
        <ActionList>
          {actionItems.map((item) => (
            <ActionItem key={item.id} onClick={item.onClick}>
              <StyledIcon color={item.color}>{item.icon}</StyledIcon>
              <p>{item.label}</p>
            </ActionItem>
          ))}
        </ActionList>
        <SubmitButton type="submit">Post</SubmitButton>
      </SettingsSection>

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        multiple
        onChange={handleFileChange}
      />
    </Wrapper>
  );
};

export default CreatePost;
