import React, { useRef, useState, useEffect, useCallback } from "react";
import Image1 from "../../../../../assets/announcement1.jpg";
import Image2 from "../../../../../assets/announcement2.jpg";
import Image3 from "../../../../../assets/announcement3.jpg";
import { Wrapper, Track, Card, DotsContainer, Dot } from "./carousel.styled";

const Carousel = () => {
  const carouselRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  const images = [Image1, Image2, Image3];

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - carouselRef.current.offsetLeft);
    setScrollLeft(carouselRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - carouselRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    carouselRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => setIsDragging(false);
  const handleMouseLeave = () => setIsDragging(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % images.length;
        carouselRef.current.scrollLeft =
          nextIndex * carouselRef.current.clientWidth;
        return nextIndex;
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);

  const updateActiveIndex = useCallback(() => {
    const { scrollLeft, clientWidth } = carouselRef.current;
    const index = Math.round(scrollLeft / clientWidth);
    setActiveIndex(index);
  }, []);

  useEffect(() => {
    const ref = carouselRef.current;

    const handleScroll = () => {
      updateActiveIndex();
    };

    ref.addEventListener("scroll", handleScroll);

    return () => ref.removeEventListener("scroll", handleScroll);
  }, [updateActiveIndex]);

  return (
    <Wrapper>
      <Track
        ref={carouselRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
      >
        {images.map((image, index) => (
          <Card key={index}>
            <img src={image} alt={`announcement ${index + 1}`} />
          </Card>
        ))}
      </Track>
      <DotsContainer>
        {images.map((_, index) => (
          <Dot key={index} $active={index === activeIndex} />
        ))}
      </DotsContainer>
    </Wrapper>
  );
};

export default Carousel;
