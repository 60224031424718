import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Facebook from "../../assets/facebook.png";
import Google from "../../assets/google.png";
import {
  Wrapper,
  LoginForm as SignupForm,
  Title,
  Form,
  Input,
  LinkText,
  Button,
  Separator,
  FacebookButton,
  GoogleButton,
  Icon,
} from "../login/login.styled";
import { useAuth } from "../../context/AuthContext";

const Signup = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const { register } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!username || !email || !password || !confirmPassword) {
      alert("Please fill in all fields.");
      return;
    }
    if (password !== confirmPassword) {
      alert("Passwords do not match.");
      return;
    }

    // register api call
    try {
      await register(username, email, password, confirmPassword);
      navigate("/newsfeed");
    } catch (e) {
      setError("Login failed. Please check your credentials." + e);
      console.log(e);
    }
  };

  return (
    <Wrapper>
      <SignupForm>
        <Title>Sign Up</Title>

        <Form onSubmit={handleSubmit}>
          <label>
            <Input
              type="text"
              placeholder="Username*"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              autoComplete="username"
            />
          </label>

          <label>
            <Input
              type="email"
              placeholder="Email*"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              autoComplete="email"
            />
          </label>

          <label>
            <Input
              type="password"
              placeholder="Password*"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              autoComplete="new-password"
            />
          </label>

          <label>
            <Input
              type="password"
              placeholder="Confirm Password*"
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              autoComplete="new-password"
            />
          </label>
          <p>{error}</p>
          <Button type="submit">Sign Up</Button>
        </Form>

        <LinkText>
          Already have an account?{" "}
          <span onClick={() => navigate("/login")}>Log in</span>
        </LinkText>

        <Separator>or</Separator>

        <FacebookButton>
          <Icon>
            <img src={Facebook} alt="Facebook Icon" />
          </Icon>
          Login with Facebook
        </FacebookButton>

        <GoogleButton>
          <Icon>
            <img src={Google} alt="Google Icon" />
          </Icon>
          Login with Google
        </GoogleButton>
      </SignupForm>
    </Wrapper>
  );
};

export default Signup;
