import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Wrapper,
  LoginForm as ResetPassForm,
  Title,
  Form,
  Input,
  LinkText,
  Button,
  PasswordRequirements,
} from "../login/login.styled";

const passwordRequirements = [
  "Your password can't be too similar to your other personal information.",
  "Your password must contain at least 8 characters.",
  "Your password can't be a commonly used password.",
  "Your password can't be entirely numeric.",
];

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();

  // Here’s where you can add a handleSubmit function to process the form submission.

  return (
    <Wrapper>
      <ResetPassForm>
        <Title>Reset Password</Title>

        <Form>
          <label>
            <Input
              type="password"
              placeholder="New password*"
              name="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              autoComplete="new-password"
            />
          </label>

          <label>
            <Input
              type="password"
              placeholder="New password confirmation*"
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              autoComplete="new-password"
            />
          </label>

          <PasswordRequirements>
            {passwordRequirements.map((requirement, index) => (
              <li key={index}>{requirement}</li>
            ))}
          </PasswordRequirements>
          <Button type="submit">Reset Password</Button>
        </Form>

        <LinkText>
          Remembered your password?{" "}
          <span onClick={() => navigate("/login")}>Login</span>
        </LinkText>
      </ResetPassForm>
    </Wrapper>
  );
};

export default ResetPassword;
