import React, { useState, useEffect } from "react";
import Stories from "./stories/stories";
import NewsFeedPost from "./newsfeed-post/newsfeed-post";
import CreatePost from "./create-post/create-post";
import styled from "styled-components";
import { breakpoint } from "../../themes/themes";
import newsFeedApi from "../../api/newsFeedApi";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin: 0 0 1rem 0;
  min-height: 100vh;

  ${breakpoint.mobile} {
    margin: 0 0 5rem 0;
  }
`;

const NewsFeed = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    getPosts();
  }, []);

  const getPosts = async () => {
    try {
      const res = await newsFeedApi.getNewsFeed();
      setPosts(res);
    } catch (e) {
      console.log(e);
    }
  };

  const addNewPost = (newPost) => {
    setPosts([newPost, ...posts]);
  };

  const updateAllPost = (newPosts) => {
    setPosts(newPosts);
  };

  return (
    <Wrapper>
      <Stories />
      <CreatePost addNewPost={addNewPost} />
      <NewsFeedPost posts={posts} updateAllPost={updateAllPost} />
    </Wrapper>
  );
};

export default NewsFeed;
