import styled from "styled-components";
import { breakpoint, color } from "../../themes/themes";

export const Wrapper = styled.section`
  padding: 8rem 0 6rem;
  background-color: ${color.lightviolet};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

export const Categories = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  li {
    list-style-type: none;
    padding: 1rem;
    background-color: ${color.white};
    border-radius: 0.4rem;
    box-shadow: 0 15px 15px -5px hsla(0, 0%, 0%, 0.07);
    cursor: pointer;

    &:hover {
      background-color: ${color.primary};
      color: ${color.white};
    }

    &.active {
      background-color: ${color.primary};
      color: ${color.white};
    }
  }
`;

export const ListItem = styled.ul`
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-top: 2.5rem;
  gap: 1.8rem;

  ${breakpoint.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${breakpoint.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Item = styled.li`
  list-style-type: none;
  border-radius: 0.9rem;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 15px 15px -5px hsla(0, 0%, 0%, 0.07);

  &:hover img {
    transform: scale(1.1);
  }
`;

export const Thumbnail = styled.div`
  overflow: hidden;

  img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.2s ease-in-out;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.4rem;
  background-color: ${color.white};
  height: 100%;
  gap: 1.4rem;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  h2,
  p {
    margin: 0;
  }

  h2 {
    font-size: 0.9rem;
  }

  p {
    font-size: 0.7rem;
    color: ${color.gray};
  }
`;

export const Rating = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

export const Stars = styled.div`
  display: flex;
  gap: 0.2rem;

  svg {
    color: ${color.lightgreen};
    font-size: 0.8rem;
  }
`;

export const Sales = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;

  svg {
    color: ${color.gray};
    font-size: 0.8rem;
  }

  p {
    font-size: 0.8rem;
    margin: 0;
  }
`;
