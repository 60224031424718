import styled from "styled-components";
import { breakpoint, color } from "../../../themes/themes";

export const Wrapper = styled.div`
  background-color: ${color.white};
  color: ${color.darkBg};
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-radius: 0.5rem;
  position: fixed;
  padding: 1rem;
  right: 1rem;
  top: 4rem;
  width: 23rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1111;
  overflow: hidden;

  ${breakpoint.mobile} {
    top: 14rem;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    right: 0;
  }

  h3 {
    margin: 0;
    font-weight: 600;
  }
`;

export const UserAccount = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.8rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

export const ProfilePicture = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  li {
    padding: 0.6rem 0.8rem;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    font-size: 0.9rem;
    border-radius: 0.5rem;
    cursor: pointer;

    &:hover {
      background-color: #f1f1f1;
    }

    svg {
      background-color: #eee;
      border-radius: 50%;
      padding: 0.5rem;
      font-size: 2.4rem;
    }
  }
`;
