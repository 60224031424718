import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalLayout from "../../../app-components/modal-layout/modal-layout";
import { IoExpandOutline } from "react-icons/io5";
import { RiEditLine } from "react-icons/ri";
import { FiSearch } from "react-icons/fi";
import { RiRobot2Fill } from "react-icons/ri";
import NewMessage from "../new-message/new-message";
import ChatPopup from "../chat-popup/chat-popup";
import {
  Container,
  Header,
  IconGroup,
  SearchContainer,
  SearchInput,
  SearchButton,
  ChatList,
  ChatItem,
  ChatLeft,
  Avatar,
  UserInfo,
  UserName,
  UserMessage,
  Timestamp,
  StatusIndicator,
  AutomateButton,
} from "./modal-inbox.styled";
import chats from "../../database/chats-data";

const ModalInbox = () => {
  const [activePopup, setActivePopup] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);

  const navigate = useNavigate();

  const openPopup = (popupType, chat = null) => {
    setActivePopup(popupType);
    if (popupType === "chatPopup") {
      setSelectedChat(chat);
    } else {
      setSelectedChat(null);
    }
  };

  const closePopup = () => {
    setActivePopup(null);
    setSelectedChat(null);
  };

  return (
    <>
      {activePopup === "newMessage" ? (
        <NewMessage onClose={closePopup} />
      ) : activePopup === "chatPopup" && selectedChat ? (
        <ChatPopup chat={selectedChat} onClose={closePopup} />
      ) : (
        <ModalLayout>
          <Container>
            <Header>
              <h1>Chats</h1>
              <IconGroup>
                <IoExpandOutline />
                <RiEditLine onClick={() => openPopup("newMessage")} />
              </IconGroup>
            </Header>
            <SearchContainer>
              <SearchInput
                type="text"
                placeholder="Search users..."
                name="search"
              />
              <SearchButton>
                <FiSearch />
              </SearchButton>
            </SearchContainer>
            <ChatList>
              {chats.map((chat, index) => (
                <ChatItem
                  key={index}
                  onClick={() => openPopup("chatPopup", chat)}
                >
                  <ChatLeft>
                    <Avatar>
                      <img src={chat.image} alt="profile" />
                    </Avatar>
                    <UserInfo>
                      <UserName>{chat.name}</UserName>
                      <UserMessage>
                        <p>
                          {chat.message.length > 25
                            ? `${chat.message.substring(0, 25)}...`
                            : chat.message}
                        </p>
                        <Timestamp>{chat.timestamp}</Timestamp>
                      </UserMessage>
                    </UserInfo>
                  </ChatLeft>
                  <StatusIndicator
                    style={{ display: chat.isActive ? "block" : "none" }}
                  />
                </ChatItem>
              ))}
            </ChatList>
            <AutomateButton onClick={() => navigate("/autosell-assist")}>
              <RiRobot2Fill />
              Let Us Automate
            </AutomateButton>
          </Container>
        </ModalLayout>
      )}
    </>
  );
};

export default ModalInbox;
