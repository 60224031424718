import React from "react";
import { FiSearch } from "react-icons/fi";
import {
  SortingWrapper,
  SearchBar,
  Input,
  Button,
  Select,
  PriceRange,
  PriceValue,
  FilterButton,
  Note,
} from "../services/services.styled";

const DigitalProductsSorting = ({
  search,
  setSearch,
  category,
  setCategory,
  price,
  setPrice,
  handlePriceFilter,
}) => {
  return (
    <SortingWrapper>
      <SearchBar>
        <Input
          type="text"
          placeholder="Search users..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button>
          <FiSearch />
        </Button>
      </SearchBar>
      <Select onChange={(e) => setCategory(e.target.value)} value={category}>
        <option value="">All Categories</option>
        <option value="Video Software">Video Software</option>
        <option value="Image Editors">Image Editors</option>
        <option value="WooCommerce">WooCommerce</option>
      </Select>

      <h4>Filter Price</h4>
      <PriceRange
        type="range"
        min="0"
        max="1000"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
      />
      <PriceValue>{`Max Price: $${price}`}</PriceValue>
      <FilterButton onClick={handlePriceFilter}>Filter</FilterButton>

      <Note>
        <p>
          News feed Hiring Challenge Only upload digital products (templates,
          trackers, designs, lessons, audios, books, et...). Services are not
          allowed. Doing so may lower your account's visibility if flagged.
        </p>
      </Note>
    </SortingWrapper>
  );
};

export default DigitalProductsSorting;
