import React from "react";
import CommunityLayout from "../../app/app-components/app-layout/community-layout";
import ChatAnonymously from "../../app/community/chat-anonymously/main/chat-anonymously";
import Dashboard from "../../app/app-components/dashboard/dashboard";

const CommunityPage = () => {
  return (
    <>
      <CommunityLayout
        leftContent={<Dashboard />}
        rightContent={<ChatAnonymously />}
      />
    </>
  );
};

export default CommunityPage;
