import styled from "styled-components";
import { breakpoint, color } from "../../../../themes/themes";

export const Container = styled.div`
  background-color: ${color.white};
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
  background-color: ${color.primary};
  color: ${color.white};
  padding: 1.5rem 1rem;
  position: fixed;

  h4 {
    margin: 0;
  }

  svg {
    display: flex;
    cursor: pointer;
    font-size: 1.5rem;
  }

  ${breakpoint.mobile} {
    h4 {
      font-size: 1.1rem;
    }
  }
`;

export const Body = styled.div`
  padding: 1rem;
  overflow-y: auto;
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  margin: 5rem 0;
`;

export const ChatMessage = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.4rem;
  justify-content: ${({ $isUserMessage }) =>
    $isUserMessage ? "flex-end" : "flex-start"};

  p {
    font-size: 0.8rem;
    padding: 0.6rem 1rem;
    border-radius: 1rem;
    margin: 0;
    background-color: ${({ $isUserMessage }) =>
      $isUserMessage ? "#F7F0FF" : "#f5f5f5"};
    max-width: 70%;
    word-wrap: break-word;
    overflow-wrap: break-word;

    ${breakpoint.mobile} {
      font-size: 1rem;
    }
  }
`;

export const Avatar = styled.div`
  display: flex;
  width: 1.9rem;
  height: 1.9rem;
  border-radius: 50%;
  overflow: hidden;

  img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Footer = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: ${color.white};
`;

export const MessageInput = styled.input`
  padding: 0.8rem;
  border-radius: 10rem;
  width: 100%;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  margin-right: 0.5rem;
  &:focus {
    border-color: ${color.primary};
    outline: none;
  }
`;

export const SendButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${color.primary};
  font-size: 1.5rem;
  padding: 0;

  &:hover {
    color: ${color.lightblue};
  }

  svg {
    display: flex;
  }
`;
