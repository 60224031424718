import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import MyCommunityHeader from "../header/header";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaPlus } from "react-icons/fa"; // For FAB icon
import { IoClose } from "react-icons/io5";
import {
  Wrapper,
  Container,
  FABButton,
  ModalBackdrop,
  ModalContent,
  CloseButton,
} from "./calendar.styled";

// Localizer for the calendar
const localizer = momentLocalizer(moment);

const CommunityCalendar = () => {
  const [events, setEvents] = useState([
    {
      title: "Initial Event",
      start: new Date(),
      end: new Date(moment().add(1, "hour").toDate()),
    },
  ]);
  const [showModal, setShowModal] = useState(false);
  const [eventTitle, setEventTitle] = useState("");
  const [eventDate, setEventDate] = useState(new Date());

  const handleSelect = ({ start, end }) => {
    setEventDate(start);
    setShowModal(true);
  };

  const handleSaveEvent = () => {
    if (eventTitle) {
      setEvents([
        ...events,
        {
          title: eventTitle,
          start: eventDate,
          end: moment(eventDate).add(1, "hour").toDate(),
        },
      ]);
      setShowModal(false);
    } else {
      alert("Please enter an event name");
    }
  };

  return (
    <Wrapper>
      <MyCommunityHeader />
      <Container>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          selectable
          onSelectSlot={handleSelect}
        />
      </Container>

      <FABButton onClick={() => setShowModal(true)}>
        <FaPlus size={16} />
      </FABButton>

      {showModal && (
        <ModalBackdrop>
          <ModalContent>
            <CloseButton className="close" onClick={() => setShowModal(false)}>
              <IoClose />
            </CloseButton>
            <h3>Create Event</h3>
            <input
              type="text"
              placeholder="Event Title"
              value={eventTitle}
              onChange={(e) => setEventTitle(e.target.value)}
              style={{ padding: "10px", width: "100%" }}
            />
            <DatePicker
              selected={eventDate}
              onChange={(date) => setEventDate(date)}
              dateFormat="MMMM d, yyyy"
              style={{ padding: "10px", marginBottom: "20px", width: "100%" }}
            />
            <button
              onClick={handleSaveEvent}
              style={{ padding: "10px 20px", cursor: "pointer" }}
            >
              Save Event
            </button>
          </ModalContent>
        </ModalBackdrop>
      )}
    </Wrapper>
  );
};

export default CommunityCalendar;
