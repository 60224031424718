import React from "react";
import ACommunityHeader from "../header/header";
import styled from "styled-components";
import { color } from "../../../../themes/themes";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  overflow: hidden;
  min-height: calc(100vh - 1rem);
  background-color: ${color.white};
`;

const AutosellCommunity = () => {
  return (
    <Wrapper>
      <ACommunityHeader />
    </Wrapper>
  );
};

export default AutosellCommunity;
