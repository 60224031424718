import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Facebook from "../../assets/facebook.png";
import Google from "../../assets/google.png";
import {
  Wrapper,
  LoginForm,
  Title,
  Form,
  Input,
  LinkText,
  Button,
  Separator,
  FacebookButton,
  GoogleButton,
  Icon,
} from "./login.styled";

import { useAuth } from "../../context/AuthContext";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!username || !password) {
      setError("Please fill in both fields.");
      return;
    }

    // login api call
    try {
      await login(username, password);
      navigate("/newsfeed");
    } catch (e) {
      setError("Login failed. Please check your credentials.");
      console.log("error:" + e);
    }
  };

  return (
    <Wrapper>
      <LoginForm>
        <Title>Login</Title>

        <Form onSubmit={handleSubmit}>
          <label>
            <Input
              type="text"
              placeholder="Username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              autoComplete="username"
            />
          </label>

          <label>
            <Input
              type="password"
              placeholder="Password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              autoComplete="current-password"
            />
          </label>

          <p>{error}</p>

          <LinkText>
            <span onClick={() => navigate("/reset")}>Forgot Password?</span>
          </LinkText>
          <Button type="submit">Login</Button>
        </Form>

        <LinkText>
          Don't have an account?{" "}
          <span onClick={() => navigate("/signup")}>Signup</span>
        </LinkText>

        <Separator>or</Separator>

        <FacebookButton>
          <Icon>
            <img src={Facebook} alt="Facebook Icon" />
          </Icon>
          Login with Facebook
        </FacebookButton>

        <GoogleButton>
          <Icon>
            <img src={Google} alt="Google Icon" />
          </Icon>
          Login with Google
        </GoogleButton>
      </LoginForm>
    </Wrapper>
  );
};

export default Login;
