import styled from "styled-components";
import { breakpoint, color } from "../../themes/themes";

export const ServicesWrapper = styled.div`
  display: flex;
  gap: 2rem;
  margin: 0 1rem 1rem 0;
  min-height: 100vh;

  ${breakpoint.tablet} {
    margin: 0 1rem 5rem;
  }

  ${breakpoint.mobile} {
    margin: 0 1rem 5rem;
    flex-direction: column-reverse;
  }
`;

export const ListItem = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.4rem;
  width: 70%;
  align-self: flex-start;

  ${breakpoint.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${breakpoint.mobile} {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Item = styled.li`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 10px 10px -5px hsla(0, 0%, 0%, 0.07);
  cursor: pointer;

  &:hover img {
    transform: scale(1.1);
  }
`;

export const ImageThumbnail = styled.div`
  width: 100%;
  height: 25vh;
  overflow: hidden;

  img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.2s ease-in-out;
  }

  ${breakpoint.mobile} {
    height: 35vh;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  gap: 0.5rem;
  height: calc(100% - 25vh);

  ${breakpoint.mobile} {
    height: calc(100% - 35vh);
    padding: 1.5rem;
  }
`;

export const Name = styled.h2`
  margin: 0;
  font-size: 0.85rem;
  font-weight: 500;

  ${breakpoint.mobile} {
    font-size: 1rem;
  }
`;

export const Rating = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;

  p {
    margin: 0;
    font-size: 0.8rem;
  }

  svg {
    display: flex;
    font-size: 0.8rem;
    color: ${color.lightgreen};
  }

  ${breakpoint.mobile} {
    p {
      font-size: 1rem;
    }

    svg {
      font-size: 1rem;
    }
  }
`;

// ------------------ SORTING STYLE ------------------ //

export const SortingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: fixed;
  background-color: #fff;
  right: 1rem;
  width: 18%;
  padding: 1rem;
  height: calc(100% - 6rem);

  ${breakpoint.tablet} {
    width: 25%;
  }

  ${breakpoint.mobile} {
    position: inherit;
    width: 100%;
  }
`;

export const SearchBar = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.3rem;

  &:focus-within {
    border-color: ${color.primary};
  }
`;

export const Input = styled.input`
  flex: 1;
  border: none;
  font-size: 0.9rem;
  outline: none;
`;

export const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 0.5rem;
  color: #888;

  &:hover {
    color: ${color.primary};
  }

  svg {
    font-size: 1.2rem;
  }
`;

export const Select = styled.select`
  padding: 0.5rem;
  border-radius: 0.3rem;
  border: 1px solid #ccc;
  font-size: 0.9rem;
`;

export const PriceRange = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 2px;
  background: #ccc;
  border-radius: 5px;
  outline: none;
  margin-bottom: 1rem;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: ${color.primary};
    border-radius: 50%;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: ${color.primary};
    border-radius: 50%;
    cursor: pointer;
  }
`;

export const PriceValue = styled.p`
  margin: 0;
  font-size: 1rem;
  text-align: center;
`;

export const FilterButton = styled.button`
  background-color: ${color.primary};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  cursor: pointer;
  font-size: 0.9rem;

  &:hover {
    background-color: ${color.lightblue};
  }
`;

export const Note = styled.div`
  margin-top: auto;

  p {
    font-size: 0.7rem;
    color: #ccc;
  }

  ${breakpoint.tablet} {
    margin: auto 0 3rem;
  }
`;
