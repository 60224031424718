import React from "react";
import { Link } from "react-router-dom";
import { navLinksArray } from "./navbar-links";
import { MobileWrapper, MobileLinkItem } from "./navbar.styled";

const MobileNav = () => {
  return (
    <MobileWrapper>
      {navLinksArray.map((link, index) => (
        <Link to={link.path} key={index}>
          <MobileLinkItem>{link.icon}</MobileLinkItem>
        </Link>
      ))}
    </MobileWrapper>
  );
};

export default MobileNav;
