import React, { memo } from "react";
import { FaStar } from "react-icons/fa";
import {
  Rating,
  ListItem,
  Item,
  User,
  Profile,
  Name,
  UserName,
  ActionButton,
} from "./users.styled";

const users = [
  {
    name: "John Doe",
    username: "john_doe",
    profilePic: "",
    rating: 5,
  },
  {
    name: "Jane Smith",
    username: "jane_smith",
    profilePic: "",
    rating: 4,
  },
  {
    name: "John Doe",
    username: "john_doe",
    profilePic: "",
    rating: 5,
  },
  {
    name: "Jane Smith",
    username: "jane_smith",
    profilePic: "",
    rating: 4,
  },
];

const StarRating = memo(({ rating }) => (
  <Rating>
    {[...Array(5)].map((_, i) => (
      <FaStar key={i} style={{ color: i < rating ? "orange" : "lightgray" }} />
    ))}
  </Rating>
));

const UsersList = () => {
  return (
    <ListItem>
      {users.map((user, index) => (
        <Item key={index}>
          <User>
            <Profile>
              <img
                loading="lazy"
                src={user.profilePic || "/default-profile.png"}
                alt="profile"
              />
            </Profile>
            <StarRating rating={user.rating} />
            <Name>{user.name}</Name>
            <UserName>@{user.username}</UserName>
          </User>
          <ActionButton>Action</ActionButton>
        </Item>
      ))}
    </ListItem>
  );
};

export default UsersList;
