export const announcements = [
  {
    title: "Course Update",
    description: "We have added new courses to the catalog.",
    image:
      "https://cdn.dribbble.com/userupload/17559888/file/original-ba6cd122454b32c711ddc19a9302934b.png?resize=1200x900",
  },
  {
    title: "New Integration",
    description: "We have integrated with a new payment provider.",
    image:
      "https://cdn.dribbble.com/userupload/17549138/file/original-37ea611f3f04bb2b6fe1e52266c0374e.png?resize=1200x900",
  },
  {
    title: "New Feature!",
    description: "You can now upload your own course inside autosell.",
    image:
      "https://cdn.dribbble.com/userupload/17561601/file/original-e7fbf8e4152ee9ef4c24fd137db93d8f.jpg?resize=1200x900",
  },
  {
    title: "Bug Fix",
    description: "A bug that prevented course submission has been fixed.",
    image:
      "https://cdn.dribbble.com/userupload/17563301/file/original-d04c0b04ca75435199cec3fd685bda2a.jpg?resize=1200x900",
  },
  {
    title: "New Feature!",
    description: "You can now upload your own course inside autosell.",
    image:
      "https://cdn.dribbble.com/userupload/17559520/file/original-a054c4d460ea06d645da104c7624ecab.png?resize=1200x900",
  },
  {
    title: "Course Update",
    description: "We have added new courses to the catalog.",
    image:
      "https://cdn.dribbble.com/userupload/17563371/file/original-a86858d9b3b90111e02ddb315b9c140f.png?resize=1200x900",
  },
  {
    title: "Bug Fix",
    description: "A bug that prevented course submission has been fixed.",
    image:
      "https://cdn.dribbble.com/userupload/17567197/file/original-a64e4cb9b456cdf1053f4878a9efaa2c.png?resize=1200x900",
  },
  {
    title: "New Integration",
    description: "We have integrated with a new payment provider.",
    image:
      "https://cdn.dribbble.com/userupload/17560807/file/original-dcbb3452da71260828c04590b5bf3491.png?resize=1200x900",
  },
];
