export const challengePostItems = [
  {
    username: "@username1",
    title: "Choco and Me!",
    thumbnail:
      "https://ckcusa.com/media/153246/how-to-bond-with-your-dog-ckc-2.jpg",
  },
  {
    username: "@username6",
    title: "Sunset Play",
    thumbnail:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSQSoMh-ZBNSxpXGlPFpnFlF0Wh362LVku3A&s",
  },
  {
    username: "@username2",
    title: "Day at the Park",
    thumbnail:
      "https://assets.entrepreneur.com/content/3x2/2000/1589538284-dogpuppy.jpg",
  },
  {
    username: "@username3",
    title: "Fun Times",
    thumbnail:
      "https://www.torontohumanesociety.com/wp-content/uploads/2024/07/Atefehs-testimony.png",
  },
  {
    username: "@username4",
    title: "Morning Walk",
    thumbnail:
      "https://oxyfresh.com/cdn/shop/articles/bonding-bad-breath.jpg?v=1645738054",
  },
  {
    username: "@username7",
    title: "Happy Moments",
    thumbnail: "https://wallpapercave.com/wp/wp9515351.jpg",
  },
  {
    username: "@username5",
    title: "Adventures Await",
    thumbnail:
      "https://static1.squarespace.com/static/5a9cd5f47e3c3aa52c57fcb9/5b103e44aa4a999dd0ed1178/5b2f042b6d2a73177a9dda56/1529981939997/shutterstock_386631706_paid+for.jpg?format=1500w",
  },
  {
    username: "@username8",
    title: "Beach Fun",
    thumbnail: "https://i.ytimg.com/vi/tt40EK8kUmo/maxresdefault.jpg",
  },
];
