import styled from "styled-components";
import ProfileImage from "../../../assets/blank.png";
import { breakpoint } from "../../../themes/themes";

export const StoriesWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 0.5rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
`;

export const ScrollContainer = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  flex-grow: 1;

  &::-webkit-scrollbar {
    display: none;
  }

  ${breakpoint.mobile} {
    display: block;
    overflow-x: auto;
  }
`;

export const StoriesList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: inline-flex;
  align-items: flex-start;
  gap: 0.5rem;
`;

export const StoryCard = styled.li`
  padding: 0.6rem;
  border-radius: 0.7rem;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    url(${(props) => props.$bgImage});
  background-size: cover;
  background-position: center;
  width: 7rem;
  height: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
`;

export const AddStoryCard = styled(StoryCard)`
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    url(${ProfileImage});
`;

export const ProfilePicture = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const UserName = styled.p`
  margin: 0;
  font-size: 0.8rem;
  color: white;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: 1rem;
`;

export const ModalContent = styled.div`
  background: white;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  width: auto;
  height: 90vh;

  ${breakpoint.mobile} {
    height: 60vh;
  }
`;

export const LargeImage = styled.img`
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  left: ${({ $left }) => ($left ? "10px" : "auto")};
  right: ${({ $left }) => ($left ? "auto" : "10px")};
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.5);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 1rem;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: rgba(255, 255, 255, 1);
  }

  svg {
    font-size: 1rem;
  }

  ${breakpoint.mobile} {
    display: none;
  }
`;
