import React from "react";
import AppLayout from "../../app/app-components/app-layout/app-layout";
import AppHeader from "../../app/app-components/app-navbar/app-header";
import ChatAutomation from "../../app/chat-system/chat-automation/chat-automation";
import Dashboard from "../../app/app-components/dashboard/dashboard";

const ChatAutomationPage = () => {
  return (
    <>
      <AppHeader />
      <AppLayout
        leftContent={<Dashboard />}
        centerContent={<ChatAutomation />}
      />
    </>
  );
};

export default ChatAutomationPage;
