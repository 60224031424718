import React from "react";
import Container from "../container/container";
import SectionTitle from "../section-title/section-title";
import { FaCheck } from "react-icons/fa6";
import { Wrapper, Content, ListItem, Thumbnail } from "./service-section.styled";
import Default from "../../assets/no-image.jpg";

const ServiceSection = ({ title, services, imageSrc }) => {
  const defaultImg = Default;

  return (
    <Wrapper>
      <Container>
        <SectionTitle title={title} />
        <Content>
          <Thumbnail>
            <img src={imageSrc || defaultImg} alt="thumbnail" />
          </Thumbnail>
          <ListItem>
            {services.map((service, index) => (
              <li key={index}>
                <FaCheck className="check-icon" />
                {service}
              </li>
            ))}
          </ListItem>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default ServiceSection;
