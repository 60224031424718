import styled from "styled-components";
import { color } from "../../themes/themes";

export const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom right, #e0b3ff, #7f00ff);
  height: 100vh;
  padding: 2rem;

  * {
    margin: 0;
    font-size: 0.9rem;
  }
`;

export const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${color.white};
  border-radius: 1rem;
  padding: 2rem;
  width: 100%;
  max-width: 400px;
`;

export const Title = styled.h1`
  margin-bottom: 1.6rem;
  color: ${color.primary};
  width: 100%;
  text-align: center;
  font-size: 1.4rem;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.6rem;
`;

export const Input = styled.input`
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  width: 100%;

  &:focus {
    outline: none;
    border-color: ${color.primary};
  }
`;

export const Button = styled.button`
  position: relative;
  padding: 0.75rem;
  margin: 0.5rem 0;
  border: none;
  border-radius: 0.5rem;
  background-color: ${color.primary};
  color: ${color.white};
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${color.lightblue};
  }
`;

export const FacebookButton = styled(Button)`
  background-color: #4267b2;
  color: white;

  &:hover {
    background-color: #365899;
  }
`;

export const GoogleButton = styled(Button)`
  background-color: transparent;
  border: 1px solid #ddd;
  color: black;

  &:hover {
    border: 1px solid ${color.link};
    background-color: inherit;
  }
`;

export const LinkText = styled.p`
  margin: 0.5rem 0;
  text-align: center;

  span {
    cursor: pointer;
    color: ${color.lightblue};
  }
`;

export const Separator = styled.p`
  text-align: center;
  color: ${color.gray};
  margin: 0.3rem 0 0.8rem;
`;

export const Icon = styled.span`
  position: absolute;
  left: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.1rem;

  img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const PasswordRequirements = styled.ul`
  margin: 0.4rem 0 1rem;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  li {
    color: #aaa;
    font-size: 0.8rem;
  }
`;
