export const digitalProductsData = [
  {
    category: "Video Software",
    name: "Professional Video Editing Toolkit",
    rating: 4.7,
    price: 500,
    image:
      "https://cdn.dribbble.com/userupload/17443021/file/original-ad0aa75f03b76e3e859c2144fbd2dcbd.jpg?resize=1200x902",
  },
  {
    category: "Video Software",
    name: "HD Video Filters and Effects",
    rating: 4.8,
    price: 250,
    image:
      "https://cdn.dribbble.com/userupload/17432015/file/original-37454186bb358b3966b86efabaf344e5.png?resize=1200x900",
  },
  {
    category: "Image Editors",
    name: "Photo Enhancement Suite",
    rating: 4.9,
    price: 150,
    image:
      "https://cdn.dribbble.com/userupload/17435470/file/original-a6c0e258d51fc2c739dd6ed8d764df84.png?resize=1200x900",
  },
  {
    category: "Image Editors",
    name: "Creative Photo Adjustments",
    rating: 4.8,
    price: 300,
    image:
      "https://cdn.dribbble.com/userupload/17401960/file/original-70a958e1464772d60352211a932a0e56.png?resize=1200x900",
  },
  {
    category: "WooCommerce",
    name: "Custom WooCommerce Extension",
    rating: 4.6,
    price: 400,
    image:
      "https://cdn.dribbble.com/userupload/17441343/file/original-824def3054ce611fdea1b193fce10f40.png?resize=1200x894",
  },
  {
    category: "WooCommerce",
    name: "Complete WooCommerce Starter Pack",
    rating: 4.7,
    price: 600,
    image:
      "https://cdn.dribbble.com/userupload/17436381/file/original-247f7d290322cd0318611196014d6cc2.jpeg?resize=1200x900",
  },
  {
    category: "Video Software",
    name: "Professional Video Editing Toolkit",
    rating: 4.7,
    price: 500,
    image:
      "https://cdn.dribbble.com/userupload/17443021/file/original-ad0aa75f03b76e3e859c2144fbd2dcbd.jpg?resize=1200x902",
  },
  {
    category: "Video Software",
    name: "HD Video Filters and Effects",
    rating: 4.8,
    price: 250,
    image:
      "https://cdn.dribbble.com/userupload/17432015/file/original-37454186bb358b3966b86efabaf344e5.png?resize=1200x900",
  },
  {
    category: "Image Editors",
    name: "Photo Enhancement Suite",
    rating: 4.9,
    price: 150,
    image:
      "https://cdn.dribbble.com/userupload/17435470/file/original-a6c0e258d51fc2c739dd6ed8d764df84.png?resize=1200x900",
  },
  {
    category: "Image Editors",
    name: "Creative Photo Adjustments",
    rating: 4.8,
    price: 300,
    image:
      "https://cdn.dribbble.com/userupload/17401960/file/original-70a958e1464772d60352211a932a0e56.png?resize=1200x900",
  },
  {
    category: "WooCommerce",
    name: "Custom WooCommerce Extension",
    rating: 4.6,
    price: 400,
    image:
      "https://cdn.dribbble.com/userupload/17441343/file/original-824def3054ce611fdea1b193fce10f40.png?resize=1200x894",
  },
  {
    category: "WooCommerce",
    name: "Complete WooCommerce Starter Pack",
    rating: 4.7,
    price: 600,
    image:
      "https://cdn.dribbble.com/userupload/17436381/file/original-247f7d290322cd0318611196014d6cc2.jpeg?resize=1200x900",
  },
];
