import React from "react";
import Image from "../../../assets/section1.png";
import Container from "../../../components/container/container";
import SectionTitle from "../../../components/section-title/section-title";
import { FaCheck } from "react-icons/fa6";
import {
  Wrapper,
  Content,
  Thumbnail,
  RightSide,
  Title,
  List,
} from "./section1.styled";

const listItems = [
  "Train your bot to talk like a human",
  "Customize based on your personality",
  "Adjust tone and goals",
  "Use different languages",
  "Sell your products 24/7",
  "Close deals with clients professionally",
  "Integrate with your Facebook, Instagram, and website",
];

const Section1 = () => {
  return (
    <Wrapper>
      <Container>
        <SectionTitle title="Get All Features" />

        <Content>
          <Thumbnail>
            <img src={Image} alt="thumbnail" />
          </Thumbnail>

          <RightSide>
            <Title>First-ever human-like chatbot</Title>
            <List>
              {listItems.map((item, index) => (
                <li key={index}>
                  <FaCheck className="check-icon" />
                  {item}
                </li>
              ))}
            </List>
          </RightSide>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Section1;
