import styled from "styled-components";
import { breakpoint, color } from "../../../themes/themes";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  color: ${color.black};

  ${breakpoint.mobile} {
    gap: 0.5rem;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;

  h1 {
    margin: 0;
  }

  svg {
    display: flex;
    font-size: 1.2rem;
    cursor: pointer;
  }
`;

export const ListItem = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    &::-webkit-scrollbar {
      display: block;
      width: 0.6rem;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: #eee;
  }
`;

export const Item = styled.li`
  padding: 0.6rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
  border-radius: 1rem;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const Avatar = styled.div`
  position: relative;
  width: 3.5rem;
  height: 3.5rem;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
`;

export const IconOverlay = styled.div`
  position: absolute;
  bottom: -0.2rem;
  right: -0.2rem;
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: white;
  background: ${({ $gradient }) => $gradient};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    font-size: 0.9rem;
  }

  .time {
    color: blue;
  }
`;
