import { IoSend } from "react-icons/io5";
import React, { useState } from "react";
import { GrClose } from "react-icons/gr";
import {
  PopupContainer,
  PopupHeader,
  PopupBody,
  ChatMessage,
  Avatar,
  PopupFooter,
  MessageInput,
  SendButton,
} from "./chat-popup.styled";

const ChatPopup = ({ chat, onClose }) => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([
    { user: chat.name, text: chat.message, isUserMessage: false },
  ]);

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = () => {
    if (message.trim()) {
      const newMessage = {
        user: chat.name,
        text: message,
        isUserMessage: true,
      };
      setMessages([...messages, newMessage]);
      setMessage("");
    }
  };

  return (
    <PopupContainer>
      <PopupHeader>
        <h4>{chat.name}</h4>
        <GrClose onClick={onClose} />
      </PopupHeader>
      <PopupBody>
        {messages.map((msg, index) => (
          <ChatMessage key={index} $isUserMessage={msg.isUserMessage}>
            {!msg.isUserMessage && (
              <Avatar>
                <img src={chat.image} alt="profile" />
              </Avatar>
            )}
            <p>{msg.text}</p>
          </ChatMessage>
        ))}
      </PopupBody>
      <PopupFooter>
        <MessageInput
          placeholder="Type a message..."
          value={message}
          onChange={handleInputChange}
        />
        <SendButton onClick={handleSendMessage}>
          <IoSend />
        </SendButton>
      </PopupFooter>
    </PopupContainer>
  );
};

export default ChatPopup;
