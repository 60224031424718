import styled from "styled-components";

export const AdsSection = styled.div`
  margin-top: 1rem;
`;

export const AdsList = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 1rem;
`;

export const AdItem = styled.li`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
`;

export const AdThumbnail = styled.div`
  width: 6.5rem;
  height: 6rem;
  border-radius: 0.5rem;
  overflow: hidden;

  img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const AdTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  h4,
  p {
    margin: 0;
  }

  h4 {
    font-size: 0.9rem;
  }

  p {
    font-size: 0.8rem;
    color: #aaa;
  }
`;
