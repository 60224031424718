import { FaAngleUp, FaAngleDown } from "react-icons/fa6";
import React, { useState } from "react";
import Container from "../../components/container/container";
import {
  Wrapper,
  Content,
  LeftSide,
  RightSide,
  Item,
  Question,
  Answer,
} from "./FAQs.styled";

const FAQs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "What is Autosell.io?",
      answer: "Autosell.io is a platform for...",
    },
    {
      question: "Can I conduct paid sessions?",
      answer: "Yes, you can conduct paid sessions...",
    },
    {
      question: "How much can I charge for my sessions?",
      answer: "You can charge based on...",
    },
    {
      question: "How do the payments work?",
      answer: "Payments are processed through...",
    },
    {
      question: "Wait, is Autosell.io really free?",
      answer: "Yes, Autosell.io offers a free tier...",
    },
    {
      question: "What are the transaction charges?",
      answer: "Transaction charges apply as...",
    },
  ];

  const toggleAccordion = (index) =>
    setOpenIndex(openIndex === index ? null : index);

  return (
    <Wrapper>
      <Container>
        <Content>
          <LeftSide>
            <h1>
              Frequently Asked <span>Questions</span>
            </h1>
            <p>Can't find the answer you are looking for?</p>
            <p className="boldText">Reach out to us</p>
          </LeftSide>

          <RightSide>
            {faqs.map(({ question, answer }, index) => (
              <Item key={index}>
                <Question onClick={() => toggleAccordion(index)}>
                  {question}
                  {openIndex === index ? <FaAngleUp /> : <FaAngleDown />}
                </Question>

                <Answer className={openIndex === index ? "active" : ""}>
                  {answer}
                </Answer>
              </Item>
            ))}
          </RightSide>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default FAQs;
