import React from "react";
import { ListItem, Item, Title, Thumbnail } from "./announcement-list.styled";
import { announcements } from "./announcement-data";

const AnnouncementList = () => {
  return (
    <ListItem>
      {announcements.map((announcement, index) => (
        <Item key={index}>
          <Title>
            <h1>{announcement.title}</h1>
            <p>{announcement.description}</p>
          </Title>
          <Thumbnail>
            <img src={announcement.image} alt="thumbnail" />
          </Thumbnail>
        </Item>
      ))}
    </ListItem>
  );
};

export default AnnouncementList;
