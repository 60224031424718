import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RiNotification2Fill } from "react-icons/ri";
import { FaDollarSign, FaFacebookMessenger } from "react-icons/fa";
import { HeaderWrapper, Logo, RightSide, Icon, Profile } from "./navbar.styled";
import { useAuth } from "../../../context/AuthContext";

// Subcomponents
import DesktopNav from "./desktop-navbar";
import Notification from "../notification/notification";
import ModalInbox from "../../chat-system/popup-panels/modal-inbox/modal-inbox";
import ProfileModal from "../profile-modal/profile-modal";

const AppHeader = () => {
  const navigate = useNavigate();

  const [activeIcon, setActiveIcon] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const { user } = useAuth();

  // Handle resizing for mobile view
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const goToHome = () => {
    navigate("/newsfeed");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const toggleIcon = (icon) => {
    setActiveIcon(activeIcon === icon ? null : icon);
  };

  return (
    <HeaderWrapper>
      <Logo onClick={goToHome}>Autosell.io</Logo>
      <DesktopNav />

      <RightSide>
        <Icon>
          <FaDollarSign />
        </Icon>

        {isMobile ? (
          <>
            <Icon onClick={() => navigate("/mobile-inbox")}>
              <FaFacebookMessenger />
            </Icon>
            <Icon onClick={() => navigate("/notifications")}>
              <RiNotification2Fill />
            </Icon>
          </>
        ) : (
          <>
            <Icon onClick={() => toggleIcon("messenger")}>
              <FaFacebookMessenger />
            </Icon>
            <Icon onClick={() => toggleIcon("notification")}>
              <RiNotification2Fill />
            </Icon>
          </>
        )}

        <Profile onClick={() => toggleIcon("profile")}>
          <img
            src={user?.profile?.profilePictureUrl || "/default-profile.png"}
            alt="profile"
          />
        </Profile>
      </RightSide>

      {/* Modals for active icons */}
      {activeIcon === "messenger" && <ModalInbox />}
      {activeIcon === "notification" && <Notification />}
      {activeIcon === "profile" && <ProfileModal />}
    </HeaderWrapper>
  );
};

export default AppHeader;
