import styled from "styled-components";
import { breakpoint, color } from "../../themes/themes";

export const Wrapper = styled.section`
  padding: 5rem 0;
  background-color: ${color.darkBg};
`;

export const ListItem = styled.ul`
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.8rem;
  margin-top: 4rem;

  ${breakpoint.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${breakpoint.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Item = styled.li`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 1.2rem;
  background-color: ${color.white};
  gap: 1.9rem;
  cursor: pointer;
  padding: 2rem;

  svg {
    font-size: 2.6rem;
    color: ${color.lightblue};
  }

  p {
    margin: 0;
    line-height: 1.4rem;
  }
`;

export const User = styled.div`
  display: flex;
  gap: 0.7rem;
  align-items: center;
`;

export const Profile = styled.div`
  width: 3.4rem;
  height: 3.4rem;
  border-radius: 50%;
  overflow: hidden;
  aspect-ratio: 1 / 1;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Name = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font-weight: 600;
    margin: 0 0 1px;
  }

  p {
    font-size: 0.8rem;
    color: ${color.gray};
    line-height: 1.1rem;
    margin: 0;
  }
`;
