import MobileChat from "../../app/chat-system/mobile-version/mobile-chat/mobile-chat";

const MobileChatPage = () => {
  return (
    <>
      <MobileChat />
    </>
  );
};

export default MobileChatPage;
