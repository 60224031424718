import React from "react";
import { Link } from "react-router-dom";
import {
  TiHome,
  TiMessages,
  TiUser,
  TiChartBar,
  TiVideo,
  TiClipboard,
} from "react-icons/ti";
import { Wrapper, ListItem, Item, Icon, Title } from "./dashboard.styled";

const Dashboard = () => {
  const items = [
    { icon: <TiHome />, title: "Dashboard", path: "" },
    {
      icon: <TiMessages />,
      title: "Testimonials",
      path: "/testimonials",
    },
    {
      icon: <TiUser />,
      title: "Community",
      path: "/community/chat-anonymously",
    },
    { icon: <TiUser />, title: "Profile Builder", path: "/under-construction" },
    { icon: <TiVideo />, title: "Webinars", path: "/under-construction" },
    { icon: <TiMessages />, title: "Chat", path: "/under-construction" },
    { icon: <TiClipboard />, title: "Course", path: "/under-construction" },
    {
      icon: <TiClipboard />,
      title: "Payment Links",
      path: "/under-construction",
    },
    {
      icon: <TiChartBar />,
      title: "Refer & Earn",
      path: "/under-construction",
    },
    {
      icon: <TiMessages />,
      title: "Ask Autosell",
      path: "/under-construction",
    },
  ];

  return (
    <Wrapper>
      <ListItem>
        {items.map((item, index) => (
          <Link to={item.path} key={index}>
            <Item>
              <Icon>{item.icon}</Icon>
              <Title>
                <p>{item.title}</p>
              </Title>
            </Item>
          </Link>
        ))}
      </ListItem>
    </Wrapper>
  );
};

export default Dashboard;
