import styled, { keyframes } from "styled-components";
import { breakpoint, color } from "../../themes/themes";

const slide = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

export const Wrapper = styled.section`
  padding: 5rem 0;
  background-image: linear-gradient(to top, #fff1eb 0%, #eff9fe 100%);
  overflow: hidden;
`;

export const ListItem = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: max-content;
  padding: 0;
  margin: 0;
  animation: ${slide} 100s linear infinite;
`;

export const Track = styled.div`
  display: flex;
  width: 200%;
`;

export const Item = styled.li`
  list-style-type: none;
  width: max-content;
  margin-right: 4rem;

  h1 {
    font-size: 2.8rem;
    white-space: nowrap;
    margin: 0;
    color: ${color.primary};
  }

  ${breakpoint.mobile} {
    margin-right: 3rem;

    h1 {
      font-size: 2rem;
    }
  }
`;
