import React from "react";
import CommunityLayout from "../../app/app-components/app-layout/community-layout";
import AutosellCommunity from "../../app/community/autosell-community/autosell-community/autosell-community";
import Dashboard from "../../app/app-components/dashboard/dashboard";

const AutosellCommunityPage = () => {
  return (
    <>
      <CommunityLayout
        leftContent={<Dashboard />}
        rightContent={<AutosellCommunity />}
      />
    </>
  );
};

export default AutosellCommunityPage;
