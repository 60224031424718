import styled from "styled-components";
import { color } from "../../../../themes/themes";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1111;
`;

export const ModalContainer = styled.div`
  background: white;
  width: 90%;
  max-width: 32rem;
  padding: 3rem 2rem 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;

  h2 {
    font-size: 1.5rem;
    margin: 0 0 1rem 0;
    color: ${color.primary};
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0.7rem;
  right: 0.5rem;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

export const ModalContent = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  list-style-type: none;
`;

export const ShareOption = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  border-radius: 0.5rem;
  gap: 1rem;

  &:hover {
    background-color: #f5f5f5;
  }
`;
