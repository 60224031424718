import React from "react";
import { MainTitle, LastWord } from "./section-title.styled";

const SectionTitle = ({ title, theme = "light" }) => {
  const words = title.split(" ");
  const lastWord = words.pop();

  return (
    <MainTitle theme={theme}>
      {words.join(" ")} <LastWord>{lastWord}</LastWord>
    </MainTitle>
  );
};

export default SectionTitle;
