import React from "react";
import CommunityHeader from "../../../app-components/community-header/community-header";

// Chat Anonymously Header
const ChatAHeader = () => {
  const communityItems = [
    { name: "Chat Anonymously", path: "/community/chat-anonymously" },
    { name: "Autosell Community", path: "/autosell-community" },
    { name: "My Community", path: "/my-community/announcements" },
  ];

  return (
    <div>
      <CommunityHeader items={communityItems} />
    </div>
  );
};

export default ChatAHeader;
