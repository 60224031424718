import styled from "styled-components";
import { breakpoint, color } from "../../../../themes/themes";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  overflow: hidden;
  min-height: calc(100vh - 1rem);
  background-color: ${color.white};
`;

export const Container = styled.div`
  padding: 1rem 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .rbc-calendar {
    height: 100%;
    flex-grow: 1;
    border-radius: 0.5rem;
  }

  .rbc-event {
    background-color: ${color.transparent};
    color: ${color.primary};
    font-weight: 600;
    border-radius: 0;
    cursor: pointer;
    padding: 0.2rem 1rem;
  }

  .rbc-toolbar {
    background-color: ${color.white};
    padding: 1rem;
    gap: 1rem;
  }

  .rbc-toolbar button {
    background-color: ${color.primary};
    color: ${color.white};
    border: none;
    border-radius: 4px;
    margin: 0 2px;
    padding: 5px 10px;
    cursor: pointer;

    &:hover {
      background-color: ${color.lightblue};
      color: ${color.white};
    }
  }

  .rbc-header {
    background-color: ${color.white};
    color: ${color.darkBg};
    text-transform: uppercase;
    padding: 1rem;
  }

  .rbc-today {
    background-color: ${color.lightviolet};
  }

  .rbc-day-bg {
    &:hover {
      background-color: ${color.lightviolet};
    }
  }
`;

export const FABButton = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  padding: 1rem;
  background-color: ${color.primary};
  color: ${color.white};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  &:hover {
    background-color: ${color.lightblue};
  }
`;

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background-color: ${color.white};
  padding: 1rem;
  border-radius: 0.8rem;
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h3 {
    margin: 0;
  }

  .close {
    padding: 0;
    background-color: inherit;
    color: inherit;
  }

  button {
    border: none;
    background-color: ${color.primary};
    color: ${color.white};
    border-radius: 0.5rem;
  }

  ${breakpoint.tablet} {
    width: 60%;
  }

  ${breakpoint.mobile} {
    width: 100%;
    margin: 0 1rem;
  }
`;

export const CloseButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  color: ${color.primary};
  font-size: 1.2rem;
  margin-left: auto;

  svg {
    display: flex;
    font-size: 1.5rem;
  }
`;
