import React from "react";
import CommunityLayout from "../../app/app-components/app-layout/community-layout";
import Announcements from "../../app/community/my-community/announcements/main/announcements";
import Dashboard from "../../app/app-components/dashboard/dashboard";

const AnnouncementsPage = () => {
  return (
    <>
      <CommunityLayout
        leftContent={<Dashboard />}
        rightContent={<Announcements />}
      />
    </>
  );
};

export default AnnouncementsPage;
