import React, { useState, useRef, useEffect } from "react";
import {
  StoriesWrapper,
  StoriesList,
  StoryCard,
  AddStoryCard,
  ProfilePicture,
  UserName,
  ModalOverlay,
  ModalContent,
  LargeImage,
  ArrowButton,
  ScrollContainer,
} from "./stories.styled";
import Image1 from "../../../assets/Story1.png";
import Image2 from "../../../assets/Story2.png";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const Stories = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const scrollContainerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const storiesData = [
    { userName: "Leilanie Toloza", profilePic: "", bgImage: Image1 },
    { userName: "John Doe", profilePic: "", bgImage: Image1 },
    { userName: "Leilanie Toloza", profilePic: Image1, bgImage: Image2 },
    { userName: "John Doe", profilePic: Image1, bgImage: Image2 },
    { userName: "Jane Smith", profilePic: "", bgImage: Image1 },
    { userName: "Mark Johnson", profilePic: "", bgImage: Image1 },
    { userName: "Emily Davis", profilePic: "", bgImage: Image2 },
    { userName: "Jane Smith", profilePic: "", bgImage: Image1 },
    { userName: "Mark Johnson", profilePic: "", bgImage: Image1 },
    { userName: "Emily Davis", profilePic: "", bgImage: Image2 },
  ];

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage("");
  };

  const handleNext = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 100, behavior: "smooth" });
    }
  };

  const handlePrev = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -100, behavior: "smooth" });
    }
  };

  const handleAddStory = () => {
    alert("Add story functionality goes here!");
  };

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerRef.current;

      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);

      handleScroll();
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <StoriesWrapper>
      {canScrollLeft && (
        <ArrowButton $left onClick={handlePrev}>
          <FaChevronLeft />
        </ArrowButton>
      )}

      <ScrollContainer ref={scrollContainerRef}>
        <StoriesList>
          <AddStoryCard onClick={handleAddStory}>
            <ProfilePicture>
              <img src="/default-profile.png" alt="Profile" />
            </ProfilePicture>
            <UserName>Add Story</UserName>
          </AddStoryCard>

          {storiesData.map((story, index) => (
            <StoryCard
              key={index}
              $bgImage={story.bgImage}
              onClick={() => openModal(story.bgImage)}
            >
              <ProfilePicture>
                <img
                  src={story.profilePic || "/default-profile.png"}
                  alt="Profile"
                />
              </ProfilePicture>
              <UserName>{story.userName}</UserName>
            </StoryCard>
          ))}
        </StoriesList>
      </ScrollContainer>

      {canScrollRight && (
        <ArrowButton onClick={handleNext}>
          <FaChevronRight />
        </ArrowButton>
      )}

      {isModalOpen && (
        <ModalOverlay onClick={closeModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <LargeImage src={selectedImage} alt="Story" />
          </ModalContent>
        </ModalOverlay>
      )}
    </StoriesWrapper>
  );
};

export default Stories;
