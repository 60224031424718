import React from "react";
import {
  ModalOverlay,
  ModalContainer,
  CloseButton,
  ModalContent,
  ShareOption,
} from "./share-modal.styled";
import { IoClose } from "react-icons/io5";
import { FaShareSquare, FaPaperPlane, FaLink } from "react-icons/fa";

const ShareModal = ({ onClose }) => {
  const shareOptions = [
    { label: "Share to Feed", icon: <FaShareSquare /> },
    { label: "Share post via..", icon: <FaPaperPlane /> },
    { label: "Share via Direct Message", icon: <FaPaperPlane /> },
    { label: "Copy Link", icon: <FaLink /> },
  ];

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose} aria-label="Close modal">
          <IoClose />
        </CloseButton>
        <h2>Share Post</h2>
        <ModalContent>
          {shareOptions.map((option) => (
            <ShareOption key={option.label}>
              {option.icon}
              {option.label}
            </ShareOption>
          ))}
        </ModalContent>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default ShareModal;
