import React from "react";
import { useNavigate } from "react-router-dom";
import Container from "../../../components/container/container";
import { FaCircleCheck } from "react-icons/fa6";
import {
  Section,
  PlanList,
  Plan,
  PlanTitle,
  PlanPrice,
  SubscribeButton,
  FeatureList,
  Feature,
} from "./pricing.styled";

const subscriptionPlans = [
  {
    name: "Free",
    price: "$0",
    period: "/ Month",
    features: [
      "Access Basic Course Content",
      "Limited Workshop Access",
      "Accept Credit Card Payments",
      "1 Live Course per Month",
      "Up to 50 Members",
      "Community Support",
    ],
    buttonLabel: "Sign Up for Free",
    buttonPath: "/free-signup",
    highlight: false,
  },
  {
    name: "Pro",
    price: "$75",
    period: "/ Month",
    features: [
      "Access All Course Content",
      "Unlimited Workshop Access",
      "Accept All Payment Methods",
      "Unlimited Live Courses",
      "Up to 500 Members",
      "Zoom Integration",
      "Advanced Analytics & Insights",
      "Email and Chat Support",
      "Customizable Course Pages",
    ],
    buttonLabel: "Get Started Now",
    buttonPath: "/payment",
    highlight: true,
  },
  {
    name: "Enterprise",
    price: "$99",
    period: "/ Month",
    features: [
      "Access All Course Content",
      "Unlimited Workshop Access",
      "Priority Payment Processing",
      "Unlimited Live Courses",
      "Unlimited Members",
      "Dedicated Zoom Integration",
      "Advanced Analytics & Customized Insights",
      "Priority Email and Phone Support",
      "Customizable Course Pages and Branding",
      "Dedicated Account Manager",
      "API Access for Integrations",
      "24/7 Technical Support",
    ],
    buttonLabel: "Get Started Now",
    buttonPath: "/payment",
    highlight: true,
  },
];

const Pricing = () => {
  const navigate = useNavigate();

  return (
    <Section>
      <Container>
        <PlanList>
          {subscriptionPlans.map((plan, index) => (
            <Plan key={index} isHighlighted={plan.highlight}>
              <PlanTitle>{plan.name}</PlanTitle>
              <PlanPrice>
                <h1>{plan.price}</h1>
                <span>{plan.period}</span>
              </PlanPrice>
              <SubscribeButton
                aria-label={`Subscribe to the ${plan.name} plan`}
                onClick={() => navigate(plan.buttonPath)}
              >
                {plan.buttonLabel}
              </SubscribeButton>
              <FeatureList>
                {plan.features.map((feature, idx) => (
                  <Feature key={idx}>
                    <FaCircleCheck />
                    <p>{feature}</p>
                  </Feature>
                ))}
              </FeatureList>
            </Plan>
          ))}
        </PlanList>
      </Container>
    </Section>
  );
};

export default Pricing;
