import AppHeader from "../../app/app-components/app-navbar/app-header";
import MobileNav from "../../app/app-components/app-navbar/mobile-navbar";
import Dashboard from "../../app/app-components/dashboard/dashboard";

const DashboardPage = () => {
  return (
    <>
      <AppHeader />
      <Dashboard />
      <MobileNav />
    </>
  );
};

export default DashboardPage;
