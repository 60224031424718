import React from "react";
import { RiRobot2Fill } from "react-icons/ri";
import { LoadingScreen, Wrapper, LoadingText, Quotes } from "./loading.styled";

const quotes = [
  "Technology is best when it brings people together.",
  "The great thing about technology is that it allows us to connect and collaborate across boundaries.",
  "The future belongs to those who understand technology and use it to make the world a better place.",
  "Chatbots are not just about technology; they are about enhancing human experiences.",
  "Embrace technology as a tool to empower your voice and reach new heights.",
];

const Loading = () => {
  const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];

  return (
    <LoadingScreen>
      <Wrapper>
        <RiRobot2Fill />
        <LoadingText>Autosell.io</LoadingText>
      </Wrapper>
      <Quotes>
        <p>{randomQuote}</p>
      </Quotes>
    </LoadingScreen>
  );
};

export default Loading;
