import styled from "styled-components";
import { RiAttachment2 } from "react-icons/ri";
import { color } from "../../../themes/themes";

export const Wrapper = styled.form`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background-color: ${color.white};
  border-radius: 0.5rem;
  margin-right: 1rem;
  gap: 1.5rem;
  height: calc(100vh - 6rem);
`;

export const CategoryInput = styled.input`
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #ddd;
  font-size: 0.9rem;
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: ${color.primary};
  }
`;

export const Tags = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

export const TagButton = styled.button`
  background-color: ${color.lightviolet};
  border: none;
  padding: 0.5rem 1.25rem;
  cursor: pointer;
  border-radius: 0.5rem;
  font-size: 0.9rem;

  &:hover {
    background-color: ${color.lightblue};
    color: ${color.white};
  }

  &.selected {
    background-color: ${color.primary};
    color: ${color.white};
  }
`;

export const CostInput = styled.input`
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #ddd;
  font-size: 0.9rem;
  outline: none;
  transition: border-color 0.3s;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    margin: 0;
    height: 2rem;
  }

  &:focus {
    border-color: ${color.primary};
  }
`;

export const Datestamp = styled.div`
  display: flex;
  gap: 1rem;
`;

export const DurationButton = styled(TagButton)`
  padding: 0.5rem 1rem;
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const TextInput = styled.input`
  width: 100%;
  padding: 1rem;
  padding-right: 3rem;
  border-radius: 0.5rem;
  border: 1px solid #ddd;
  font-size: 0.9rem;
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: ${color.primary};
  }
`;

export const AttachmentIcon = styled(RiAttachment2)`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  color: ${color.primary};
  cursor: pointer;
`;

export const UploadButton = styled.button`
  background-color: ${color.primary};
  color: ${color.white};
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${color.lightblue};
  }
`;
