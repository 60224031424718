import React from "react";
import MyCommunityHeader from "../../header/header";
import Carousel from "../carousel/carousel";
import AnnouncementList from "../announcement-list/announcement-list";
import styled from "styled-components";
import { color } from "../../../../../themes/themes";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  overflow: hidden;
  min-height: calc(100vh - 1rem);
  background-color: ${color.white};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

const Announcements = () => {
  return (
    <Wrapper>
      <MyCommunityHeader />
      <Content>
        <Carousel />
        <AnnouncementList />
      </Content>
    </Wrapper>
  );
};

export default Announcements;
