import React from "react";
import Container from "../../components/container/container";
import { Wrapper, Track, ListItem, Item } from "./presentation.styled";
import Video from "../video/video";

const categories = [
  "Freelancers",
  "Digital Product Sellers",
  "Business Owners",
  "Influencers",
  "UGC",
  "Coaches",
  "Consultants",
  "Service Providers",
  "Community Builder",
  "Agency Owners",
  "Employers",
];

const Presentation = () => {
  const repeatedData = [...categories, ...categories];

  return (
    <Wrapper>
      <Container>
        <Track>
          <ListItem>
            {repeatedData.map((category, index) => (
              <Item key={index}>
                <h1>{category}</h1>
              </Item>
            ))}
          </ListItem>
          <ListItem>
            {repeatedData.map((category, index) => (
              <Item key={`repeat-${index}`}>
                <h1>{category}</h1>
              </Item>
            ))}
          </ListItem>
        </Track>
        <Video />
      </Container>
    </Wrapper>
  );
};

export default Presentation;
