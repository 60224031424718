import styled from "styled-components";
import { breakpoint, color } from "../../themes/themes";

export const Wrapper = styled.section`
  padding: 6rem 0;
  background-image: linear-gradient(
    to top,
    #f3e7e9 0%,
    #e3eeff 99%,
    #e3eeff 100%
  );
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  align-items: center;
  margin-top: 3rem;

  ${breakpoint.tablet} {
    grid-template-columns: repeat(1, 1fr);
  }

  ${breakpoint.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Caption = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  p {
    font-size: 1.1rem;
    line-height: 1.8rem;
  }

  h1 {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-size: 2rem;
    line-height: 2.4rem;

    ${breakpoint.mobile} {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }
`;

export const Button = styled.button`
  align-self: flex-start;
  padding: 1rem 2rem;
  border: none;
  font-size: 0.9rem;
  border-radius: 50px;
  background-color: ${color.primary};
  color: ${color.white};
  cursor: pointer;
`;

export const Thumbnail = styled.div`
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
