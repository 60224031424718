const breakpoint = {
  mobile: "@media only screen and (max-width: 768px)",
  tablet: "@media only screen and (min-width: 769px) and (max-width: 1279px)",
  desktop: "@media only screen and (min-width: 1280px)",
  navbar: "@media only screen and (max-width: 1020px)",
};

const color = {
  primary: "#7f00ff",
  transparent: "rgba(0,0,0,0)",
  white: "#ffffff",
  black: "#000000",
  darkBg: "#26004d",
  lightviolet: "#F7F0FF",
  lightblue: "#6d7dff",
  teal: "#50f3b5",
  lightgreen: "#44eb6b",
  link: "#2a9df4",
  gray: "#808080",
};

export { breakpoint, color };
