const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const newsFeedApi = {
  post: async (token, content, privacyLevel, files) => {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("mediaAttachments", file);
    });

    formData.append("content", content);
    formData.append("privacyLevel", privacyLevel);

    try {
      const response = await fetch(`${BASE_URL}/post/create`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        return await response.json();
      } else {
        throw Error("Failed to create post");
      }
    } catch (error) {
      throw Error(error);
    }
  },
  getNewsFeed: async () => {
    const token = JSON.parse(localStorage.getItem("user")).access_token;

    try {
      const response = await fetch(`${BASE_URL}/post/news-feed`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        // console.log("News Feed:", data);
        return data;
      } else {
        console.error("Failed to fetch news feed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  },
  likePost: async (postId) => {
    const token = JSON.parse(localStorage.getItem("user")).access_token;

    try {
      const response = await fetch(`${BASE_URL}/post/like`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ reactionType: "LIKE", postId: postId }),
      });

      if (response.ok) {
        return await response.json();
      } else {
        throw Error("Failed to create post");
      }
    } catch (error) {
      throw Error(error);
    }
  },
  unlikePost: async (postId) => {
    const token = JSON.parse(localStorage.getItem("user")).access_token;

    try {
      const response = await fetch(`${BASE_URL}/post/unlike`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ postId: postId }),
      });

      if (response.ok) {
        return await response.json();
      } else {
        throw Error("Failed to create post");
      }
    } catch (error) {
      throw Error(error);
    }
  },
  commentPost: async (postId, content) => {
    const token = JSON.parse(localStorage.getItem("user")).access_token;

    try {
      const response = await fetch(`${BASE_URL}/post/comment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ postId, content }),
      });

      if (response.ok) {
        return await response.json();
      } else {
        throw Error("Failed to create post");
      }
    } catch (error) {
      throw Error(error);
    }
  },
};

export default newsFeedApi;
