import React, { useState } from "react";
import ProfileImg from "../../../assets/Story1.png";
import { IoClose } from "react-icons/io5";
import {
  ModalBackground,
  ModalContainer,
  CloseButton,
  CommentList,
  CommentItemWrapper,
  ProfileImage,
  CommentItem,
  Username,
  InputContainer,
  Input,
  Button,
} from "./comment-modal.styled";

const CommentModal = ({ onClose }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");

  const handleInputChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleAddComment = () => {
    if (newComment.trim() !== "") {
      const commentData = {
        username: "@username",
        profileImg: ProfileImg,
        text: newComment,
      };
      setComments((prevComments) => [...prevComments, commentData]);
      setNewComment("");
    }
  };

  return (
    <ModalBackground onClick={onClose}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>
          <IoClose />
        </CloseButton>
        <h2>Comments</h2>
        <CommentList>
          {comments.length > 0 ? (
            comments.map((comment, index) => (
              <CommentItemWrapper key={index}>
                <ProfileImage src={comment.profileImg} alt="Profile" />
                <CommentItem>
                  <Username>{comment.username}</Username>
                  <span>{comment.text}</span>
                </CommentItem>
              </CommentItemWrapper>
            ))
          ) : (
            <p>No comments yet. Be the first to comment!</p>
          )}
        </CommentList>
        <InputContainer>
          <Input
            type="text"
            value={newComment}
            onChange={handleInputChange}
            placeholder="Write a comment..."
          />
          <Button onClick={handleAddComment}>Add</Button>
        </InputContainer>
      </ModalContainer>
    </ModalBackground>
  );
};

export default CommentModal;
