import styled from "styled-components";
import { breakpoint, color } from "../../../themes/themes";

export const List = styled.ul`
  padding: 0 0 1rem 0;
  margin: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.4rem;
  max-height: 100%;
  width: 100%;
  overflow-y: auto;

  ${breakpoint.tablet} {
    grid-template-columns: repeat(2, 1fr);
    margin: 0 0 3rem;
  }

  ${breakpoint.mobile} {
    grid-template-columns: repeat(1, 1fr);
    margin: 0 0 3rem;
  }
`;

export const ListEntry = styled.li`
  background-color: ${color.white};
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0 10px 10px -5px hsla(0, 0%, 0%, 0.1);

  &:hover img {
    transform: scale(1.1);
  }
`;

export const UserDetails = styled.div`
  display: flex;
  padding: 1rem;
  align-items: center;
  gap: 0.5rem;
`;

export const UserAvatar = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    display: flex;
    object-fit: cover;
  }
`;

export const UserName = styled.h4`
  font-weight: 500;
  margin: 0;
  font-size: 0.9rem;
`;

export const PostImage = styled.div`
  width: 100%;
  height: 30vh;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    display: flex;
    object-fit: cover;
    transition: transform 0.2s ease-in-out;
  }
`;

export const PostTitle = styled.div`
  padding: 1rem;

  h4 {
    font-weight: 600;
    margin: 0;
    font-size: 1rem;
  }
`;

export const PostInteraction = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  padding: 0.5rem;
  border-top: 1px solid #ddd;

  svg {
    display: flex;
  }

  li {
    padding: 0.5rem;
    border-radius: 0.5rem;

    &:hover {
      background-color: ${color.lightviolet};
    }
  }
`;
