import React from "react";
import Container from "../../components/container/container";
import SectionTitle from "../../components/section-title/section-title";
import { RiDoubleQuotesL } from "react-icons/ri";
import { Wrapper, ListItem, Item, User, Profile, Name } from "./reviews.styled";

const reviews = [
  {
    text: "Love the integrations with Calendar, Zoom and WhatsApp. Makes my life easier!",
    name: "Xinran Waibel",
    position: "Founder of Data Engineer",
    image:
      "https://i0.wp.com/www.steverrobbins.com/wp-content/uploads/ceo-midjourney-office.jpg?resize=1024%2C683&ssl=1",
  },
  {
    text: "Autosell.io has significantly boosted my team's productivity. A game changer!",
    name: "John Doe",
    position: "CEO of Tech Innovators",
    image: "https://randomuser.me/api/portraits/men/32.jpg",
  },
  {
    text: "Incredible tool! The seamless integrations save us so much time and effort.",
    name: "Jane Smith",
    position: "CTO of Smart Solutions",
    image: "https://randomuser.me/api/portraits/women/44.jpg",
  },
  {
    text: "Love the integrations with Calendar, Zoom and WhatsApp. Makes my life easier!",
    name: "Xinran Waibel",
    position: "Founder of Data Engineer",
    image:
      "https://i0.wp.com/www.steverrobbins.com/wp-content/uploads/ceo-midjourney-office.jpg?resize=1024%2C683&ssl=1",
  },
  {
    text: "Autosell.io has significantly boosted my team's productivity. A game changer!",
    name: "John Doe",
    position: "CEO of Tech Innovators",
    image: "https://randomuser.me/api/portraits/men/32.jpg",
  },
  {
    text: "Incredible tool! The seamless integrations save us so much time and effort.",
    name: "Jane Smith",
    position: "CTO of Smart Solutions",
    image: "https://randomuser.me/api/portraits/women/44.jpg",
  },
];

const Reviews = () => {
  return (
    <Wrapper>
      <Container>
        <SectionTitle title="People Love Using Autosell.io" theme="dark" />
        <ListItem>
          {reviews.map((review, index) => (
            <Item key={index}>
              <RiDoubleQuotesL aria-hidden="true" />
              <p>{review.text}</p>
              <User>
                <Profile>
                  <img src={review.image} alt={review.name} />
                </Profile>
                <Name>
                  <h3>{review.name}</h3>
                  <p>{review.position}</p>
                </Name>
              </User>
            </Item>
          ))}
        </ListItem>
      </Container>
    </Wrapper>
  );
};

export default Reviews;
