import React from "react";
import ServiceSection from "../../../components/service-section/service-section";

const services = [
  "Customize landing pages for your services and products",
  "Connect pixels to Meta ads",
  "Generate unique links",
  "Cool and professional animations",
  "Upload videos, carousels, and images",
  "Customize based on your style",
];

const Section7 = () => {
  const imageSrc = "";

  return (
    <ServiceSection
      title="Customize Landing Pages"
      services={services}
      imageSrc={imageSrc}
    />
  );
};

export default Section7;
