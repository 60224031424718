import styled from "styled-components";
import { breakpoint, color } from "../../../themes/themes";

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.8rem;
  padding: 1rem;
  background-color: ${color.primary};
  color: ${color.white};
  position: relative;

  ${breakpoint.mobile} {
    background-color: ${color.white};
    color: ${color.darkBg};
    justify-content: space-between;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 1.2rem 1rem;
  }
`;

export const Item = styled.div`
  padding: 0.6rem;
  font-size: 0.95rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 500;
  display: inline-block;

  &:hover {
    background-color: ${color.white};
    color: ${color.darkBg};
  }

  ${(props) =>
    props.$active &&
    `
      background-color: #FFED00;
      color: ${color.darkBg};
    `}

  ${breakpoint.mobile} {
    display: none;
  }
`;

export const Logo = styled.div`
  padding: 0 0.5rem;

  svg {
    display: flex;
    font-size: 1.8rem;
  }

  ${breakpoint.mobile} {
    svg {
      color: ${color.primary};
      font-size: 2rem;
    }
    padding: 0;
  }
`;

export const MenuButton = styled.div`
  font-size: 2rem;
  cursor: pointer;
  display: none;

  svg {
    display: flex;
  }

  ${breakpoint.mobile} {
    display: block;
  }
`;

export const Sidebar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  height: 100%;
  background-color: ${color.primary};
  padding: 2rem 1rem;
  z-index: 1001;
  transition: transform 0.3s ease;
  transform: translateX(-100%);

  ${(props) =>
    props.$isOpen &&
    `
      transform: translateX(0); 
    `}
`;

export const SidebarItem = styled.div`
  padding: 0.8rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  color: ${color.white};
  cursor: pointer;

  &:hover {
    background-color: ${color.white};
    color: ${color.darkBg};
  }

  ${(props) =>
    props.$active &&
    `
    background-color: ${color.white};
    color: ${color.darkBg};
    `}

  ${breakpoint.mobile} {
    display: block;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
`;
