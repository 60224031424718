import React, { useState } from "react";
import SectionTitle from "../../components/section-title/section-title";
import Container from "../../components/container/container";
import UsersList from "./usersList";
import { FiSearch } from "react-icons/fi";
import {
  Wrapper,
  TopBar,
  Title,
  SearchBar,
  SearchIcon,
  ModalOverlay,
  ModalContent,
} from "./users.styled";

const Users = () => {
  const [showSearchModal, setShowSearchModal] = useState(false);

  const toggleSearchModal = () => {
    setShowSearchModal(!showSearchModal);
  };

  return (
    <Wrapper>
      <Container>
        <SectionTitle title="For people making Impact" />

        <TopBar>
          <Title>
            <h1>All Users</h1>
            <p>Here are some of our creators</p>
          </Title>
          <div style={{ display: "flex", alignItems: "center" }}>
            <SearchBar>
              <input
                type="text"
                placeholder="Search for users"
                aria-label="Search for users"
              />
              <button type="submit">Search</button>
            </SearchBar>

            <SearchIcon onClick={toggleSearchModal}>
              <FiSearch />
            </SearchIcon>
          </div>
        </TopBar>

        <ModalOverlay $show={showSearchModal} onClick={toggleSearchModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <h2>Search for Users</h2>
            <input
              type="text"
              placeholder="Search for users"
              aria-label="Search for users"
            />
            <button type="submit">Search</button>
          </ModalContent>
        </ModalOverlay>
        <UsersList />
      </Container>
    </Wrapper>
  );
};

export default Users;
