import NavbarComponent from "../components/navbar/navbar";
import Offer from "../homepage/offer/offer";
import Footer from "../components/footer/footer";

const Homepage = () => {
  const navItems = [
    { label: "Home", path: "#offer" },
    { label: "Services", path: "#services" },
    { label: "Features", path: "#features" },
    { label: "About", path: "#about" },
  ];

  const buttons = [
    { label: "Log in", path: "/login" },
    { label: "Sign Up", path: "/signup" },
  ];

  return (
    <>
      <NavbarComponent
        showLogo={true}
        logoPath="/"
        logoText="Autosell.io"
        navItems={navItems}
        showSearch={true}
        buttons={buttons}
      />
      <section id="offer">
        <Offer />
      </section>
      <Footer />
    </>
  );
};

export default Homepage;
