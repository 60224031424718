import React from "react";
import { Routes, Route } from "react-router-dom";
import Homepage from "../pages/homepage";
import LandingPage from "../pages/landing-page";
import LoginPage from "../pages/login-page";
import SignupPage from "../pages/signup-page";
import ResetPassPage from "../pages/reset-pass-page";
import QuizPage from "../pages/quiz-page";
import NewsFeedPage from "../pages/app-pages/newsfeed-page";
import DashboardPage from "../pages/app-pages/dashboard-page";
import PricingPage from "../pages/pricing-page";
import PaymentPage from "../pages/payment-page";
import UserAccountPage from "../pages/app-pages/user-account-page";
import MobileInboxPage from "../pages/app-pages/mobile-inbox-page";
import MobileNewMessagePage from "../pages/app-pages/mobile-new-message-page";
import MobileChatPage from "../pages/app-pages/mobile-chat-page";
import ServicesPage from "../pages/app-pages/services-page";
import DigitalProductsPage from "../pages/app-pages/digital-products-page";
import ChatAutomationPage from "../pages/app-pages/chat-automation-page";
import HiringPage from "../pages/app-pages/hiring-page";
import UnderConstruction from "../app/app-components/under-construction";
import ChallengePage from "../pages/app-pages/challenge-page";
import TestimonialsPage from "../pages/app-pages/testimonials-page";
import CommunityPage from "../pages/app-pages/community-page";
import AnnouncementsPage from "../pages/app-pages/announcements-page";
import AutosellCommunityPage from "../pages/app-pages/autosell-community-page";
import CalendarPage from "../pages/app-pages/calendar-page";
import ProtectedRoute from "./protected-route";
import AuthRoute from "./auth-route";
import Loading from "../components/loading/loading";
import { useAuth } from "../context/AuthContext";

function AppRoutes() {
  const { loading } = useAuth();

  if (loading) {
    return <Loading />;
  }

  return (
    <Routes>
      {/* Normal Routes */}
      <Route path="/" element={<Homepage />} />
      <Route path="/landing-page" element={<LandingPage />} />
      <Route path="/get-started" element={<QuizPage />} />
      <Route path="/pricing" element={<PricingPage />} />

      {/* Auth Routes */}
      <Route
        path="/signup"
        element={
          <AuthRoute>
            <SignupPage />
          </AuthRoute>
        }
      />
      <Route
        path="/reset"
        element={
          <AuthRoute>
            <ResetPassPage />
          </AuthRoute>
        }
      />
      <Route
        path="/login"
        element={
          <AuthRoute>
            <LoginPage />
          </AuthRoute>
        }
      />

      {/* Protected Routes */}
      <Route
        path="/newsfeed"
        element={
          <ProtectedRoute>
            <NewsFeedPage />
          </ProtectedRoute>
        }
      />
      <Route path="/dashboard" element={<DashboardPage />} />

      {/* I Dont Know Routes */}
      <Route path="/services" element={<ServicesPage />} />
      <Route path="/username@autosell.io" element={<UserAccountPage />} />
      <Route path="/mobile-inbox" element={<MobileInboxPage />} />
      <Route path="/new-message" element={<MobileNewMessagePage />} />
      <Route path="/chat/:chatName" element={<MobileChatPage />} />
      <Route path="/payment" element={<PaymentPage />} />
      <Route path="/digital-products" element={<DigitalProductsPage />} />
      <Route path="/autosell-assist" element={<ChatAutomationPage />} />
      <Route path="/hiring" element={<HiringPage />} />
      <Route path="/under-construction" element={<UnderConstruction />} />
      <Route path="/challenge" element={<ChallengePage />} />
      <Route path="/testimonials" element={<TestimonialsPage />} />
      <Route path="/community/chat-anonymously" element={<CommunityPage />} />
      <Route
        path="/my-community/announcements"
        element={<AnnouncementsPage />}
      />
      <Route path="/autosell-community" element={<AutosellCommunityPage />} />
      <Route path="/my-community/calendar" element={<CalendarPage />} />
    </Routes>
  );
}

export default AppRoutes;
