import styled from "styled-components";
import { breakpoint, color } from "../../../../themes/themes";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint.tablet} {
    display: none;
  }

  ${breakpoint.desktop} {
    display: none;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  background-color: ${color.white};
  width: 100%;
  top: 0;
  padding: 1rem;

  .header-content {
    display: flex;
    gap: 1rem;
    align-self: flex-start;
  }

  h2 {
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0;
  }

  svg {
    cursor: pointer;
    font-size: 1.5rem;
  }
`;

export const Input = styled.input`
  margin-top: 1rem;
  padding: 0.6rem;
  border-radius: 0.25rem;
  width: 100%;
  font-size: 1rem;
  border: 1px solid #e0e0e0;

  &:focus {
    border-color: ${color.primary};
    outline: none;
  }
`;

export const UserList = styled.ul`
  padding: 0;
  margin: 7rem 0 1rem;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  overflow-y: auto;
  max-height: 100%;
`;

export const UserListItem = styled.li`
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.8rem;

  &:hover {
    background-color: #f4f4f4;
  }
`;

export const Avatar = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  overflow: hidden;

  img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const UserName = styled.h4`
  margin: 0;
  font-weight: 600;
`;
