import React from "react";
import { GrClose } from "react-icons/gr";
import { Wrapper, Header, Input } from "./new-message.styled";

const NewMessage = ({ onClose }) => {
  return (
    <Wrapper>
      <Header>
        <h4>New Message</h4>
        <GrClose onClick={onClose} />
      </Header>
      <Input placeholder="To:" />
    </Wrapper>
  );
};

export default NewMessage;
