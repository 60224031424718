import styled from "styled-components";
import { breakpoint, color } from "../../../../themes/themes";

export const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(0.8rem);
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
  width: 70%;

  ${breakpoint.tablet} {
    width: 90%;
  }

  ${breakpoint.mobile} {
    width: 100%;
  }
`;

export const Header = styled.div`
  p {
    margin: 0;
    text-align: right;
  }
`;

export const ModalBody = styled.div`
  border-radius: 1.5rem;
  width: 100%;
  background-color: ${color.primary};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const ProfileCard = styled.div`
  border-radius: 1rem;
  padding: 3rem;
  background-color: ${color.white};
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: relative;
  z-index: 1;

  ${breakpoint.tablet} {
    width: 50%;
  }

  ${breakpoint.mobile} {
    width: 100%;
  }
`;

export const ProfileImage = styled.div`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  overflow: hidden;
  background-image: url(${(props) => props.$imgSrc});
  background-size: cover;
  background-position: center;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;

  h1,
  p {
    margin: 0;
  }

  h1 {
    font-size: 1.6rem;
  }
`;

export const ActionButton = styled.button`
  border: none;
  background-color: red;
  color: ${color.white};
  padding: 0.8rem 1rem;
  border-radius: 10rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 600;
  width: 20%;
  position: relative;
  margin-top: -1.2rem;
  z-index: 2;
  transition: transform 0.2s ease-in-out;

  svg {
    display: flex;
    font-size: 1.1rem;
  }

  &:hover {
    background-color: #800000;
    transform: scale(1.05);
  }

  ${breakpoint.mobile} {
    width: 60%;
  }
`;

export const Confetti = styled.div`
  width: 12rem;
  height: 12rem;
  position: absolute;
  top: 65%;
  transform: translateY(-50%);
  z-index: 2;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.left {
    left: -6rem;
  }

  &.right {
    top: 30%;
    right: -6rem;
    transform: translateY(-50%) scaleX(-1);
  }
`;

export const Slots = styled.div`
  display: flex;
  align-self: flex-end;
  gap: 0.5rem;
  align-items: center;
  color: ${color.white};

  p {
    margin: 0;
  }

  svg {
    display: flex;
  }
`;

export const Footer = styled.div`
  align-self: flex-end;

  button {
    padding: 0.5rem 1rem;
    border: none;
    background-color: ${color.transparent};
    color: ${color.primary};
    text-decoration: underline 1px ${color.primary};
    font-size: 1rem;
    cursor: pointer;
    border-radius: 0.5rem;

    &:hover {
      background-color: ${color.primary};
      color: ${color.white};
    }
  }
`;
