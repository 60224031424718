import styled from "styled-components";
import { color } from "../../../../themes/themes";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  color: ${color.black};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;

  h1 {
    margin: 0;
  }
`;

export const IconGroup = styled.div`
  display: flex;
  gap: 1rem;

  svg {
    display: flex;
    font-size: 1.2rem;
    cursor: pointer;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.4rem;
  border: 1px solid #ccc;
  border-radius: 0.3rem;

  &:focus-within {
    border-color: ${color.primary};
  }
`;

export const SearchInput = styled.input`
  flex: 1;
  border: none;
  font-size: 0.9rem;
  outline: none;
`;

export const SearchButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 0.5rem;
  color: #888;

  &:hover {
    color: ${color.primary};
  }

  svg {
    font-size: 1.2rem;
  }
`;

export const ChatList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  overflow-y: auto;
  max-height: 100%;
  width: 100%;
`;

export const ChatItem = styled.li`
  padding: 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${color.white};
  border-radius: 0.5rem;
  justify-content: space-between;

  &:hover {
    background-color: #f4f4f4;
  }
`;

export const ChatLeft = styled.div`
  display: flex;
  gap: 0.6rem;
  align-items: center;
`;

export const Avatar = styled.div`
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  overflow: hidden;

  img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

export const UserName = styled.h4`
  margin: 0;
  font-weight: 600;
`;

export const UserMessage = styled.div`
  display: flex;
  gap: 0.5rem;

  p {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 300;
  }
`;

export const Timestamp = styled.p`
  color: #aaa;
`;

export const StatusIndicator = styled.div`
  background-color: ${color.lightgreen};
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
`;

export const AutomateButton = styled.button`
  display: flex;
  gap: 0.5rem;
  border-radius: 4rem;
  background-color: ${color.primary};
  color: ${color.white};
  padding: 0.8rem 1rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 1rem;

  svg {
    display: flex;
    font-size: 1.5rem;
  }

  &:hover {
    background-color: ${color.lightblue};
  }
`;
