import React from "react";
import Ads from "../ads/ads";
import { FiSearch } from "react-icons/fi";
import ProfilePic from "../../../assets/Story1.png";
import {
  Wrapper,
  SearchBar,
  Input,
  Button,
  UserList,
  UserItem,
  ProfileWrapper,
  Avatar,
  UserInfo,
  FollowText,
} from "./suggested-user.styled";

const users = [
  { name: "John Doe", description: "New to Autosell.io", image: ProfilePic },
  { name: "Jane Smith", description: "Car enthusiast", image: ProfilePic },
  { name: "Emily Johnson", description: "Just joined", image: ProfilePic },
  { name: "John Doe", description: "New to Autosell.io", image: ProfilePic },
  { name: "Jane Smith", description: "Car enthusiast", image: ProfilePic },
  { name: "Emily Johnson", description: "Just joined", image: ProfilePic },
  { name: "John Doe", description: "New to Autosell.io", image: ProfilePic },
  { name: "Jane Smith", description: "Car enthusiast", image: ProfilePic },
  { name: "Emily Johnson", description: "Just joined", image: ProfilePic },
  { name: "John Doe", description: "New to Autosell.io", image: ProfilePic },
  { name: "Jane Smith", description: "Car enthusiast", image: ProfilePic },
  { name: "Emily Johnson", description: "Just joined", image: ProfilePic },
  { name: "John Doe", description: "New to Autosell.io", image: ProfilePic },
  { name: "Jane Smith", description: "Car enthusiast", image: ProfilePic },
  { name: "Emily Johnson", description: "Just joined", image: ProfilePic },
  { name: "John Doe", description: "New to Autosell.io", image: ProfilePic },
  { name: "Jane Smith", description: "Car enthusiast", image: ProfilePic },
];

const SuggestedUser = () => {
  return (
    <Wrapper>
      <SearchBar>
        <Input type="text" placeholder="Search users..." name="search" />
        <Button>
          <FiSearch />
        </Button>
      </SearchBar>
      <UserList>
        {users.map((user, index) => (
          <UserItem key={index}>
            <ProfileWrapper>
              <Avatar>
                <img src={user.image} alt="profile" />
              </Avatar>
              <UserInfo>
                <h4>{user.name}</h4>
                <p>{user.description}</p>
              </UserInfo>
            </ProfileWrapper>
            <FollowText>Follow</FollowText>
          </UserItem>
        ))}
      </UserList>
      <Ads />
    </Wrapper>
  );
};

export default SuggestedUser;
