const botResponses = {
  "What is Autosell?":
    "Autosell is an automated platform that helps manage and sell your products effectively.",
  "How does it work?":
    "Autosell works by integrating with your product listings and automating the sales process.",
  "Is there a fee?":
    "Yes, Autosell charges a small service fee depending on your sales volume.",
  "What are the benefits of using Autosell?":
    "With Autosell, you can save time, increase efficiency, and automate the sales process, allowing you to focus on growing your business.",
  "How do I get started with Autosell?":
    "To get started, simply sign up on our platform, connect your product listings, and configure your sales automation settings.",

  Hello: "Hi! How can I assist you today?",
  Hi: "Hello! How can I help you today?",
  "Okay, Goodbye": "Goodbye! Have a great day!",
  "How are you?": "I'm just a bot, but I'm doing great! Thanks for asking.",

  "What is the return policy?":
    "Our return policy allows returns within 30 days of purchase. For more details, please visit our return policy page.",
  "Can I cancel an order?":
    "Yes, you can cancel an order within 24 hours of placing it. Please contact our support team for assistance.",
  "What payment methods do you accept?":
    "We accept all major credit cards, PayPal, and bank transfers.",
  "How do I track my order?":
    "You can track your order through the tracking number provided in your order confirmation email.",

  default: "Sorry, I don't understand that. Can you ask something else?",
};

export default botResponses;
