import NavbarComponent from "../components/navbar/navbar";
import PaymentForm from "../app/app-components/payment-form/payment-form";
import Footer from "../components/footer/footer";

const PaymentPage = () => {
  const navItems = [
    { label: "Features", path: "/landing-page#features" },
    { label: "Testimonials", path: "/landing-page#testimonials" },
    { label: "Pricing", path: "/pricing" },
    { label: "About", path: "/landing-page#about" },
  ];

  const buttons = [
    { label: "Get Started", path: "/get-started" },
    { label: "Sign Up", path: "/signup" },
  ];

  return (
    <>
      <NavbarComponent
        showLogo={true}
        logoPath="/landing-page"
        logoText="Autosell.io"
        navItems={navItems}
        showSearch={true}
        buttons={buttons}
      />
      <PaymentForm />
      <Footer />
    </>
  );
};

export default PaymentPage;
