import React from "react";
import ServiceSection from "../../../components/service-section/service-section";

const services = [
  "Act as your link in bio",
  "Customize your own design",
  "Add various features and sections",
  "Showcase all your offers and services",
  "Upload samples of your edits and work",
  "Post uploads showcasing your expertise",
];

const Section5 = () => {
  const imageSrc = "";

  return (
    <ServiceSection
      title="Built in Professional Portfolio"
      services={services}
      imageSrc={imageSrc}
    />
  );
};

export default Section5;
