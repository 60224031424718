import Section1 from "../landingpage/features/section1/section1";
import Section2 from "../landingpage/features/section2/section2";
import Section3 from "../landingpage/features/section3/section3";
import Section4 from "../landingpage/features/section4/section4";
import Section5 from "../landingpage/features/section5/section5";
import Section6 from "../landingpage/features/section6/section6";
import Section7 from "../landingpage/features/section7/section7";

const FeaturesPage = () => {
  return (
    <>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
    </>
  );
};

export default FeaturesPage;
