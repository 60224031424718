import styled from "styled-components";
import { breakpoint, color } from "../../themes/themes";
import BackgroundImg from "../../assets/hero-bg.gif";

export const Wrapper = styled.section`
  height: 100vh;
  background: url(${BackgroundImg}) no-repeat center center / cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 40, 0.7);
    mix-blend-mode: darken;
    z-index: 1;
  }

  > * {
    position: relative;
    z-index: 2;
  }
`;

export const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  text-align: center;

  * {
    margin: 0;
    color: ${color.white};
  }

  ${breakpoint.mobile} {
    gap: 0.8rem;
  }
`;

export const Title = styled.h1`
  font-size: 4rem;
  font-family: "Poppins", sans-serif;
  line-height: 4.6rem;

  ${breakpoint.mobile} {
    font-size: 2.2rem;
    line-height: 2.8rem;
  }

  span {
    color: ${color.lightblue};
  }
`;

export const Button = styled.button`
  border: none;
  padding: 1rem;
  width: 10rem;
  background-color: ${color.primary};
  color: ${color.white};
  margin-top: 3rem;
  border-radius: 2rem;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 0.9rem;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${color.lightblue};
  }
`;
