import AppHeader from "../../app/app-components/app-navbar/app-header";
import MobileNav from "../../app/app-components/app-navbar/mobile-navbar";
import UserAccount from "../../app/app-components/user-account/user-account";

const UserAccountPage = () => {
  return (
    <>
      <AppHeader />
      <UserAccount />
      <MobileNav />
    </>
  );
};

export default UserAccountPage;
