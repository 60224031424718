import styled from "styled-components";
import { breakpoint, color } from "../../../themes/themes";

export const Wrapper = styled.div`
  padding: 1rem;
  margin-right: 1rem;
  border-radius: 0.5rem;
  background-color: white;
  height: calc(100vh - 6rem);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ${breakpoint.tablet} {
    padding: 6rem 0.8rem 0;
    margin-right: 0;
  }

  ${breakpoint.mobile} {
    padding: 6rem 0.8rem 0;
    margin-right: 0;
  }
`;

export const SearchBar = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  margin-bottom: 1rem;

  &:focus-within {
    border-color: ${color.primary};
  }
`;

export const Input = styled.input`
  flex: 1;
  border: none;
  font-size: 0.9rem;
  outline: none;
`;

export const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 0.5rem;
  color: #888;

  &:hover {
    color: ${color.primary};
  }

  svg {
    font-size: 1.2rem;
  }
`;

export const UserList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: hidden;
  flex-grow: 1;
  max-height: 40vh;

  &:hover {
    overflow-y: auto;
  }

  ${breakpoint.tablet} {
    overflow-y: auto;
  }

  ${breakpoint.mobile} {
    overflow-y: auto;
  }

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #eee;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }
`;

export const UserItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-right: 0.7rem;
`;

export const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Avatar = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;

  h4,
  p {
    margin: 0;
  }

  h4 {
    font-size: 0.9rem;
    font-weight: 600;
  }

  p {
    font-size: 0.8rem;
    color: #ccc;
  }
`;

export const FollowText = styled.p`
  margin: 0;
  font-size: 0.9rem;
  color: ${color.link};
`;
