import React from "react";
import AppLayout from "../../app/app-components/app-layout/app-layout";
import AppHeader from "../../app/app-components/app-navbar/app-header";
import MobileNav from "../../app/app-components/app-navbar/mobile-navbar";
import Challenge from "../../app/challenge/challenge";
import Dashboard from "../../app/app-components/dashboard/dashboard";

const ChallengePage = () => {
  return (
    <>
      <AppHeader />
      <AppLayout leftContent={<Dashboard />} centerContent={<Challenge />} />
      <MobileNav />
    </>
  );
};

export default ChallengePage;
