import styled from "styled-components";
import { color, breakpoint } from "../../themes/themes";

// ----- users styled component ----- //
export const Wrapper = styled.section`
  padding: 5rem 0;
`;

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3.4rem 0;

  ${breakpoint.mobile} {
    margin: 3rem 0;
    align-items: flex-start;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.2rem;

  h1,
  p {
    margin: 0;
  }

  p {
    color: darkgray;
  }

  h1 {
    font-size: 1.8rem;
  }

  ${breakpoint.mobile} {
    h1 {
      font-size: 1.5rem;
    }

    p {
      font-size: 1rem;
    }
  }
`;

export const SearchBar = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;

  input {
    border: 1px solid ${color.primary};
    border-radius: 0.5rem;
    padding: 0.6rem;
  }

  button {
    border: none;
    border-radius: 0.5rem;
    background-color: ${color.primary};
    padding: 0.6rem;
    color: ${color.white};
    cursor: pointer;
  }

  ${breakpoint.mobile} {
    display: none;
  }
`;

export const SearchIcon = styled.div`
  display: none;
  cursor: pointer;

  svg {
    font-size: 1.6rem;
  }

  ${breakpoint.mobile} {
    display: block;
  }
`;

export const ModalOverlay = styled.div`
  display: ${(props) => (props.$show ? "flex" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background-color: ${color.white};
  padding: 3rem 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.4rem;
  width: 90%;

  h2 {
    margin: 0;
  }

  input {
    border: 1px solid ${color.primary};
    border-radius: 0.5rem;
    padding: 0.6rem;
    width: 100%;
  }

  button {
    border: none;
    border-radius: 0.5rem;
    background-color: ${color.primary};
    padding: 0.6rem 2rem;
    color: ${color.white};
    cursor: pointer;
  }
`;

// ----- usersList styled component ----- //
export const ListItem = styled.ul`
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;

  ${breakpoint.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${breakpoint.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Item = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding: 2.4rem 1rem;
  border-radius: 0.9rem;
  border: 1px solid ${color.lightblue};
  gap: 1.8rem;
  background: linear-gradient(
    to bottom,
    ${color.lightblue} 28%,
    ${color.white} 28%
  );
`;

export const User = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
`;

export const Profile = styled.div`
  width: 5rem;
  background-color: ${color.white};
  border-radius: 50%;

  img {
    display: flex;
    width: 100%;
    object-fit: cover;
  }
`;

export const Rating = styled.div`
  display: flex;
  gap: 0.2rem;
  margin: 0.6rem 0 0.4rem;

  svg {
    font-size: 0.9rem;
  }
`;

export const Name = styled.h3`
  margin: 0;
  font-size: 1rem;
`;

export const UserName = styled.p`
  margin: 0;
  font-size: 0.8rem;
  color: ${color.gray};
`;

export const ActionButton = styled.button`
  padding: 0.8rem 1rem;
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid ${color.lightblue};
  cursor: pointer;
  background-color: ${color.transparent};

  &:hover {
    background-color: ${color.lightblue};
    color: ${color.white};
  }
`;
