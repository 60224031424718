import React from "react";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import chats from "../../database/chats-data";
import {
  Container,
  Header,
  Input,
  UserList,
  UserListItem,
  Avatar,
  UserName,
} from "./mobile-new-message.styled";

const MobileNewMessage = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Header>
        <div className="header-content">
          <IoMdArrowBack onClick={handleBackClick} aria-label="Go back" />
          <h2>New Message</h2>
        </div>
        <Input placeholder="To:" />
      </Header>
      <UserList>
        {chats.map((chat, index) => (
          <UserListItem key={index}>
            <Avatar>
              <img src={chat.image} alt={chat.name + " profile"} />
            </Avatar>
            <UserName>{chat.name}</UserName>
          </UserListItem>
        ))}
      </UserList>
    </Container>
  );
};

export default MobileNewMessage;
