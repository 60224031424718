import React from "react";
import Container from "../container/container";
import styled from "styled-components";
import { color } from "../../themes/themes";

const Wrapper = styled.footer`
  padding: 2rem 0;
  background-color: ${color.darkBg};
  color: ${color.white};
`;

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <small>©2024 Autosell.io. All rights reserved</small>
      </Container>
    </Wrapper>
  );
};

export default Footer;
