import React, { useEffect, useState } from "react";
import Container from "../../components/container/container";
import SectionTitle from "../../components/section-title/section-title";
import { FaStar } from "react-icons/fa";
import { FaDownload } from "react-icons/fa6";
import Image from "../../assets/blank.png";
import {
  Wrapper,
  Content,
  Categories,
  ListItem,
  Item,
  Thumbnail,
  Details,
  Title,
  Rating,
  Stars,
  Sales,
} from "./offer.styled";
import homePageApi from "../../api/home-page-api";

// const offers = [
//   {
//     title: "Landing Page Software",
//     description: "By autosell.io In trial",
//     image: Image,
//     rating: 5,
//     sales: 1,
//     category: "Web Development",
//   },
//   {
//     title: "Video Editing Software",
//     description: "By editpro.io In trial",
//     image: Image,
//     rating: 4,
//     sales: 50,
//     category: "Web Development",
//   },
//   {
//     title: "Graphic Design Tool",
//     description: "By designhub.io In trial",
//     image: Image,
//     rating: 3,
//     sales: 120,
//     category: "Image Editor",
//   },
//   {
//     title: "Landing Page Software",
//     description: "By autosell.io In trial",
//     image: Image,
//     rating: 5,
//     sales: 1,
//     category: "Web Development",
//   },
//   {
//     title: "Video Editing Software",
//     description: "By editpro.io In trial",
//     image: Image,
//     rating: 4,
//     sales: 50,
//     category: "Video Software",
//   },
//   {
//     title: "Graphic Design Tool",
//     description: "By designhub.io In trial",
//     image: Image,
//     rating: 3,
//     sales: 120,
//     category: "Web Development",
//   },
//   {
//     title: "Landing Page Software",
//     description: "By autosell.io In trial",
//     image: Image,
//     rating: 5,
//     sales: 1,
//     category: "Web Development",
//   },
//   {
//     title: "Video Editing Software",
//     description: "By editpro.io In trial",
//     image: Image,
//     rating: 4,
//     sales: 50,
//     category: "Video Software",
//   },
//   {
//     title: "Graphic Design Tool",
//     description: "By designhub.io In trial",
//     image: Image,
//     rating: 3,
//     sales: 120,
//     category: "Video Software",
//   },
// ];

// const categories = [
//   "All Categories",
//   "Video Software",
//   "Image Editor",
//   "Web Development",
// ];

const Offer = () => {
  const [selectedCategory, setSelectedCategory] = useState("All Categories");
  const [offers, setOffers] = useState([]);
  const [categories, setCategories] = useState([]);
  const getOffers = async () => {
    try {
      const res = await homePageApi.getOffers();
      const categories = [
        "All Categories",
        ...new Set(res.map((el) => el.category)),
      ];
      setCategories(categories);
      setOffers(res);
    } catch {}
  };

  useEffect(() => {
    getOffers();
  }, []);

  const genPostImageUrl = (name) =>
    `${process.env.REACT_APP_BASE_IMAGE_URL}/${name}`;

  const filteredOffers =
    selectedCategory === "All Categories"
      ? offers
      : offers.filter((offer) => offer.category === selectedCategory);

  return (
    <Wrapper>
      <Container>
        <SectionTitle title="What We Offer" />
        <Content>
          <Categories>
            {categories.map((category, index) => (
              <li
                key={index}
                className={selectedCategory === category ? "active" : ""}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </li>
            ))}
          </Categories>

          <ListItem>
            {filteredOffers.map((offer, index) => (
              <Item key={index}>
                <Thumbnail>
                  <img
                    src={offer.image ? genPostImageUrl(offer.image) : Image}
                    alt={offer.title}
                  />
                </Thumbnail>
                <Details>
                  <Title>
                    <h2>{offer.title}</h2>
                    <p>{offer.description}</p>
                  </Title>
                  <Rating>
                    <Stars>
                      {[...Array(offer.rating)].map((_, i) => (
                        <FaStar key={i} />
                      ))}
                    </Stars>
                    <Sales>
                      <FaDownload />
                      <p>{offer.sales}</p>
                    </Sales>
                  </Rating>
                </Details>
              </Item>
            ))}
          </ListItem>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Offer;
