import React from "react";
import {
  AdsSection,
  AdsList,
  AdItem,
  AdThumbnail,
  AdTitle,
} from "./ads.styled";

const Ads = () => {
  const ads = [
    {
      id: 1,
      imgSrc:
        "https://www.bacolodlifestyle.com/wp-content/uploads/2021/04/GCash-GLife-FB.jpg",
      title: "GCash mo na yan!",
      link: "https://m.gcash.com/",
    },
    {
      id: 2,
      imgSrc:
        "https://www.adweek.com/wp-content/uploads/2024/10/paypal-data-brands-2024.jpg?w=1200",
      title: "PayPal: Send Payments",
      link: "https://www.paypal.com/ph/",
    },
  ];

  return (
    <AdsSection>
      <AdsList>
        {ads.map((ad) => (
          <AdItem key={ad.id}>
            <AdThumbnail>
              <img src={ad.imgSrc} alt="ads" />
            </AdThumbnail>
            <AdTitle>
              <h4>{ad.title}</h4>
              <p>{ad.link}</p>
            </AdTitle>
          </AdItem>
        ))}
      </AdsList>
    </AdsSection>
  );
};

export default Ads;
