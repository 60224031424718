import React from "react";
import { FaChevronCircleRight } from "react-icons/fa";
import { Wrapper, Note, Button } from "./footer.styled";

const ChatAFooter = ({ setModalVisible }) => {
  return (
    <Wrapper>
      <Note>
        *Do not send any inappropriate chats or images to avoid being reported.
        If you get reported 10 times, your account will be completely disabled
        for this feature, and you will no longer be able to use it.
      </Note>
      <Button onClick={() => setModalVisible(true)}>
        SKIP <FaChevronCircleRight />
      </Button>
    </Wrapper>
  );
};

export default ChatAFooter;
