import { IoSend } from "react-icons/io5";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import chats from "../../database/chats-data";
import {
  Container,
  Header,
  Body,
  ChatMessage,
  Avatar,
  Footer,
  MessageInput,
  SendButton,
} from "./mobile-chat.styled";

const MobileChat = () => {
  const { chatName } = useParams();
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  // Find the chat from the chat list by name
  const chat = chats.find((c) => c.name === chatName);

  useEffect(() => {
    if (chat) {
      setMessages([
        { user: chat.name, text: chat.message, isUserMessage: false },
      ]);
    }
  }, [chat]);

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = () => {
    if (message.trim()) {
      const newMessage = {
        user: chat.name,
        text: message,
        isUserMessage: true,
      };
      setMessages([...messages, newMessage]);
      setMessage("");
    }
  };

  if (!chat) {
    return <div>Chat not found.</div>;
  }

  return (
    <Container>
      <Header>
        <IoMdArrowBack onClick={handleBackClick} aria-label="Go back" />
        <h4>{chat.name}</h4>
      </Header>
      <Body>
        {messages.map((msg, index) => (
          <ChatMessage key={index} $isUserMessage={msg.isUserMessage}>
            {!msg.isUserMessage && (
              <Avatar>
                <img src={chat.image} alt="profile" />
              </Avatar>
            )}
            <p>{msg.text}</p>
          </ChatMessage>
        ))}
      </Body>
      <Footer>
        <MessageInput
          placeholder="Type a message..."
          value={message}
          onChange={handleInputChange}
        />
        <SendButton onClick={handleSendMessage}>
          <IoSend />
        </SendButton>
      </Footer>
    </Container>
  );
};

export default MobileChat;
