import React from "react";
import { MdOutlinePermMedia } from "react-icons/md";
import ChallengePosts from "./challenge-posts/challenge-posts";
import {
  Container,
  Header,
  Title,
  Form,
  InputContainer,
  Input,
  Asset,
  SubmitButton,
} from "./challenge.styled";

const Challenge = () => {
  return (
    <Container>
      <Header>
        <Title>
          A creative picture of you with your pet to promote autosell.
        </Title>
        <Form>
          <InputContainer>
            <Input placeholder="Upload yours.." />
            <Asset>
              <MdOutlinePermMedia />
            </Asset>
          </InputContainer>
          <SubmitButton>Upload</SubmitButton>
        </Form>
      </Header>
      <ChallengePosts />
    </Container>
  );
};

export default Challenge;
