import React, { useState } from "react";
import GCashLogo from "../../../assets/gcash.png";
import PayPalLogo from "../../../assets/paypal.png";
import {
  Wrapper,
  PaymentFormWrapper,
  FieldContainer,
  Title,
  Label,
  Input,
  PaymentOptions,
  PaymentOptionButton,
  SubmitButton,
  Disclaimer,
} from "./payment-form.styled";

// Subcomponents
const PaymentOption = ({ icon, onClick }) => (
  <PaymentOptionButton onClick={onClick}>
    <img src={icon} alt="Payment method icon" />
  </PaymentOptionButton>
);

// Main Component
const PaymentForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  const [paymentMethod, setPaymentMethod] = useState(null);

  const paymentOptions = [
    { method: "GCash", icon: GCashLogo },
    { method: "PayPal", icon: PayPalLogo },
  ];

  const handlePayment = () => {
    alert(`Processing payment with ${paymentMethod}`);
    // Here you would typically integrate with a payment gateway
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Wrapper>
      <PaymentFormWrapper>
        <Title>Payment Method</Title>

        <FieldContainer>
          <Label htmlFor="name">Name</Label>
          <Input
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Name"
            autoComplete="name"
            required
          />
        </FieldContainer>

        <FieldContainer>
          <Label htmlFor="email">Email</Label>
          <Input
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email Address"
            autoComplete="email"
            required
          />
        </FieldContainer>

        <FieldContainer>
          <Label>Payment Method</Label>
          <PaymentOptions>
            {paymentOptions.map(({ method, icon }) => (
              <PaymentOption
                key={method}
                icon={icon}
                onClick={() => setPaymentMethod(method)}
              />
            ))}
          </PaymentOptions>
        </FieldContainer>

        <SubmitButton onClick={handlePayment}>Subscribe Now</SubmitButton>

        <Disclaimer>
          By providing your payment information, you allow us to process your
          payment securely.
        </Disclaimer>
      </PaymentFormWrapper>
    </Wrapper>
  );
};

export default PaymentForm;
