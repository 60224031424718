import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import useLoading from "./utils/hooks/useLoading";
import Loading from "./components/loading/loading";
import AppRoutes from "./routes/app-routes";
import { AuthProvider } from "./context/AuthContext";

function App() {
  const loading = useLoading(2000);
  if (loading) {
    return <Loading />;
  }

  return (
    <Router>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </Router>
  );
}

export default App;
