import ProfilePic from "../../../assets/Story1.png";

const chats = [
  {
    name: "John Doe",
    message: "Hello, Good day",
    image: ProfilePic,
    timestamp: "8h",
    isActive: true,
  },
  {
    name: "Jane Smith",
    message: "Are we still on for lunch?",
    image: ProfilePic,
    timestamp: "2h",
    isActive: false,
  },
  {
    name: "Alice Johnson",
    message: "Don't forget the meeting tomorrow.",
    image: ProfilePic,
    timestamp: "1d",
    isActive: true,
  },
  {
    name: "Bob Brown",
    message: "Can we reschedule our call?",
    image: ProfilePic,
    timestamp: "3d",
    isActive: false,
  },
  {
    name: "Charlie Black",
    message: "Looking forward to the conference.",
    image: ProfilePic,
    timestamp: "5d",
    isActive: true,
  },
  {
    name: "John Doe",
    message: "Hello, Good day",
    image: ProfilePic,
    timestamp: "8h",
    isActive: true,
  },
  {
    name: "Jane Smith",
    message: "Are we still on for lunch?",
    image: ProfilePic,
    timestamp: "2h",
    isActive: false,
  },
  {
    name: "Alice Johnson",
    message: "Don't forget the meeting tomorrow.",
    image: ProfilePic,
    timestamp: "1d",
    isActive: true,
  },
  {
    name: "Bob Brown",
    message: "Can we reschedule our call?",
    image: ProfilePic,
    timestamp: "3d",
    isActive: false,
  },
  {
    name: "Charlie Black",
    message: "Looking forward to the conference.",
    image: ProfilePic,
    timestamp: "5d",
    isActive: true,
  },
  {
    name: "John Doe",
    message: "Hello, Good day",
    image: ProfilePic,
    timestamp: "8h",
    isActive: true,
  },
  {
    name: "Jane Smith",
    message: "Are we still on for lunch?",
    image: ProfilePic,
    timestamp: "2h",
    isActive: false,
  },
  {
    name: "Alice Johnson",
    message: "Don't forget the meeting tomorrow.",
    image: ProfilePic,
    timestamp: "1d",
    isActive: true,
  },
  {
    name: "Bob Brown",
    message: "Can we reschedule our call?",
    image: ProfilePic,
    timestamp: "3d",
    isActive: false,
  },
  {
    name: "Charlie Black",
    message: "Looking forward to the conference.",
    image: ProfilePic,
    timestamp: "5d",
    isActive: true,
  },
  {
    name: "John Doe",
    message: "Hello, Good day",
    image: ProfilePic,
    timestamp: "8h",
    isActive: true,
  },
  {
    name: "Jane Smith",
    message: "Are we still on for lunch?",
    image: ProfilePic,
    timestamp: "2h",
    isActive: false,
  },
  {
    name: "Alice Johnson",
    message: "Don't forget the meeting tomorrow.",
    image: ProfilePic,
    timestamp: "1d",
    isActive: true,
  },
  {
    name: "Bob Brown",
    message: "Can we reschedule our call?",
    image: ProfilePic,
    timestamp: "3d",
    isActive: false,
  },
  {
    name: "Charlie Black",
    message: "Looking forward to the conference.",
    image: ProfilePic,
    timestamp: "5d",
    isActive: true,
  },
];

export default chats;
