import React from "react";
import { useNavigate } from "react-router-dom";
import { RiEditLine } from "react-icons/ri";
import { FiSearch } from "react-icons/fi";
import { RiRobot2Fill } from "react-icons/ri";
import {
  SearchInput,
  SearchButton,
  ChatList,
  ChatLeft,
  Avatar,
  UserInfo,
  UserName,
  UserMessage,
  Timestamp,
  StatusIndicator,
} from "../../popup-panels/modal-inbox/modal-inbox.styled";
import chats from "../../database/chats-data";
import {
  Wrapper,
  Container,
  Header,
  SearchContainer,
  ChatItem,
  AutomateButton,
  Return,
} from "./mobile-inbox.styled";

const MobileInbox = () => {
  const navigate = useNavigate();

  const handleChatClick = (chatName) => {
    navigate(`/chat/${chatName}`);
  };

  return (
    <>
      <Wrapper>
        <Container>
          <Header>
            <h1>Chats</h1>
            <RiEditLine onClick={() => navigate("/new-message")} />
          </Header>
          <SearchContainer>
            <SearchInput
              type="text"
              placeholder="Search users..."
              name="search"
            />
            <SearchButton>
              <FiSearch />
            </SearchButton>
          </SearchContainer>
        </Container>
        <ChatList>
          {chats.map((chat, index) => (
            <ChatItem key={index} onClick={() => handleChatClick(chat.name)}>
              <ChatLeft>
                <Avatar>
                  <img src={chat.image} alt="profile" />
                </Avatar>
                <UserInfo>
                  <UserName>{chat.name}</UserName>
                  <UserMessage>
                    <p>
                      {chat.message.length > 25
                        ? `${chat.message.substring(0, 25)}...`
                        : chat.message}
                    </p>
                    <Timestamp>{chat.timestamp}</Timestamp>
                  </UserMessage>
                </UserInfo>
              </ChatLeft>
              <StatusIndicator
                style={{ display: chat.isActive ? "block" : "none" }}
              />
            </ChatItem>
          ))}
        </ChatList>
        <AutomateButton onClick={() => navigate("/autosell-assist")}>
          <RiRobot2Fill />
        </AutomateButton>
      </Wrapper>
      <Return>
        <button onClick={() => navigate("/newsfeed")}>
          return to homepage
        </button>
      </Return>
    </>
  );
};

export default MobileInbox;
