import styled, { keyframes } from "styled-components";
import { breakpoint, color } from "../../../../themes/themes";

const dropanim = keyframes`
 0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }`;

export const Wrapper = styled.div`
  margin: 8rem 0 5rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  ${breakpoint.tablet} {
    display: none;
  }

  ${breakpoint.desktop} {
    display: none;
  }
`;

export const Container = styled.div`
  position: fixed;
  background-color: ${color.white};
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  top: 0;
  padding-bottom: 1rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem 0;

  h1 {
    margin: 0;
  }

  svg {
    display: flex;
    font-size: 1.4rem;
    cursor: pointer;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.4rem;
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  margin: 0 1rem;

  &:focus-within {
    border-color: ${color.primary};
  }
`;

export const ChatItem = styled.li`
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${color.white};
  border-radius: 0.5rem;
  justify-content: space-between;

  &:hover {
    background-color: #f4f4f4;
  }
`;

export const AutomateButton = styled.button`
  display: flex;
  gap: 0.5rem;
  border-radius: 4rem;
  background-color: ${color.primary};
  color: ${color.white};
  padding: 0.8rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 1rem;
  position: fixed;
  bottom: 5.5rem;
  right: 1rem;
  animation: ${dropanim} 3s infinite;

  svg {
    display: flex;
    font-size: 1.6rem;
  }

  &:hover {
    background-color: ${color.lightblue};
  }
`;

export const Return = styled.div`
  height: 100vh;
  width: 100%;
  display: none;
  justify-content: center;

  ${breakpoint.tablet} {
    display: flex;
  }

  ${breakpoint.desktop} {
    display: flex;
  }

  button {
    align-self: center;
    padding: 0.8rem 1rem;
    border: none;
    background-color: ${color.primary};
    color: ${color.white};
    border-radius: 10rem;
    cursor: pointer;
  }
`;
