import styled from "styled-components";
import { breakpoint, color } from "../../../themes/themes";

export const HeaderWrapper = styled.nav`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #6500cc;
  color: ${color.white};
  padding: 1rem 3rem;
  width: 100%;
  top: 0;
  z-index: 9999;

  ${breakpoint.tablet} {
    padding: 1rem;
    background-color: ${color.white};
    color: inherit;
  }

  ${breakpoint.mobile} {
    padding: 1rem;
    background-color: ${color.white};
    color: inherit;
  }
`;

export const Logo = styled.h1`
  font-size: 1.5rem;
  margin: 0;
  cursor: pointer;

  ${breakpoint.tablet} {
    color: ${color.primary};
  }

  ${breakpoint.mobile} {
    color: ${color.primary};
  }
`;

export const RightSide = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.6rem;
  list-style-type: none;
`;

export const Icon = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${color.primary};
  padding: 0.6rem;
  cursor: pointer;

  &:hover {
    background-color: ${color.lightblue};
  }

  svg {
    font-size: 1.4rem;
  }

  ${breakpoint.tablet} {
    background-color: #efe5e5;

    &:hover {
      background-color: #ded4d4;
    }

    svg {
      font-size: 1.2rem;
    }
  }

  ${breakpoint.mobile} {
    background-color: #efe5e5;

    &:hover {
      background-color: #ded4d4;
    }

    svg {
      font-size: 1.2rem;
    }
  }
`;

export const Profile = styled.div`
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  background-color: ${color.white};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${breakpoint.mobile} {
    width: 2.4rem;
    height: 2.4rem;
  }
`;

//---------- DESKTOP STYLED COMPONENTS ----------//

export const DesktopWrapper = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  align-items: center;

  ${breakpoint.tablet} {
    display: none;
  }

  ${breakpoint.mobile} {
    display: none;
  }
`;

export const DesktopLinkItem = styled.li`
  padding: 0.6rem 1rem;
  cursor: pointer;
  border-radius: 0.4rem;
  margin: 0;

  &:hover {
    background-color: #fff;
    color: black;
  }
`;

//---------- MOBILE STYLED COMPONENTS ----------//

export const MobileWrapper = styled.ul`
  position: fixed;
  display: none;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  list-style-type: none;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  bottom: 0;
  margin: 0;
  width: 100%;
  padding: 0.4rem 1rem;
  z-index: 1111;

  svg {
    font-size: 1.4rem;
  }

  ${breakpoint.tablet} {
    display: flex;
  }

  ${breakpoint.mobile} {
    display: flex;
  }
`;

export const MobileLinkItem = styled.li`
  display: flex;
  padding: 1rem;
  border-radius: 0.5rem;

  &:hover {
    background-color: #fff6f6;
  }
`;
