import styled from "styled-components";
import { breakpoint, color } from "../../../themes/themes";

export const PostWrapper = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  width: 100%;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  ${breakpoint.mobile} {
    border-radius: 0;
  }
`;

export const PostHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem 0;
`;

export const UserSection = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
`;

export const ProfilePicture = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;

  h3,
  p {
    margin: 0;
  }

  h3 {
    font-size: 1rem;

    &:hover {
      text-decoration: 1px underline;
      color: ${color.primary};
      cursor: pointer;
    }
  }
`;

export const PostMeta = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  color: gray;

  p {
    font-size: 0.8rem;
  }

  svg {
    font-size: 0.8rem;
  }
`;

export const PostOptions = styled.div`
  svg {
    font-size: 1.2rem;
    cursor: pointer;
  }
`;

export const PostContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PostText = styled.p`
  margin: 1rem 0 0;
  font-size: 0.95rem;
  line-height: 1.3rem;
  padding: 0 1rem 1rem;
`;

export const PostImage = styled.div`
  display: flex;
  width: 100%;
  height: 80vh;
  gap: 0.6rem;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 0.85rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ddd;
  }

  img {
    width: 25rem;
    height: 100%;
    object-fit: cover;
    flex-shrink: 0;
    flex-grow: 1;
    cursor: pointer;
  }

  ${breakpoint.tablet} {
    height: 50vh;

    img {
      border-radius: 0;
      width: 100%;
    }
  }

  ${breakpoint.mobile} {
    height: 50vh;

    img {
      border-radius: 0;
      width: 100%;
    }
  }
`;

export const PostIcons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.2rem 1.8rem;

  .left-icons {
    display: flex;
    gap: 1.5rem;
  }

  .react {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  svg {
    display: flex;
    font-size: 1.3rem;
    cursor: pointer;
  }

  button {
    border: none;
    padding: 0;
    background-color: ${color.transparent};
  }
`;

export const Notification = styled.div`
  position: fixed;
  bottom: 6rem;
  background: rgba(0, 0, 0, 0.4);
  color: ${color.white};
  font-size: 0.9rem;
  padding: 0.5rem 1.2rem;
  border-radius: 0.3rem;
  z-index: 9999;
  animation: fadeIn 0.5s, fadeOut 0.5s 1.5s forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

// ---------- COMMENT STYLED COMPONENTS ---------- //

export const CommentBoxWrapper = styled.div`
  padding: 1rem;
  border-top: 1px solid #ddd;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const CommentTextarea = styled.textarea`
  flex-grow: 1;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: none;
`;

export const SendButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${color.primary};
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 1.5em;
`;

export const CommentsList = styled.div`
  max-height: 50vh;
  overflow-y: auto;
  border-top: 1px solid #ddd;
`;

export const CommentItem = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0.8rem 1rem;
`;

export const CommentUserInfo = styled(UserInfo)`
  gap: 0.2rem;
  margin-left: 10px;
  background-color: #f2f4f7;
  padding: 0.6rem 1rem;
  border-radius: 0.8rem;

  h4 {
    margin: 0;
    font-size: 0.85em;
  }

  p {
    font-size: 0.9rem;
  }
`;
