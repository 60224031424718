import { GrHomeRounded } from "react-icons/gr";
import { RiUserVoiceLine, RiTrophyLine } from "react-icons/ri";
import { LuShoppingCart } from "react-icons/lu";
import { IoBriefcaseOutline } from "react-icons/io5";
import { AiOutlineMenu } from "react-icons/ai";

export const navLinksArray = [
  { name: "Newsfeed", path: "/newsfeed", icon: <GrHomeRounded /> },
  { name: "Services", path: "/services", icon: <RiUserVoiceLine /> },
  {
    name: "Digital Products",
    path: "/digital-products",
    icon: <LuShoppingCart />,
  },
  { name: "Hiring", path: "/hiring", icon: <IoBriefcaseOutline /> },
  { name: "Challenge", path: "/challenge", icon: <RiTrophyLine /> },
  { name: "Dashboard", path: "/dashboard", icon: <AiOutlineMenu /> },
];
