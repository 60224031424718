import React from "react";
import ServiceSection from "../../../components/service-section/service-section";

const services = [
  "Sell your digital products",
  "Buy digital products",
  "Easy to upload and download",
  "Packed with built-in features",
  "Unlimited uploads",
  "Customize your own landing page",
];

const Section4 = () => {
  const imageSrc = "";

  return (
    <ServiceSection
      title="Buy and Sell Digital Products"
      services={services}
      imageSrc={imageSrc}
    />
  );
};

export default Section4;
