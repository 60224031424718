import React from "react";
import styled from "styled-components";
import { breakpoint } from "../../../themes/themes";

const Wrapper = styled.section`
  background-color: #f2f4f7;
  display: flex;
  align-items: flex-start;
  justify-content: ${({ $hasRightContent }) =>
    $hasRightContent ? "center" : "flex-end"};
  padding: 6rem 0 0;

  ${breakpoint.tablet} {
    padding: 5rem 0 0;
  }

  ${breakpoint.mobile} {
    padding: 5rem 0 0;
  }
`;

const LeftSide = styled.div`
  position: fixed;
  width: 25%;
  left: 0;

  ${breakpoint.tablet} {
    display: none;
  }

  ${breakpoint.mobile} {
    display: none;
  }
`;

const Center = styled.div`
  width: ${({ $hasRightContent }) => ($hasRightContent ? "40%" : "70%")};

  ${breakpoint.tablet} {
    width: 100%;
  }

  ${breakpoint.mobile} {
    width: 100%;
  }
`;

const RightSide = styled.div`
  position: fixed;
  width: 25%;
  right: 0;

  ${breakpoint.tablet} {
    display: none;
  }

  ${breakpoint.mobile} {
    display: none;
  }
`;

const AppLayout = ({ leftContent, centerContent, rightContent }) => {
  const hasRightContent = Boolean(rightContent);

  return (
    <Wrapper $hasRightContent={hasRightContent}>
      <LeftSide>{leftContent}</LeftSide>
      <Center $hasRightContent={hasRightContent}>{centerContent}</Center>
      {hasRightContent && <RightSide>{rightContent}</RightSide>}
    </Wrapper>
  );
};

export default AppLayout;
