import React from "react";
import { useNavigate } from "react-router-dom";
import { IoSettings, IoLogOut } from "react-icons/io5";
import { FaCircleQuestion } from "react-icons/fa6";
import {
  Wrapper,
  UserAccount,
  ProfilePicture,
  List,
} from "./profile-modal.styled";
import { useAuth } from "../../../context/AuthContext";

const ProfileModal = () => {
  const navigate = useNavigate();
  const { logout, user } = useAuth();
  return (
    <Wrapper>
      <UserAccount onClick={() => navigate("/username@autosell.io")}>
        <ProfilePicture>
          <img
            src={user?.profile?.profilePictureUrl || "/default-profile.png"}
            alt="Profile"
          />
        </ProfilePicture>
        <h3>User Account</h3>
      </UserAccount>
      <List>
        <li>
          <IoSettings /> <h3>Settings</h3>
        </li>
        <li>
          <FaCircleQuestion /> <h3>Help & Support</h3>
        </li>
        <li onClick={logout}>
          <IoLogOut /> <h3>Logout</h3>
        </li>
      </List>
    </Wrapper>
  );
};

export default ProfileModal;
