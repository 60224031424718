import React, { createContext, useContext, useState, useEffect } from "react";
import authApi from "../api/authApi";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Check if user is logged in on init
    const storedUser = localStorage.getItem("user");

    if (storedUser) {
      checkToken(storedUser);
    } else {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = async (storedUser) => {
    try {
      const token = JSON.parse(storedUser).access_token;
      const user = await authApi.check(token);
      setUser(user);
      setIsLoggedIn(true);
    } catch (error) {
      if (error.message === "unauthenticated") {
        logout();
      } else {
        console.error("Error during authentication check:", error.message);
        setError(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const login = async (username, password) => {
    try {
      const userData = await authApi.login(username, password);
      setUser(userData.user);
      setIsLoggedIn(true);
      localStorage.setItem("user", JSON.stringify(userData));
    } catch (error) {
      console.error("Login failed:", error);
      throw error;
    }
  };

  const register = async (username, email, password, confPassword) => {
    try {
      const userData = await authApi.register(
        username,
        email,
        password,
        confPassword
      );
      setUser(userData.user);
      setIsLoggedIn(true);
      localStorage.setItem("user", JSON.stringify(userData));
    } catch (error) {
      console.error("Login failed:", error);
      throw error;
    }
  };

  const logout = () => {
    setUser(null);
    setIsLoggedIn(false);
    localStorage.removeItem("user");
  };

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, user, login, logout, register, error, loading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
