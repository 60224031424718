import styled from "styled-components";
import { breakpoint, color } from "../../../../themes/themes";

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-top: 1px solid #ddd;

  ${breakpoint.mobile} {
    flex-direction: column-reverse;
    gap: 1rem;
  }
`;

export const Note = styled.p`
  margin: 0;
  max-width: 50vw;
  font-size: 0.8rem;
  color: #aaa;

  ${breakpoint.mobile} {
    max-width: 100%;
  }
`;

export const Button = styled.button`
  background-color: ${color.primary};
  color: ${color.white};
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: ${color.lightblue};
  }

  svg {
    display: flex;
  }
`;
