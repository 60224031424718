import React from "react";
import Container from "../../components/container/container";
import SectionTitle from "../../components/section-title/section-title";
import Image from "../../assets/about.png";
import { Wrapper, Content, Thumbnail, Caption, Button } from "./about.styled";

const About = () => {
  return (
    <Wrapper>
      <Container>
        <SectionTitle title="Our Vision" />
        <Content>
          <Thumbnail>
            <img src={Image} alt="thumbnail" />
          </Thumbnail>
          <Caption>
            <h1>We want to see millions of entrepreneurs succeed</h1>
            <p>
              At Autosell, we envision a growing community where entrepreneurs
              grow and succeed together. Our platform empowers users to earn,
              offer services, and automate their businesses for more sales. We
              aim to create a network of successful entrepreneurs, providing an
              all-in-one solution that saves money by replacing the need for
              multiple expensive tools.
            </p>
            <Button>Join us on this journey!</Button>
          </Caption>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default About;
