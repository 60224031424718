import React from "react";
import styled from "styled-components";
import { breakpoint } from "../../../themes/themes";

const Wrapper = styled.section`
  background-color: #f2f4f7;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 6rem 0 0;

  ${breakpoint.tablet} {
    padding: 5rem 0 0;
  }

  ${breakpoint.mobile} {
    padding: 5rem 0 0;
  }
`;

const LeftSide = styled.div`
  position: fixed;
  width: 25%;
  left: 0;

  ${breakpoint.tablet} {
    width: 30%;
  }

  ${breakpoint.mobile} {
    display: none;
  }
`;

const RightSide = styled.div`
  width: 65%;
  margin-left: auto;

  ${breakpoint.mobile} {
    width: 100%;
  }
`;

const TestimonialLayout = ({ leftContent, rightContent }) => {
  return (
    <Wrapper>
      <LeftSide>{leftContent}</LeftSide>
      <RightSide>{rightContent}</RightSide>
    </Wrapper>
  );
};

export default TestimonialLayout;
