import React from "react";
import { Content } from "./video.styled";

const Video = () => {
  return (
    <Content>
      <iframe
        src="https://www.youtube.com/embed/itQQCkA87Hs?si=hUOqslUaUVPNuCcQ"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      />
    </Content>
  );
};

export default Video;
