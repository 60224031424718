import React, { useState } from "react";
import ProfileImg from "../../../assets/Story1.png";
import { GoHeart, GoHeartFill, GoComment, GoShare } from "react-icons/go";
import ShareModal from "../../newsfeed/newsfeed-post/share-modal/share-modal";
import CommentModal from "../comment-modal/comment-modal";
import {
  List,
  ListEntry,
  UserDetails,
  UserAvatar,
  UserName,
  PostImage,
  PostTitle,
  PostInteraction,
} from "./challenge-posts.styled";
import { challengePostItems } from "../challenge-post-data.js/challengePostData";

const ChallengePosts = () => {
  const [likedItems, setLikedItems] = useState({});
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);

  const handleLike = (index) => {
    setLikedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleShareClick = () => {
    setIsShareModalOpen(true);
  };

  const handleCloseShareModal = () => {
    setIsShareModalOpen(false);
  };

  const handleCommentClick = () => {
    setIsCommentModalOpen(true);
  };

  const handleCloseCommentModal = () => {
    setIsCommentModalOpen(false);
  };

  return (
    <List>
      {challengePostItems.map((item, index) => (
        <ListEntry key={index}>
          <UserDetails>
            <UserAvatar>
              <img src={ProfileImg} alt="profile" />
            </UserAvatar>
            <UserName>{item.username}</UserName>
          </UserDetails>
          <PostImage>
            <img src={item.thumbnail} alt="thumbnail" />
          </PostImage>
          <PostTitle>
            <h4>{item.title}</h4>
          </PostTitle>

          <PostInteraction>
            <li onClick={() => handleLike(index)}>
              {likedItems[index] ? (
                <GoHeartFill style={{ color: "#FF0000" }} />
              ) : (
                <GoHeart />
              )}
            </li>
            <li onClick={handleCommentClick}>
              <GoComment />
            </li>
            <div style={{ flexGrow: 1 }}></div>
            <li onClick={handleShareClick}>
              <GoShare />
            </li>
          </PostInteraction>
        </ListEntry>
      ))}
      {isShareModalOpen && <ShareModal onClose={handleCloseShareModal} />}
      {isCommentModalOpen && <CommentModal onClose={handleCloseCommentModal} />}
    </List>
  );
};

export default ChallengePosts;
