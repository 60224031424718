import styled, { css } from "styled-components";
import { breakpoint, color } from "../../../themes/themes";

export const Section = styled.section`
  padding: 7rem 0 5rem;
  background-color: ${color.lightviolet};
`;

export const PlanList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 3rem;

  ${breakpoint.mobile} {
    align-items: center;
    flex-direction: column;
  }
`;

export const Plan = styled.li`
  padding: 3rem 2rem;
  border-radius: 1.2rem;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  background-color: ${color.white};
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  width: 20rem;

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      background-color: ${color.darkBg};
      color: ${color.white};
    `}

  ${breakpoint.mobile} {
    width: 100%;
  }
`;

export const PlanTitle = styled.h1`
  margin: 0;
  font-size: 2rem;
`;

export const PlanPrice = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  h1 {
    margin: 0;
    font-size: 2.4rem;
  }

  span {
    font-size: 1.2rem;
    font-weight: 400;
    color: lightgray;
  }
`;

export const SubscribeButton = styled.button.attrs({ type: "button" })`
  border: none;
  padding: 1rem;
  border-radius: 0.6rem;
  background-color: ${color.primary};
  color: ${color.white};
  width: 100%;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: ${color.lightblue};
  }
`;

export const FeatureList = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  list-style: none;
  gap: 0.8rem;
  margin-top: 0.5rem;
`;

export const Feature = styled.li`
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  p {
    margin: 0;
  }

  svg {
    display: flex;
    color: ${color.primary};
    min-width: 1.4rem;
  }
`;
