export const servicesData = [
  {
    category: "WooCommerce",
    name: "Custom WooCommerce Plugin",
    rating: 4.6,
    price: 400,
    image:
      "https://cdn.dribbble.com/userupload/17422071/file/original-175afc4a65a30fdf3c02ccbacac8a8d2.png?resize=1024x768",
  },
  {
    category: "Video Software",
    name: "Advanced Video Editing Suite",
    rating: 4.7,
    price: 500,
    image:
      "https://cdn.dribbble.com/userupload/17427666/file/original-3d7aa7c973e090cadfb270e46c852f8f.png?resize=1200x900",
  },
  {
    category: "Video Software",
    name: "Pro Video Effects Pack",
    rating: 4.8,
    price: 250,
    image:
      "https://cdn.dribbble.com/userupload/17190817/file/original-ab8a41d3c954972b3c4194dd795a2adc.jpg?resize=1200x907",
  },
  {
    category: "Image Editors",
    name: "Creative Photo Manipulation",
    rating: 4.8,
    price: 300,
    image:
      "https://cdn.dribbble.com/userupload/17428154/file/original-91a0711ad6bbbf073a1d8f67350e79c2.png?resize=1200x900",
  },
  {
    category: "Image Editors",
    name: "Photo Retouching Master",
    rating: 4.9,
    price: 150,
    image:
      "https://cdn.dribbble.com/userupload/17427172/file/original-d690b3c47d6cbd428586a17f5bd63f3d.png?resize=1200x900",
  },
  {
    category: "WooCommerce",
    name: "WooCommerce Store Setup",
    rating: 4.7,
    price: 600,
    image:
      "https://cdn.dribbble.com/userupload/17411468/file/original-1deff62cddcfa01b020da6dae9614c2f.jpg?resize=1200x900",
  },
  {
    category: "Video Software",
    name: "Advanced Video Editing Suite",
    rating: 4.7,
    price: 500,
    image:
      "https://cdn.dribbble.com/userupload/17427666/file/original-3d7aa7c973e090cadfb270e46c852f8f.png?resize=1200x900",
  },
  {
    category: "Video Software",
    name: "Pro Video Effects Pack",
    rating: 4.8,
    price: 250,
    image:
      "https://cdn.dribbble.com/userupload/17190817/file/original-ab8a41d3c954972b3c4194dd795a2adc.jpg?resize=1200x907",
  },
  {
    category: "Image Editors",
    name: "Photo Retouching Master",
    rating: 4.9,
    price: 150,
    image:
      "https://cdn.dribbble.com/userupload/17427172/file/original-d690b3c47d6cbd428586a17f5bd63f3d.png?resize=1200x900",
  },
  {
    category: "Image Editors",
    name: "Creative Photo Manipulation",
    rating: 4.8,
    price: 300,
    image:
      "https://cdn.dribbble.com/userupload/17428154/file/original-91a0711ad6bbbf073a1d8f67350e79c2.png?resize=1200x900",
  },
  {
    category: "WooCommerce",
    name: "Custom WooCommerce Plugin",
    rating: 4.6,
    price: 400,
    image:
      "https://cdn.dribbble.com/userupload/17422071/file/original-175afc4a65a30fdf3c02ccbacac8a8d2.png?resize=1024x768",
  },
  {
    category: "WooCommerce",
    name: "WooCommerce Store Setup",
    rating: 4.7,
    price: 600,
    image:
      "https://cdn.dribbble.com/userupload/17411468/file/original-1deff62cddcfa01b020da6dae9614c2f.jpg?resize=1200x900",
  },
];
